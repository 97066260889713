import HTMLVideo from '/js/components/html_components/common/HTMLVideo';
import HTMLCanvas from '/js/components/html_components/common/HTMLCanvas';
import AxisCameraStream from '/js/components/AxisCameraStream';
import { Configuration } from 'ohzi-core';
import { Time } from 'ohzi-core';

import MenuCameraStatus from './MenuCameraStatus';

// This class stores the PhysicalCamera and AxisCameraStream of a camera in the menu
/**
 * @class MenuCamera
 * @description - Responsible for each camera item that appears at the menu
 * @function create_html_video_element() - Creates the small video thumbnail that each menu item has
 * @function start_streaming() - Starts the streaming of the menu camera icons. Called by menu_view.add_camera
 */
export default class MenuCamera
{
  constructor(physical_camera, menu_camera_element)
  {
    this.physical_camera = physical_camera;
    // use the getter to access this
    this.__html_element = menu_camera_element;
    this.__html_element[0].setAttribute('onclick', 'app.menu_view.select_camera(this, event)');
    this.__html_element[0].setAttribute('data-name', physical_camera.name);

    this.stream = new AxisCameraStream(this.create_html_video_element());
    this.stream.set_profile('thumb');
    this.stream.set_endpoint(this.physical_camera);

    let video_container = this.__html_element.find('.menu__cameras-camera-image-video-container')[0];
    this.stream.html_video.append_to(video_container);

    this.__html_element.find('.menu__cameras-camera-title').text(physical_camera.name);
    this.rec_icon_circle = this.__html_element.find('.menu__cameras-camera-rec-icon').find('circle');

    // Assign action to tandem switch
    this.tandem_switch_el = menu_camera_element.find('.menu__cameras-camera-tandem');
    this.tandem_switch = physical_camera.tandem_enabled;

    this.tandem_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_tandem_mode(this, event)');
    this.tandem_switch_el[0].setAttribute('data-name', physical_camera.name);

    // console.log(`[MenuCamera] Camera '${physical_camera.name}' Lens: ${physical_camera.lens_type} Type: ${physical_camera.type}`);

    if (physical_camera.lens_type !== 'flat' ||
      Configuration.is_mobile || Configuration.is_ios)
    {
      // console.log(`[MenuCamera] Camera '${physical_camera.name}' Hide tandem`);
      this.__hide_elem(this.tandem_switch_el);
    }

    // Assign action to map_view switch
    this.map_view_switch_el = menu_camera_element.find('.menu__cameras-camera-map_view');
    this.map_view_switch = physical_camera.tandem_enabled;

    this.map_view_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_map_view_mode(this, event)');
    this.map_view_switch_el[0].setAttribute('data-name', physical_camera.name);

    // if (physical_camera.cam_type !== "phone") {
    // console.log(`[MenuCamera] Camera '${physical_camera.name}' Hide map_view`);
    this.__hide_elem(this.map_view_switch_el);
    // }

    this.gps_indicator = this.__html_element.find('.menu__cameras-camera-indicators-indicator--gps');
    this.rtk_indicator = this.__html_element.find('.menu__cameras-camera-indicators-indicator--rtk');

    this.streaming_state = false;

    this.status = new MenuCameraStatus(this);

    this.reconnection_throttle = 5;
    this.lost_connection_throttle = 30;

    // Boolean to try reconnection if the camera turns off while playing   
    this.is_playing = false;
    this.is_is_trying_to_connect = false;

  }

  set_rec_icon_color(color)
  {
    this.rec_icon_circle.css('fill', color);
  }

  set_streaming_state(state)
  {
    this.streaming_state = state;

    this.__toggle_switch_elem(this.remote_stream_el, this.streaming_state);

    if (this.streaming_state)
    {
      this.remote_stream_el[0].textContent = 'Stop Stream';
    }
    else
    {
      this.remote_stream_el[0].textContent = 'Start Stream';
    }
  }

  create_html_video_element()
  {
    let html_video = undefined;
    let video_class_name = 'menu__cameras-camera-image-video';
    if (Configuration.is_ios)
    {
      html_video = new HTMLCanvas(video_class_name);
    }
    else
    {
      html_video = new HTMLVideo(video_class_name, true);
    }
    return html_video;
  }

  // Called by MenuView
  start_streaming(should_freeze_after_start)
  {
    console.log('MenuCamera:start_streaming with frozen image:' + should_freeze_after_start);
    let callback = should_freeze_after_start ? this.__freeze_image.bind(this) : this.play.bind(this);
    this.is_is_trying_to_connect = true;
    this.stream.start_streaming(()=>
    {
      callback();
      this.set_is_playing.bind(this,true);
    });
    
  }
  set_is_playing(is_playing)
  {
    this.is_playing = is_playing;
    this.is_is_trying_to_connect = false;
  }

  play()
  {
    this.stream.html_video.synchronize();
    this.stream.play();
  }

  pause()
  {
    this.stream.pause();
  }

  stop()
  {
    this.stream.stop();
    this.set_is_playing(false);
  }

  restart()
  {
    const show_thumbnail_image= localStorage.getItem('show_thumbnail_image');
    const show_thumbnail_video= localStorage.getItem('show_thumbnail_video');


    if(show_thumbnail_image === "false" && show_thumbnail_video==="false" ) return;

    if(this.is_playing || this.is_is_trying_to_connect) return;
    this.stop();
    this.start_streaming(show_thumbnail_image==="true");

  }

  config_updated()
  {
    this.status.config_updated(this);

    if (this.physical_camera.fix)
    {
      this.gps_indicator.removeClass('hidden');
    }
    else
    {
      this.gps_indicator.addClass('hidden');
    }

    if (this.physical_camera.rtk)
    {
      this.rtk_indicator.removeClass('hidden');
    }
    else
    {
      this.rtk_indicator.addClass('hidden');
    }
  }

  append_to(container)
  {
    container.append(this.__html_element);
  }

  update()
  {

    const show_thumbnail_video= localStorage.getItem('show_thumbnail_video');


      this.status.update(this);
      if(show_thumbnail_video === "false") return; //if we have selected on the menu to show only thumbnain block the restart of  stream

      this.stream.update(this.reconnection_throttle);
  
      if (this.reconnection_throttle < 0.1)
      {
        this.reconnection_throttle = 10;
      }
      else
      {
        this.reconnection_throttle -= Time.delta_time;
      }
    

  }

  // update()
  // {

  //   this.status.update(this);
  //   this.stream.update(this.reconnection_throttle);
 
  //   // If camera connection was lost
  //   if (!this.stream.is_streaming  ) 
  //   {
  //     if (this.lost_connection_throttle < 0.1) 
  //     {
  //       if (this.is_playing)
  //       {
  //         this.stream.restart_video_pipeline();
  //       }
  //     }
  //   }

  //   if (this.reconnection_throttle < 0.1)
  //   {
  //     this.reconnection_throttle = 2;
  //   }
  //   else
  //   {
  //     this.reconnection_throttle -= Time.delta_time;
  //   }

  //   if (this.lost_connection_throttle < 0.1)
  //   {
  //     this.lost_connection_throttle = 30;
  //   }
  //   else
  //   {
  //     this.lost_connection_throttle -= Time.delta_time;
  //   }
    
  // }

  has_remote_control()
  {
    return false;
  }

  show()
  {
    this.__show_elem(this.__html_element);
  }

  hide()
  {
    this.__hide_elem(this.__html_element);
  }

  __freeze_image()
  {
    this.stream.play();
    this.stream.pause_rtsp();
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }

  __hide_elem(elem)
  {
    $(elem).addClass('hidden');
  }

  __show_elem(elem)
  {
    $(elem).removeClass('hidden');
  }
}
