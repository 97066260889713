import { BaseApplication } from 'ohzi-core';
import { CameraManager } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import { PerspectiveCamera } from 'ohzi-core';
import { NormalRender } from 'ohzi-core';
import { Graphics } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import CameraController from '/js/components/CameraController/CameraController';
import CameraControllerTandem from '/js/components/CameraController/CameraControllerTandem';
import CameraPTZMode from '/js/components/CameraController/movement_mode/CameraPTZMode';
import FisheyeControls from '/js/components/CameraController/states/FisheyeControls';
import PlayerMarker from '/js/components/PlayerMarker';
import CesiumHandler from '/js/components/CesiumHandler';
import MenuView from '/js/components/html_components/MenuView';
import MenuOptionsView from '/js/components/html_components/MenuOptionsView';
import CameraSettingsView from './js/components/html_components/CameraSettingsView';
import CameraCalibrationView from './js/components/html_components/CameraCalibrationView';
import CalendarView from '/js/components/html_components/CalendarView';
import PlayerView from '/js/components/html_components/PlayerView';
import WebRtcView from './js/components/html_components/WebRtcView';
import PlayerContainer from '/js/components/html_components/PlayerContainer';
import WebSocketConnectionView from  '/js/components/html_components/connection/WebSocketConnectionView';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import PlayerMarkerManager from './js/components/PlayerMarkerManager';
import MenuCameraManager from '/js/components/MenuCameraManager';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';
import PlayerSettings from './js/components/PlayerSettings';
import LiveTelemetryController from './js/components/socket/LiveTelemetryController';
import RecordingTelemetryController from './js/components/socket/RecordingTelemetryController';
import ModalWindowView from './js/components/html_components/ModalWindowView';
import ArchiveTelemetryController from './js/components/socket/ArchiveTelemetryController';
import TargetTelemetryController from './js/components/socket/TargetTelemetryController';
// import TargetSerialPortBridge from './js/components/socket/TargetSerialPortBridge';

import BrowserEventHandler from './js/components/BrowserEventHandler';
import RecordingsListView from './js/components/html_components/RecordingsListView';
import RecordingsListRowManager from './js/components/RecordingsListRowManager';
import RoleManager from './js/components/RoleManager';
import PlayerCameraMarker from './js/components/PlayerCameraMarker';

import NotificationManager from './js/components/NotificationManager';

// This is the starting point of the application
/**
 * @class MainApplication
 * @description Here is the Main class that is called
 * @function __init_camera() - Initialises the perspective camera of threeJs and the pass it at the camera management
 * @function __init_camera_controller() - Initialises the camera_controller and sets the perspective camera as its main camera.
 * @function on_resize() - On resize event calls the on_resize function on PlayerContainer.(called by api.js)
 * @function on_orientation_change() - On orientationchange evenet calls on_orientation_change on PlayerContainer. (called  by api.jss)
 * @function update() - This function is called one time per frame. This function is called from RenderLoop class of ohzi-core.
 * camera_controllers is the camera that is responisble for moving the view of the video when it plays.
 */
export default class MainApplication extends BaseApplication
{
  constructor(renderer)
  {
    super();
    this.renderer = renderer;

    this.camera_controller  = new CameraController();
    this.camera_controller_tandem  = new CameraControllerTandem();
    this.normal_render_mode = new NormalRender();

    this.fisheye_controls = new FisheyeControls();
  }

  // This function is called only one time
  // at the beginning of the execution (see main.js)
  start()
  {
    Graphics.set_state(this.normal_render_mode);
    this.__init_camera();
    this.__init_camera_controller();

    // let axis = Debug.draw_axis();
    // axis.position.y -= 5;

    PhysicalCameraManager.set_camera_controller(this.camera_controller);
    PhysicalCameraManager.set_camera_controller_tandem(this.camera_controller_tandem);
    PhysicalCameraManager.set_fisheye_controls(this.fisheye_controls);
    PhysicalCameraManager.set_app(this);
    CesiumHandler.set_app(this);
    CesiumHandler.start();

    this.fisheye_controls.start();

    this.config = ResourceContainer.get_resource('config');

    this.webrtc_view                      = new WebRtcView(this);
    this.calendar_view = new CalendarView(this);
    this.recordings_list_view = new RecordingsListView(this);
    this.player_view = new PlayerView(this);
    this.player_view.start();

    PhysicalCameraManager.set_player_video(this.player_view.html_video);

    BrowserEventHandler.start(this.player_view);

    // Create cameras using the json obtained from the server
    let cameras = ResourceContainer.get_resource('cameras');
    console.log('[MainApplication] Cameras');
    console.dir(cameras);
    // let webrtc_cameras = ResourceContainer.get_resource('webrtc_cameras');
    // console.log(`[MainApplication] webrtc_cameras on new API`);
    // console.dir(webrtc_cameras);
    for (let i = 0; i < cameras.length; i++)
    {
      // add cameras to menu view
      PhysicalCameraManager.add_camera(cameras[i]);
    }

    let player_marker = new PlayerMarker();
    PlayerMarkerManager.add_marker('target', player_marker);

    let player_camera_marker = new PlayerCameraMarker();
    PlayerMarkerManager.add_marker('hotspot', player_camera_marker);

    this.camera_settings_view              = new CameraSettingsView(this);
    this.camera_calibration_view           = new CameraCalibrationView(this);
    this.menu_view                         = new MenuView(this);
    this.modal_window_view                 = new ModalWindowView(this);
    this.menu_options_view                 = new MenuOptionsView(this);
    this.player_container                  = new PlayerContainer(this);
    this.web_socket_connection_view         = new WebSocketConnectionView(this);
    this.live_telemetry_controller         = new LiveTelemetryController(this);
    this.recording_telemetry_controller    = new RecordingTelemetryController(this);
    this.archive_telemetry_controller      = new ArchiveTelemetryController(this);

    // super(app, app.config.live_telemetry_websocket_host, app.menu_view.tabs.LIVE);
    this.target_telemetry_controller       = new TargetTelemetryController(this);
    // this.target_serial_port_bridge         = new TargetSerialPortBridge(this);

    this.player_settings                   = PlayerSettings;
    this.physical_camera_manager           = PhysicalCameraManager;
    this.player_marker_manager             = PlayerMarkerManager;
    this.recordings_list_row_manager       = RecordingsListRowManager;
    this.taglock_marker_manager            = TaglockMarkerManager;
    this.notification_manager              = NotificationManager;
    this.menu_camera_manager               = MenuCameraManager;
    this.cesium_handler                    = CesiumHandler;

    this.player_marker_manager.set_app(this);
    this.taglock_marker_manager.set_app(this);

    this.camera_controller.set_player_container(this.player_container);

    this.notification_manager.start();
    this.webrtc_view.start();
    this.calendar_view.start();
    this.recordings_list_view.start();
    this.camera_settings_view.start();
    this.camera_calibration_view.start();
    this.menu_view.start();
    this.modal_window_view.start();
    this.menu_options_view.start();
    this.player_container.start();
    this.player_settings.start();
    this.recordings_list_row_manager.start();
    this.taglock_marker_manager.start();

    this.live_telemetry_controller.start();
    this.recording_telemetry_controller.start();
    this.archive_telemetry_controller.start();
    this.target_telemetry_controller.start();
    // this.target_serial_port_bridge.start();

    // TODO: stop doing this?
    for (let i = 0; i < cameras.length; i++)
    {
      // Stop the streaming for the recorded telemetry when the website starts up
      if (cameras[i].bodyworn !== 'yes')
      {
        this.recording_telemetry_controller.stop_streaming(cameras[i]);
      }
    }

    this.configure_app();

    // Force resize update before the first frame
    // To be sure that the canvas has the correct size
    this.on_resize();
  }

  // This function is called one time per frame.
  // In an optimal execution, it is called 60 times per second
  // This function is called from RenderLoop class of ohzi-core
  update()
  {
    // this.camera_controller.update();

    PhysicalCameraManager.update();
    MenuCameraManager.update();
    RecordingsListRowManager.update();
    CesiumHandler.update();
    PlayerMarkerManager.update();

    this.menu_view.update();
    this.modal_window_view.update();
    this.player_container.update();
    this.player_view.update();
    this.camera_settings_view.update();
    this.camera_calibration_view.update();
    // this.webrtc_view.update();
    this.calendar_view.update();
    this.recordings_list_view.update();
    this.player_settings.update();

    this.live_telemetry_controller.update();
    this.recording_telemetry_controller.update();
    this.archive_telemetry_controller.update();
    this.target_telemetry_controller.update();
    // this.target_serial_port_bridge.update();

    this.taglock_marker_manager.update();
    this.notification_manager.update();
  }

  // Called by api.js when the browser window is resized
  on_resize()
  {
    this.player_container.on_resize();
    this.player_view.on_resize();
  }

  // Called by api.js when the device orientation is changed
  on_orientation_change()
  {
    this.player_container.on_orientation_change();
  }

  configure_app()
  {
    if (Configuration.is_ios)
    {
      ViewApi.hide_recordings_tab();
      ViewApi.hide_archive_tab();
    }

    RoleManager.configure_app(this);
  }

  __init_camera()
  {
    let camera = new PerspectiveCamera(60, Screen.aspect_ratio, 0.1, 200);
    camera.updateProjectionMatrix();
    camera.position.z = 10;

    camera.clear_color.copy(new THREE.Color('#000000'));
    camera.clear_alpha = 1;
    CameraManager.current = camera;
  }

  __init_camera_controller()
  {
    this.camera_controller.set_camera(CameraManager.current);
    this.camera_controller.set_state(this.fisheye_controls);
    // this.camera_controller.set_debug_mode(this.fisheye_controls);
    this.camera_controller.min_zoom = 0;
    this.camera_controller.max_zoom = 50;
    this.camera_controller.reference_zoom = 0;
    this.camera_controller.reference_position.set(0, 0, 0);

    this.camera_controller_tandem.set_camera(CameraManager.current);
    // this.camera_controller_tandem.set_mode(new CameraPTZMode());
  }
}
