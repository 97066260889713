import { Time } from 'ohzi-core';

export default class ApplicationView
{
  constructor(name, container)
  {
    this.name = name;
    this.container = container;
    this.containerEl = container[0];

    this.initialized = false;
    this.t = 0;
    this.is_hiding = false;
  }

  start()
  {
    this.initialized = true;
  }

  update()
  {
    if (this.is_hiding)
    {
      this.t += Time.delta_time;

      if (this.t >= 0.5)
      {
        this.container.addClass('hidden');
        this.is_hiding = false;
      }
    }
  }

  on_resize()
  {
  }

  show(transition_delay = 0)
  {
    this.container.removeClass('hidden');
    this.containerEl.style['transition-delay'] = `${transition_delay}s`;
    this.containerEl.style.opacity = 1;
    this.containerEl.style['pointer-events'] = 'auto';
  }

  hide(transition_delay = 0)
  {
    this.t = 0;
    this.is_hiding = true;
    // this.containerEl.style['transition-delay'] = `${transition_delay}s`
    this.containerEl.style.opacity = 0;

    this.containerEl.style['pointer-events'] = 'none';
  }

  width()
  {
    return this.containerEl.getBoundingClientRect().width;
  }

  height()
  {
    return this.containerEl.getBoundingClientRect().height;
  }
}
