import { ObjectUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import FisheyeSphere from '/js/components/FisheyeSphere';
import MapMarker from '/js/components/MapMarker';
import MapTargetMarker from '/js/components/MapTargetMarker';

import PhysicalCamera from './PhysicalCamera';

// This represents the camera containing the config variables used for the application
/**
 * @class PhysicalCameraFisheye
 * @description Handles the fishey camera.
 * @function update_params() - Updates Camera with new inputs that are received from server by right clicking on the map.(called by PhysicalCameraManager)
 *
 */
export default class PhysicalCameraFisheye extends PhysicalCamera
{
  //stream is type of class AxisCameraStream. More details at AxisCameraStream.js
  constructor(params, stream, texture)
  {
    super(params, stream, texture);

    this.default_cesium_custom_3d_object_alt = 10;

    this.cam_type  = 'axis_fisheye';
    this.lens_type = 'fisheye';

    this.fisheye_sphere = new FisheyeSphere();
    this.map_marker = this.__create_map_marker(params.cesium_3d_object_url);

    this.fisheye_sphere.set_texture(texture);
    this.update_params();
  }

  get show_custom_sphere()
  {
    return (!this.cesium_3d_object_url || this.cesium_3d_object_url === 'textured-sphere');
  }

  start()
  {}

  update_params()
  {
    this.fisheye_sphere.set_fov_and_use_upper_hemisphere(this.fov, this.use_upper_hemisphere);
    this.fisheye_sphere.set_rotation(this.yaw, this.roll, this.pitch);

    let alt = this.show_custom_sphere ? this.default_map_marker_alt : this.default_cesium_custom_3d_object_alt;
    this.map_marker.set_position(this.cam_lon, this.cam_lat, alt);

    // this.map_marker.set_position(this.cam_lon, this.cam_lat, this.cam_alt);
    this.map_marker.set_rotation(this.yaw, this.roll, this.pitch);
    this.map_marker.set_name(this.name);
    this.map_marker.set_scale(this.marker_scale);
    this.map_marker.set_opacity(this.marker_opacity);
    this.map_marker.set_inverted(this.use_upper_hemisphere);
    this.map_marker.update_marker_color();


    this.map_target_marker.set_position(this.tgt_lon, this.tgt_lat, this.tgt_alt, this.tgt_distance);
    this.map_target_marker.set_name(this.tgt_name);

    this.__update_name_in_menu();

    this.stream.set_endpoint(this);
    this.stream.set_profile(this.live_profile);
  }

  __create_map_marker(cesium_3d_object_url)
  {
    return new MapMarker({
      text: this.name,
      lon: this.cam_lon,
      lat: this.cam_lat,
      alt: this.default_map_marker_alt,
      map_zoom: this.map_zoom,
      scale: this.marker_scale,
      opacity: this.marker_opacity,
      is_inverted: this.use_upper_hemisphere,
      show_custom_sphere: this.show_custom_sphere,
      show_custom_plane: false,
      custom_object_url: this.show_custom_sphere ? undefined : cesium_3d_object_url
    });
  }
}
