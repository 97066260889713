import { Validation } from 'ohzi-core';

import PlayerSettings from '../PlayerSettings';
import PhysicalCameraManager from '../PhysicalCameraManager';
import CesiumHandler from '../CesiumHandler';

export default class CustomCameraMovement
{
  constructor(cesium_viewer)
  {
    this.cesium_viewer = cesium_viewer;
  }

  flyTo(longitude, latitude, height, heading, pitch, roll, ballRadius)
  {
    console.info(`[CustomCameraMovement:flyTo] lat: ${latitude}, lon: ${longitude}, alt: ${height}, heading: ${heading}, pitch: ${pitch}`);

    if (!this.__is_valid_position(longitude, latitude, height))
    {
      console.warn(`[CustomCameraMovement:flyTo] Position Not Valid`);
      return;
    }
    
    if (!this.__is_valid_rotation(heading, pitch, roll))
    {
      console.warn(`[CustomCameraMovement:flyTo] RYP Not Valid`);
      return;
    }

    // Unlock the map for a better flying
    this.cesium_viewer.trackedEntity = undefined;

    let planePosition = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);

    const hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), 0, 0);
    //const hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), Cesium.Math.toRadians(pitch), Cesium.Math.toRadians(roll));

    const planeModelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(planePosition, hpr);

    let yAxis = new Cesium.Cartesian3();

    yAxis.x = planeModelMatrix[4];
    yAxis.y = planeModelMatrix[5];
    yAxis.z = planeModelMatrix[6];

    const offsetLength = 10;
    let length = ballRadius * 2 + offsetLength;

    let distance = length / Math.tan(this.cesium_viewer.camera.frustum.fov / 2);

    let offset = Cesium.Cartesian3.multiplyByScalar(yAxis, -distance, new Cesium.Cartesian3());
    let cameraPosition = Cesium.Cartesian3.add(planePosition, offset, new Cesium.Cartesian3());

    var flyOptions = {
      destination: cameraPosition,
      orientation: {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(0),
        //pitch: Cesium.Math.toRadians(pitch),
        roll: 0.0
        //roll: Cesium.Math.toRadians(roll)
      },
      duration: 5,
      //easingFunction: Cesium.EasingFunction.QUARTIC_OUT,
      pitchAdjustHeight: 100,
    };

    flyOptions.complete = function () {
      setTimeout(function () {
        if (PlayerSettings.map_lock)
        {
          if (PhysicalCameraManager.tandem_mode)
          {
            CesiumHandler.lock_to_marker(PhysicalCameraManager.selected_camera_tandem.map_marker);
          }
          else
          {
            if (PhysicalCameraManager.selected_camera)
            {
              CesiumHandler.lock_to_marker(PhysicalCameraManager.selected_camera.map_marker);
            }
          }
        }
      }, 500);
    };

    this.cesium_viewer.camera.flyTo(flyOptions);
  }

  flyToDirect(longitude, latitude, height, heading, pitch, roll, ballRadius, marker, lock)
  {
    console.info(`[CustomCameraMovement:flyToDirect] lat: ${latitude}, lon: ${longitude}, alt: ${height}, heading: ${heading}, pitch: ${pitch}`);

    if (!this.__is_valid_position(longitude, latitude, height))
    {
      console.warn(`[CustomCameraMovement:flyToDirect] Position Not Valid`);
      return;
    }
    
    if (!this.__is_valid_rotation(heading, pitch, roll))
    {
      console.warn(`[CustomCameraMovement:flyToDirect] RYP Not Valid`);
      return;
    }

    // Unlock the map for a better flying
    this.cesium_viewer.trackedEntity = undefined;

    let planePosition = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);

    const hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), 0, 0);
    //const hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), Cesium.Math.toRadians(pitch), Cesium.Math.toRadians(roll));

    const planeModelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(planePosition, hpr);

    let yAxis = new Cesium.Cartesian3();

    yAxis.x = planeModelMatrix[4];
    yAxis.y = planeModelMatrix[5];
    yAxis.z = planeModelMatrix[6];

    const offsetLength = 10;
    let length = ballRadius * 2 + offsetLength;

    let distance = length / Math.tan(this.cesium_viewer.camera.frustum.fov / 2);

    let offset = Cesium.Cartesian3.multiplyByScalar(yAxis, -distance, new Cesium.Cartesian3());
    //let cameraPosition = Cesium.Cartesian3.add(planePosition, offset, new Cesium.Cartesian3());
    let cameraPosition = this.__getCameraPositionOfPlane(planePosition, heading, -45, 0, 40, true);
    
    var flyOptions = {
      destination: cameraPosition,
      orientation: {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(-45.0),
        //pitch: Cesium.Math.toRadians(pitch),
        roll: 0.0
        //roll: Cesium.Math.toRadians(roll)
      },
      duration: 1,
      //easingFunction: Cesium.EasingFunction.QUARTIC_OUT,
      //pitchAdjustHeight: 100,
    };

    flyOptions.complete = function () {
      setTimeout(function () {
        if (lock)
        {
          CesiumHandler.lock_to_marker(marker);
        }
      }, 500);
    };

    this.cesium_viewer.camera.flyTo(flyOptions);
  }

  setViewToPlane(planePosition, heading, pitch, roll, zoom, lock_all_axis) {
    roll = 0.0;   // Always disable roll

    if (!lock_all_axis)
    {
      pitch = 0;
      roll = 0;
    }

    if (!this.__is_valid_rotation(heading, pitch, roll))
    {
      console.warn(`[CustomCameraMovement:setViewToPlane] RYP Not Valid. heading: ${heading}, pitch: ${pitch}, roll: ${roll}`);
      return;
    }

    const destination = this.__getCameraPositionOfPlane(planePosition, heading, pitch, roll, zoom, lock_all_axis);
    
    
    this.cesium_viewer.camera.setView({
        destination : destination,
        //destination : normalVectorEndPosition,
        orientation : {
            heading : Cesium.Math.toRadians(heading),
            //pitch : Cesium.Math.toRadians(0),
            pitch : Cesium.Math.toRadians(pitch),
            //pitch : pitch_rad,
            roll : 0.0
            //roll : roll
        }
    });
  }

  enableDisableScreenSpaceCameraController(b) {
    if(!this.cesium_viewer)
        return;

    const scene = this.cesium_viewer.scene;

    scene.screenSpaceCameraController.enableRotate = b;
    scene.screenSpaceCameraController.enableTranslate = b;
    scene.screenSpaceCameraController.enableZoom = b;
    scene.screenSpaceCameraController.enableTilt = b;
    scene.screenSpaceCameraController.enableLook = b;
  }

  __getCameraPositionOfPlane(planePosition, heading, pitch, roll, zoom, lock_all_axis) {
    let hpr = undefined;

    if (lock_all_axis)
    {
      hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), Cesium.Math.toRadians(roll), Cesium.Math.toRadians(pitch));
    } 
    else
    {
      hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(heading), 0, 0);
    }
    
    const planeModelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(planePosition, hpr);

    let yAxis = new Cesium.Cartesian3();

    yAxis.x = planeModelMatrix[4];
    yAxis.y = planeModelMatrix[5];
    yAxis.z = planeModelMatrix[6];

    const offsetLength = 10;
    let length = zoom * 2 + offsetLength;

    let distance = length / Math.tan(this.cesium_viewer.camera.frustum.fov / 2);
    let offset = Cesium.Cartesian3.multiplyByScalar(yAxis, -distance, new Cesium.Cartesian3());

    let cameraPosition = Cesium.Cartesian3.add(planePosition, offset, new Cesium.Cartesian3());

    return cameraPosition;
	}

  __is_valid_position(lon, lat, alt)
  {
    return !!((Validation.is_int(lon) || Validation.is_float(lon)) &&
              (Validation.is_int(lat) || Validation.is_float(lat)) &&
              (Validation.is_int(alt) || Validation.is_float(alt)));
  }

  __is_valid_rotation(heading, pitch, roll)
  {
    return !!((Validation.is_int(heading) || Validation.is_float(heading)) &&
              (Validation.is_int(pitch) || Validation.is_float(pitch)) &&
              (Validation.is_int(roll) || Validation.is_float(roll)));
  }
}
