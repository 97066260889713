import CameraMovementMode from './CameraMovementMode';

export default class ImmediateMode extends CameraMovementMode
{
  constructor()
  {
    super();
    this.rotation_speed = new THREE.Vector2();

    this.vector_up_axis   = new THREE.Vector3(0, 1, 0);
    this.vector_right_axis   = new THREE.Vector3(-1, 0, 0);
    this.vector_forward_axis   = new THREE.Vector3(0, 0, 1);
    this.tmp_up1 = new THREE.Vector3();
    this.tmp_up2 = new THREE.Vector3();
    this.tmp_forward = new THREE.Vector3();

    this.tmp_new_pos = new THREE.Vector3();

    this.tmp_quat = new THREE.Quaternion();
    this.tmp_camera_target_pos = new THREE.Vector3();
  }

  on_enter(camera_controller)
  {
    camera_controller.reference_rotation.copy(camera_controller.camera.quaternion);
  }

  update(camera_controller)
  {
    // the 360- correction makes it go to the right when the orientation increases from 0 to 360
    // the +180 correction compensates for the camera in webgl looking at Z -1
    let current_orientation = (360 - camera_controller.current_orientation) + 180;
    let current_tilt        = camera_controller.current_tilt * -1;
    //let current_orientation = (360 - camera_controller.new_pan) + 180;
    //let current_tilt        = camera_controller.new_tilt * -1;
    let new_orientation = new THREE.Quaternion().setFromAxisAngle(this.vector_up_axis, THREE.Math.degToRad(current_orientation));
    let new_tilt        = new THREE.Quaternion().setFromAxisAngle(this.vector_right_axis, THREE.Math.degToRad(current_tilt));

    //camera_controller.current_orientation = camera_controller.new_pan;
    //camera_controller.current_tilt = camera_controller.new_tilt;

    let quat = new THREE.Quaternion().multiply(new_orientation).multiply(new_tilt);
    camera_controller.camera.quaternion.copy(quat);

    // camera_controller.camera.position.set(0,0,0);

    this.tmp_forward.copy(this.vector_forward_axis);
    let dir = this.tmp_forward.applyQuaternion(camera_controller.camera.quaternion);

    camera_controller.reference_zoom = THREE.Math.clamp(camera_controller.reference_zoom,
      camera_controller.min_zoom, camera_controller.max_zoom);

    camera_controller.camera.position.copy(camera_controller.reference_position).sub(dir.multiplyScalar(camera_controller.reference_zoom));
    camera_controller.__last_reference_position.copy(camera_controller.reference_position);

    this.update_normalized_zoom(camera_controller.min_zoom, camera_controller.max_zoom);
  }

  update_normalized_zoom(min_zoom, max_zoom)
  {
    if (this.camera)
    {
      let zoom = this.camera.position.distanceTo(this.reference_position);
      this.normalized_zoom = this.linear_map(zoom, min_zoom, max_zoom, 1, 0);
      this.normalized_zoom = THREE.Math.clamp(this.normalized_zoom, 0, 1);  
    }
  }

  // get_target_camera_pos(camera_controller)
  // {
  //   this.tmp_quat.copy(camera_controller.reference_rotation);
  //   this.tmp_forward.copy(this.vector_forward_axis);

  //   let dir = this.tmp_forward.applyQuaternion(this.tmp_quat);

  //   let zoom = THREE.Math.clamp(camera_controller.reference_zoom,
  //     camera_controller.min_zoom, camera_controller.max_zoom);

  //   this.tmp_camera_target_pos.copy(camera_controller.reference_position).sub(dir.multiplyScalar(zoom));
  //   return this.tmp_camera_target_pos;
  // }
}
