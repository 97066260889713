import CesiumHandler from './CesiumHandler';
import { MathUtilities } from 'ohzi-core';
import { Validation } from 'ohzi-core';

// import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import PlayerSettings from './PlayerSettings';
import CustomPlane from './cesium/CustomPlane';
import CameraTargetUtilities from './CameraTargetUtilities';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import NotificationManager from '/js/components/NotificationManager';

// This class is a wrapper for a cesium marker
export default class MapMarker
{
  constructor({ text, lon, lat, alt, map_zoom, scale, opacity, is_inverted, show_custom_sphere, show_custom_plane, show_point, custom_object_url })
  {
    this.text = text;

    this.lon = lon;
    this.lat = lat;
    this.alt = alt;

    this.yaw = undefined;
    this.pitch = undefined;
    this.roll = undefined;

    this.map_zoom = map_zoom;
    this.scale = scale;
    this.opacity = opacity;
    this.is_inverted = is_inverted;
    this.show_custom_sphere = show_custom_sphere;
    this.show_custom_plane = show_custom_plane;
    this.show_point = show_point;
    this.custom_object_url = custom_object_url;

    this.cesium_marker = undefined;
    this.cesium_marker_sphere = undefined;
    this.cesium_marker_plane = undefined;
    this.cesium_text = undefined;

    // Sphere defaults
    //this.width    = 5.0;
    //this.height   = 5.0;
    this.width    = scale * 2;
    this.height   = scale * 2;

    this.alert    = 0;

    this.rotationXPositive180 = Cesium.Matrix3.fromRotationX(Cesium.Math.PI, new Cesium.Matrix3());
    this.rotationXMatrix4Positive180 = Cesium.Matrix4.fromRotationTranslation(this.rotationXPositive180, undefined, new Cesium.Matrix4());

    this.create_marker();
  }

  create_marker()
  {
    let payload = CesiumHandler.add_marker(
      {
        text: this.__create_label(),
        eye_offset: -1.1,
        model_url: this.custom_object_url,
        custom_sphere: this.show_custom_sphere,
        custom_plane: this.show_custom_plane,
        show_point: this.show_point,
        is_inverted: this.is_inverted,
        lon: this.lon,
        lat: this.lat,
        alt: this.alt,
        show: true,
        scale: this.scale,
        opacity: this.opacity,
        track: true
      }
    );

    this.cesium_marker = payload.marker;
    this.cesium_marker_sphere = payload.marker_sphere;

    // TODO: Move these to PlaneMapMaker
    this.cesium_marker_plane = payload.marker_plane;
    this.cesium_marker_plane_maker = payload.marker_plane_maker;
    this.cesium_marker_plane_scale = payload.marker_plane_scale;
    this.cesium_marker_tracked = payload.marker_tracked;
    this.cesium_text = payload.text;
    this.cesium_custom_3d_object = payload.marker_custom_3d_object;
    this.cesium_marker_point = payload.marker_point;

    if (!this.custom_object_url)
    {
      this.cesium_camera_ground_arrow = CesiumHandler.add_ground_arrow(
        {
          lon: this.lon,
          lat: this.lat,
          alt: this.alt
        }
      );
    }

    this.cesium_camera_arrow = CesiumHandler.add_camera_arrow(
      {
        lon: this.lon,
        lat: this.lat,
        alt: this.alt
      }
    );

    // Hide by default
    this.hide();
  }

  set_alert(alert)
  {
    //console.log(`[set_alert] alert: ${alert}, this.alert: ${this.alert}`);
    //console.dir(this.alert);
    //if (typeof alert !== 'undefined') {
    if (alert === 1 && this.alert !== 1) {
      console.log(`New Alert Detected for taglock '${this.text}'`);
      // Fly map to center on taglock marker and play sound alert
      this.fly_to_direct();
      //this.fly_to();
      //this.center_to_location();
      NotificationManager.play_sound_alert();
    }
    if (alert === 0 && this.alert !== 0) {
      console.log(`Alert End Detected for taglock '${this.text}'`);
      // Fly map to center on taglock marker and play sound alert
      NotificationManager.stop_sound_alert();
    }
    this.alert = alert;
  }

  set_video_resolution(height, width)
  {
    // console.log(`[MapMarker:set_video_resolution] [${this.text}] height: ${height}, width: ${width}, yaw: ${this.yaw}, pitch: ${this.pitch}, roll: ${this.roll}`);
    // TODO: Convert to aspect ratio and keep within the size of 19:6
    if (width < 37 || height < 37) {
      console.log(`[MapMarker:set_video_resolution] [${this.text}] height: ${height}, width: ${width} is not Valid. Don't update resolution`);
      return;
    }
    this.width = width / 37;
    this.height = height / 37;

    // console.log(`[MapMarker:set_video_resolution]: converted to height: ${this.height}, width: ${this.width}`);

    let position = Cesium.Cartesian3.fromDegrees(
      this.lon,
      this.lat,
      this.alt
    );

    // Update rotation to change to the new width/height
    this.set_rotation(this.yaw, this.pitch, this.roll, true);
    this.update_show_marker_plane();
    // Update size of arrow and position of text
    this.update_marker_ground_arrow(position);
    this.update_marker_text(position);
  }

  set_position(lon, lat, alt, tgt_distance, force_update)
  {
    // console.log(`[MapMarker:set_position] [${this.text}] `);

    let valid_position = CameraTargetUtilities.is_valid_non_zero_position(lon, lat, alt);

    if (valid_position)
    {
      // console.log(`[MapMarker:set_position] [${this.text}] Position valid. this.long: ${this.lon}, lon: ${lon}`);
      this.tgt_distance = parseFloat(tgt_distance);

      if (!MathUtilities.equals(parseFloat(this.lon), parseFloat(lon)) ||
          !MathUtilities.equals(parseFloat(this.lat), parseFloat(lat)) ||
          !MathUtilities.equals(parseFloat(this.alt), parseFloat(alt)) ||
          force_update)
      {
        // console.log(`[MapMarker:set_position] [${this.text}] Position new and valid. lat: ${lat}, lon: ${lon}`);
        this.lon = parseFloat(lon);
        this.lat = parseFloat(lat);
        this.alt = parseFloat(alt);

        let position = Cesium.Cartesian3.fromDegrees(
          this.lon,
          this.lat,
          this.alt
        );

        // console.log(`[MapMarker:set_position] [${this.text}] lat/lon/alt: ${this.lat}, ${this.lon}, ${this.alt}`);
        this.cesium_marker.position = position;
        this.update_marker_text(position);
        this.update_marker_ground_arrow(position);

        if (this.cesium_marker_tracked)
        {
          this.cesium_marker_tracked.position = position;
          // Use primitive to keep entities in sync when moving (NOTE: Cannot track Primitives only Entities)
          //this.cesium_marker_tracked._pointPrimitives[0].position = position;
        }

        if (this.cesium_marker_point)
        {
          let position_no_alt = Cesium.Cartesian3.fromDegrees(
            this.lon,
            this.lat,
            0.0
          );
          this.cesium_marker_point.position = position_no_alt;
        }

        if (this.cesium_marker_sphere)
        {
          this.cesium_marker_sphere.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);
        }

        if (this.cesium_marker_plane)
        {
          this.cesium_marker_plane.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);

          // Force rotation update to move arrows etc.
          this.set_rotation(this.yaw, this.pitch, this.roll, true);
        }

        this.set_cesium_3d_object_position();
      }
    }
  }

  // TODO: pitch/roll are backwards compared to the calling function but it works. Is the transform backwards?
  set_rotation(yaw, pitch, roll, force_update)
  {
    // console.log(`[MapMarker:set_rotation] [${this.text}] yaw: ${yaw}, pitch: ${pitch}, roll: ${roll}`)

    let valid_rotation = this.__is_valid_marker_position(yaw, pitch, roll);

    if (valid_rotation)
    {
      if (!MathUtilities.equals(parseFloat(this.yaw), parseFloat(yaw)) ||
          !MathUtilities.equals(parseFloat(this.pitch), parseFloat(pitch)) ||
          !MathUtilities.equals(parseFloat(this.roll), parseFloat(roll)) ||
          force_update)
      {
        // console.log(`[MapMarker:set_rotation] Inside [${this.text}] yaw: ${yaw}, pitch: ${pitch}, roll: ${roll}`)

        this.yaw    = parseFloat(yaw);
        this.pitch  = parseFloat(pitch);
        this.roll   = parseFloat(roll);

        // console.log(`[MapMarker:set_rotation] degrees rpy: ${roll}, ${pitch}, ${yaw}`);
        if (this.cesium_marker_plane)
        {
          roll += 90;
        }

        yaw         = Cesium.Math.toRadians(yaw);
        pitch       =   Cesium.Math.toRadians(pitch);
        roll        =    Cesium.Math.toRadians(roll);

        let position = Cesium.Cartesian3.fromDegrees(
          this.lon,
          this.lat,
          this.alt
        );

        const hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll);

        if (this.cesium_marker_sphere)
        {
          this.cesium_marker_sphere.modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(position, hpr);

          if (this.is_inverted)
          {
            this.cesium_marker_sphere.modelMatrix = Cesium.Matrix4.multiply(this.cesium_marker_sphere.modelMatrix, this.rotationXMatrix4Positive180, new Cesium.Matrix4());
          }

          // this.cesium_marker_sphere.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);
          // Needed to reset the size as this rotation command affects the size of the sphere so we need to reset it
          this.set_scale(this.scale);
        }
        if (this.cesium_marker_plane)
        {
          if (this.width <= 0 || this.height <= 0)
          {
            console.log(`[set_rotation] width: ${this.width} or height: ${this.height} is 0. Abort updating plane dimensions`);
            return;
          }
          const planeDimensions = new Cesium.Cartesian3(this.width, this.height, 1.0);

          const rotatedPlaneModelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(position, hpr);

          this.cesium_marker_plane.modelMatrix = Cesium.Matrix4.multiplyByScale(rotatedPlaneModelMatrix, planeDimensions, new Cesium.Matrix4());

          this.update_plane_normal_arrow(position, rotatedPlaneModelMatrix);
        }
      }
    }
  }

  update_marker_text(position)
  {
    //console.log(`[update_marker_text] [${this.text}] `);
    //console.log(`[update_marker_text] height: ${this.height}, width: ${this.width} `);
    //console.dir(this.cesium_text);
    if (!this.cesium_text)
    {
      return;
    }

    let defaultModelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position, undefined, new Cesium.Matrix4());

    let zAxisNormal = new Cesium.Cartesian3();

    zAxisNormal.x = defaultModelMatrix[8];
    zAxisNormal.y = defaultModelMatrix[9];
    zAxisNormal.z = defaultModelMatrix[10];

    let offset = ((this.cesium_marker_sphere && this.cesium_marker_sphere.show) || (this.cesium_marker_plane && this.cesium_marker_plane.show)) ? (this.height / 2.0) + 1 : 1;
    //console.log(`[update_marker_text] offset: ${offset}`);

    let extendedZAxisNormal = Cesium.Cartesian3.multiplyByScalar(zAxisNormal, offset, new Cesium.Cartesian3());
    let labelPosition = Cesium.Cartesian3.add(position, extendedZAxisNormal, new Cesium.Cartesian3());

    this.cesium_text.position = labelPosition;
  }

  update_plane_normal_arrow(position, rotatedPlaneModelMatrix)
  {
    if (!this.cesium_camera_arrow)
    {
      return;
    }

    // Arrow pointing
    const planeNormalVectorArrowLength = 10;

    // north direction
    let yAxisNormalOfRotatedPlaneModelMatrix = new Cesium.Cartesian4();

    yAxisNormalOfRotatedPlaneModelMatrix.x = -rotatedPlaneModelMatrix[8];
    yAxisNormalOfRotatedPlaneModelMatrix.y = -rotatedPlaneModelMatrix[9];
    yAxisNormalOfRotatedPlaneModelMatrix.z = -rotatedPlaneModelMatrix[10];

    yAxisNormalOfRotatedPlaneModelMatrix.w = 0;

    let extendedWordNormalVector = Cesium.Cartesian3.multiplyByScalar(yAxisNormalOfRotatedPlaneModelMatrix, planeNormalVectorArrowLength, new Cesium.Cartesian3());
    let normalVectorEndPosition = Cesium.Cartesian3.add(position, extendedWordNormalVector, new Cesium.Cartesian3());

    let thePlaneNormalArrowPositions = [position, normalVectorEndPosition.clone()];
    if (this.cesium_camera_arrow)
    {
      this.cesium_camera_arrow._polylines[0].positions = thePlaneNormalArrowPositions;
    }
  }

  update_marker_ground_arrow(position)
  {
    if (!this.cesium_camera_ground_arrow)
    {
      return;
    }

    let pos_cart = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);

    // console.log(`[MapMarker:update_marker_ground_arrow] pos_car.height: ${pos_cart.height}, this.height: ${this.height}`);

    let lng = Cesium.Math.toDegrees(pos_cart.longitude);
    let lat = Cesium.Math.toDegrees(pos_cart.latitude);

    let result = (pos_cart.height - (this.height / 2.0));

    let groundNormalVectorEndPosition = Cesium.Cartesian3.fromDegrees(
      lng,
      lat,
      0.0
    );

    let offset = ((this.cesium_marker_sphere && this.cesium_marker_sphere.show) || (this.cesium_marker_plane && this.cesium_marker_plane.show)) ? (this.height / 2.0) : 0;

    let groundNormalVectorStartPosition = Cesium.Cartesian3.fromDegrees(
      lng,
      lat,
      (pos_cart.height - offset)
    );

    let theGroundArrowPositions = [groundNormalVectorStartPosition, groundNormalVectorEndPosition];

    if (this.cesium_camera_ground_arrow)
    {
      this.cesium_camera_ground_arrow._polylines[0].positions = theGroundArrowPositions;
    }
  }

  set_cesium_3d_object_position()
  {
    if (this.cesium_custom_3d_object)
    {
      let position = Cesium.Cartesian3.fromDegrees(
        this.lon,
        this.lat,
        this.alt - 5
      );

      this.cesium_custom_3d_object.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);

      // Force rotation update to move arrows etc.
      this.set_rotation(this.yaw, this.pitch, this.roll, true);
    }
  }

  set_inverted(is_inverted)
  {
    if (this.is_inverted !== is_inverted)
    {
      this.is_inverted = is_inverted;

      if (this.cesium_marker_sphere)
      {
        this.cesium_marker_sphere.modelMatrix = Cesium.Matrix4.multiply(this.cesium_marker_sphere.modelMatrix, this.rotationXMatrix4Positive180, new Cesium.Matrix4());
      }
    }
  }

  fly_to()
  {
    CesiumHandler.fly_to_marker(this);
  }

  fly_to_direct()
  {
    CesiumHandler.fly_to_marker_direct(this);
  }

  fly_to_new_position(map_zoom)
  {
    this.map_zoom = THREE.Math.clamp(parseFloat(map_zoom), 0, Number.MAX_SAFE_INTEGER);

    CesiumHandler.fly_to(this.lon, this.lat, this.alt, this.map_zoom);
  }

  fly_to_new_target_position(map_zoom)
  {
    this.map_zoom = THREE.Math.clamp(parseFloat(map_zoom), 0, Number.MAX_SAFE_INTEGER);

    CesiumHandler.fly_to_target(this.lon, this.lat, this.map_zoom);
  }

  fly_to_camera_position(camera, map_zoom)
  {
    // console.log(`[MapMarker:fly_to_camera_position]`);
    // console.dir(camera);
    // console.dir(this);
    this.map_zoom = THREE.Math.clamp(parseFloat(map_zoom), 0, Number.MAX_SAFE_INTEGER);

    CesiumHandler.fly_to_camera(this, camera, this.map_zoom);
  }

  set_view_to_plane(map_zoom, lock_all_axis)
  {
    // console.log(`[MapMarker:set_view_to_plane] lock_all_axis: ${lock_all_axis}`);
    this.map_zoom = THREE.Math.clamp(parseFloat(map_zoom), 0, Number.MAX_SAFE_INTEGER);

    let position = Cesium.Cartesian3.fromDegrees(
      this.lon,
      this.lat,
      this.alt
    );

    // TODO: Swap pitch/roll as they are backwards in this MapMarker (need to fix other places first)
    // CesiumHandler.set_view_to_plane(position, this.yaw, this.pitch, this.roll, this.map_zoom);
    CesiumHandler.set_view_to_plane(position, this.yaw, this.roll, this.pitch, this.map_zoom, lock_all_axis);
  }

  set_name(name)
  {
    if (this.text !== name)
    {
      this.text = name;
      this.cesium_text.label.text = this.__create_label();
      // this.cesium_marker.label.text = this.__create_label();
    }
  }

  update_marker_color()
  {
    this.cesium_text.label.fillColor=CesiumHandler.label_color;
  }

  set_scale(scale)
  {
    this.scale = parseFloat(scale || 1);
    // this.cesium_marker.ellipsoid.radii = new Cesium.Cartesian3(scale, scale, scale);

    if (this.cesium_marker_sphere)
    {
      // console.dir(`[MapMarker:set_scale] [${this.text}] scale: ${scale}, this.scale: ${this.scale}`);
      this.cesium_marker_sphere.modelMatrix = Cesium.Matrix4.setScale(
        this.cesium_marker_sphere.modelMatrix,
        new Cesium.Cartesian3(this.scale, this.scale, this.scale),
        new Cesium.Matrix4()
      );
    }
    else if (this.cesium_custom_3d_object)
    {
      this.cesium_custom_3d_object.modelMatrix = Cesium.Matrix4.setScale(
        this.cesium_custom_3d_object.modelMatrix,
        new Cesium.Cartesian3(this.scale, this.scale, this.scale),
        new Cesium.Matrix4()
      );
    }
  }

  set_opacity(opacity)
  {
    if (this.cesium_marker_sphere && this.opacity !== opacity)
    {
      this.opacity = opacity;
      // this.cesium_marker.ellipsoid.material.color = new Cesium.Color(1.0, 1.0, 1.0, opacity);
      // this.cesium_marker.model.color = Cesium.Color.WHITE.withAlpha(opacity);
      this.cesium_marker_sphere.appearance.material.uniforms.alpha = opacity;
    }
  }

  set_image_url(image_url)
  {
    // console.log('[MapMarker:set_image_url]', image_url);
    // console.dir(image_url);
    // this.cesium_marker.ellipsoid.material.image = image_url;
    if (this.cesium_marker_sphere && typeof image_url === 'string')
    {
      this.cesium_marker_sphere.appearance.material.uniforms.image = image_url;
    }

    if (this.cesium_marker_plane)
    {
      CesiumHandler.viewer.scene.primitives.remove(this.cesium_marker_plane);
      let custom_plane_primitive = this.cesium_marker_plane_maker.create_custom_mapping_plane_primitive(this.cesium_marker_plane_scale || 1, 16, 9);

      this.cesium_marker_plane = CesiumHandler.viewer.scene.primitives.add(custom_plane_primitive);
      this.cesium_marker_plane.appearance.material.uniforms.image = image_url;

      this.update_show_marker_plane();

      // Have to call this to setup the location, orietnation and size of the new plane correctly again.
      // this.set_rotation(this.yaw, this.pitch, this.roll, true);
      this.set_position(this.lon, this.lat, this.alt, this.tgt_distance, true);
    }
  }

  update()
  {
  }

  update_arrow_text_position()
  {
    if (CameraTargetUtilities.is_valid_non_zero_position(this.lon, this.lat, this.alt))
    {
      let position = Cesium.Cartesian3.fromDegrees(
        this.lon,
        this.lat,
        this.alt
      );

      this.update_marker_ground_arrow(position);
      this.update_marker_text(position);
    }
  }

  // Make sure plane stays hidden if map_view is off on set_video_resolution() or set_image_url()
  update_show_marker_plane()
  {
    if (!this.cesium_marker_plane)
    {
      return;
    }
    /*
    if (this.text === PhysicalCameraManager.selected_camera.name ||
      this.text === PhysicalCameraManager.selected_camera_tandem.name)
    {
      console.log(`[update_show_marker_plane] this marker is selected, don't hide marker`);
      return;
    }
*/
    let camera_el = $(`.menu__cameras-camera[data-name='${this.text}']`);

    if ( this.cesium_marker_plane && camera_el && !$(camera_el).find('.menu__cameras-camera-map_view').hasClass('active') )
    {
      this.cesium_marker_plane.show = false;
      this.cesium_camera_arrow.show = false;
    }
    this.update_arrow_text_position();
  }

  show(partial)
  {
    // console.log(`[MapMarker:show] [${this.text}] partial: ${partial}`);
    // console.trace();
    if (this.__is_valid_marker_position(this.lon, this.lat, this.alt))
    {
      //console.log(`[MapMarker:show] [${this.text}] position valid`);
      this.cesium_marker.show = true;

      if (!partial)
      {
        this.__show(this.cesium_marker_sphere);
        this.__show(this.cesium_marker_plane);
        this.__show(this.cesium_camera_arrow);
        this.__show_polylines(this.cesium_camera_arrow);
      }
      this.__show(this.cesium_text);
      this.__show(this.cesium_camera_ground_arrow);
      this.__show_polylines(this.cesium_camera_ground_arrow);
    }
    this.update_arrow_text_position();
  }

  hide(partial)
  {
    // console.log(`[MapMarker:hide] [${this.text}] partial: ${partial}`);
    // console.trace();
    this.cesium_marker.show = false;

    this.__hide(this.cesium_marker_sphere);
    this.__hide(this.cesium_marker_plane);
    this.__hide(this.cesium_camera_arrow);
    this.__hide_polylines(this.cesium_camera_arrow);
    if (!partial)
    {
      this.__hide(this.cesium_text);
      this.__hide(this.cesium_camera_ground_arrow);
      this.__hide_polylines(this.cesium_camera_ground_arrow);
    }
    this.update_arrow_text_position();
  }

  dispose()
  {
    console.log(`[MapMarker:dispose]`);

    CesiumHandler.viewer.scene.primitives.remove(this.cesium_marker_sphere);
    CesiumHandler.viewer.scene.primitives.remove(this.cesium_marker_plane);
    CesiumHandler.viewer.scene.primitives.remove(this.cesium_custom_3d_object);
    CesiumHandler.viewer.scene.primitives.remove(this.cesium_camera_arrow);
    CesiumHandler.viewer.scene.primitives.remove(this.cesium_camera_ground_arrow);
    //CesiumHandler.viewer.entities.remove(this.cesium_camera_arrow);
    CesiumHandler.viewer.entities.remove(this.cesium_text);
    //CesiumHandler.viewer.entities.remove(this.cesium_camera_ground_arrow);
    // CesiumHandler.viewer.entities.remove(this.cesium_marker);

    // Stop alert sound if active
    NotificationManager.stop_sound_alert();
  }

  __show(marker)
  {
    if (marker)
    {
      marker.show = true;
    }
  }

  __hide(marker)
  {
    if (marker)
    {
      marker.show = false;
    }
  }

  __show_polylines(marker)
  {
    if (marker)
    {
      marker._polylines[0].show = true;
    }
  }

  __hide_polylines(marker)
  {
    if (marker)
    {
      marker._polylines[0].show = false;
    }
  }

  __create_label()
  {
    return this.text;
  }

  __is_valid_marker_position(lon, lat, alt)
  {
    return !!((Validation.is_int(lon) || Validation.is_float(lon)) &&
              (Validation.is_int(lat) || Validation.is_float(lat)) &&
              (Validation.is_int(alt) || Validation.is_float(alt)));
  }
}
