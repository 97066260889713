import { Input } from 'ohzi-core';
import { Time } from 'ohzi-core';
import { MathUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import CameraViewState from '../states/CameraViewState';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This class handles the movement requests of a PTZ camera and fixes the THREE camera to a position
export default class PTZControls extends CameraViewState
{
  constructor()
  {
    super();

    this.upper_tilt_limit = 90;
    this.lower_tilt_limit = -90;

    this.horizontal_rot_speed = 0;
    this.vertical_rot_speed = 0;

    this.zoom_t = 0;

    this.starting_fov = 60;
    this.zoomed_in_fov = undefined;

    this.fisheye_fov = 180;
    this.use_upper_hemisphere = false;

    this.pan_enabled = false;

    this.last_NDC = new THREE.Vector2();

    this.click_pos = new THREE.Vector2();
    this.pan_speed = 0;
    this.tilt_speed = 0;

    this.plane_width = 160;
    this.plane_height = 90;
  }

  start()
  {
    let app_config = ResourceContainer.get_resource('config');
    this.x_rotation_speed = app_config.x_rotation_speed;
    this.y_rotation_speed = app_config.y_rotation_speed;
  }

  set_fisheye_fov_and_hemisphere(fisheye_fov, use_upper_hemisphere)
  {
    this.fisheye_fov          = fisheye_fov;
    this.use_upper_hemisphere = use_upper_hemisphere;
  }

  on_enter(camera_controller)
  {
    this.starting_fov = camera_controller.camera.fov;
    this.zoomed_in_fov = this.starting_fov * 0.1;

    this.update_camera_distance(camera_controller);
  }

  update(camera_controller)
  {
    if (!camera_controller.input_enabled)
    {
      return;
    }

    if (Input.left_mouse_button_pressed && Input.mouse_is_within_bounds())
    {
      this.last_NDC.copy(Input.NDC);
      this.click_pos.copy(Input.NDC); // GG
      this.pan_enabled = true;
    }
    if (Input.left_mouse_button_down && this.pan_enabled)
    {
      let delta_NDC = Input.NDC.clone().sub(this.last_NDC);
      this.horizontal_rot_speed   += delta_NDC.x * this.x_rotation_speed;
      this.vertical_rot_speed     -= delta_NDC.y * this.y_rotation_speed;
      this.last_NDC.copy(Input.NDC);

      let delta_click = Input.NDC.clone().sub(this.click_pos);
      // this.pan_speed = delta_click.x * 40;
      // this.tilt_speed = delta_click.y * 40;
      this.pan_speed = THREE.Math.clamp((delta_click.x.toFixed(1) * 40), -60, 60);   // min/mac -60/60
      this.tilt_speed = THREE.Math.clamp((delta_click.y.toFixed(1) * 40), -60, 60);   // min/mac -60/60
    }
    if (Input.left_mouse_button_released)
    {
      this.pan_enabled = false;
      this.pan_speed = 0;
      this.tilt_speed = 0;
    }

    if (Input.mouse_is_within_bounds())
    {
      this.update_zoom(camera_controller);
    }

    // TODO: Are these used on PTZ mode?
    // this.update_tilt_bounds(camera_controller);
    // this.update_rotation(camera_controller, this.horizontal_rot_speed, this.vertical_rot_speed);

    this.horizontal_rot_speed *= 0.9; // decay
    this.vertical_rot_speed   *= 0.9; // decay

    camera_controller.camera.position.set(0, 0, camera_controller.get_zoom_to_show_rect(this.plane_width, this.plane_height));
  }

  update_rotation(camera_controller, horizontal_rot, vertical_rot)
  {
    if (camera_controller.current_tilt + vertical_rot > this.upper_tilt_limit)
    {
      vertical_rot = this.upper_tilt_limit - camera_controller.current_tilt;
    }

    if (camera_controller.current_tilt + vertical_rot < this.lower_tilt_limit)
    {
      vertical_rot = this.lower_tilt_limit - camera_controller.current_tilt;
    }

    camera_controller.set_rotation_delta(horizontal_rot, vertical_rot);
  }

  update_zoom(camera_controller)
  {
    let zoom_delta = this.get_zoom_delta();

    let power = Input.is_mac() ? -10 : -0.3;
    this.zoom_t += zoom_delta * Time.delta_time * power;
    this.zoom_t = THREE.Math.clamp(this.zoom_t, 0, 1);
  }

  get_zoom_delta()
  {
    if (Input.zoom_started)
    {
      return Input.touch_zoom_delta * -0.05;
    }
    else
    {
      return Input.wheel_delta;
    }
  }

  update_tilt_bounds(camera_controller)
  {
    if (this.use_upper_hemisphere)
    {
      this.upper_tilt_limit = 90;
      this.lower_tilt_limit = MathUtilities.linear_map(this.fisheye_fov, 0, 360, 90, -90) + camera_controller.camera.fov / 2;
    }
    else
    {
      this.upper_tilt_limit = MathUtilities.linear_map(this.fisheye_fov, 0, 360, -90, 90) - camera_controller.camera.fov / 2;
      this.lower_tilt_limit = -90;
    }
  }

  update_camera_distance(camera_controller)
  {
    // console.log(`[PTZControls:on_enter]`);
    // console.dir(camera_controller);
    let fov = camera_controller.camera.fov;

    let selected_camera = PhysicalCameraManager.selected_camera;

    // let video_height = selected_camera.video_height;
    let video_height = 360;
    let video_width = 640;

    // console.dir(selected_camera);

    // let dist = selected_camera.video_height / 2 / Math.tan(Math.PI * fov / 360);
    // let dist = video_height / 2 / Math.tan(Math.PI * fov / 360);
    // let dist = video_width / 2 / Math.tan(Math.PI * fov / 360);
    let dist = 0;

    let player_width = camera_controller.player_container.container.width();
    let player_height = camera_controller.player_container.container.height();

    // let new_dist = dist / (player_height / 70);
    // let new_dist = dist / (player_width / 65);
    let new_dist = 0;

    let fovh = fov * video_width / video_height;  // Calculate FOV (horiztonal plane) based on fov and spect ratio

    if (camera_controller.player_container.is_maximized)
    {
      // Player is full screen
      dist = video_width / 2 / Math.tan(Math.PI * fovh / 360);
      new_dist = dist / (player_width / 417);
      // console.log(`[PTZControls:on_enter] full screen - fovh: ${fovh}, dist: ${dist}, new_dist: ${new_dist}`);
    }
    else
    {
      // Not full screen
      dist = video_width / 2 / Math.tan(Math.PI * fovh / 360);
      new_dist = dist / (player_width / 152);
      // console.log(`[PTZControls:on_enter] not full screen - fov: ${fovh}, dist: ${dist}, new_dist: ${new_dist}`);
    }
    // console.log(`[PTZControls:on_enter] selected_camera.video_height: ${video_height}, dist: ${dist}, new_dist: ${new_dist}`);

    // console.log(`[PTZControls:on_enter] player_container player_width: ${player_width}, player_height: ${player_height}`);

    // Test app.camera_controller.camera.position.set(0,0,90)

    if (PhysicalCameraManager.selected_camera)
    {
      this.plane_width = PhysicalCameraManager.selected_camera.fisheye_sphere.scale.x;
      this.plane_height = PhysicalCameraManager.selected_camera.fisheye_sphere.scale.y;
    }

    // Set camera perspective to default for flat camera (we don't change this during this view)
    // camera_controller.camera.near = 0.1;
    camera_controller.camera.far = 1000;
    camera_controller.camera.position.set(0, 0, camera_controller.get_zoom_to_show_rect(this.plane_width, this.plane_height));
    // camera_controller.camera.position.set(0, 0, new_dist);
    camera_controller.camera.rotation.set(0, 0, 0);
    camera_controller.camera.quaternion.set(0, 0, 0, 1);
    camera_controller.camera.updateProjectionMatrix();
  }
}
