import MenuCameraStatusState  from './MenuCameraStatusState';
import WaitingForStreaming    from './WaitingForStreaming';
import { Time } from 'ohzi-core';
export default class ReceivingConfigurationUpdate extends MenuCameraStatusState
{
  constructor()
  {
    super();

    this.elapsed_time = 0;
    this.timer = 0;
  }

  on_enter(menu_camera_status)
  {

  }

  update(menu_camera_status)
  {
    this.elapsed_time += Time.delta_time;
    this.timer += Time.delta_time;

    let color = (this.timer * 2) % 1 > 0.5 ? '#008000' : '#00ff00';
    menu_camera_status.set_rec_icon_color(color);

    if (this.elapsed_time > 1)
    {
      menu_camera_status.set_state(new WaitingForStreaming());
    }
  }

  on_config_received(menu_camera_status)
  {
    this.elapsed_time = 0;
  }
}
