import { ResourceContainer } from 'ohzi-core';
import TelemetryController from './TelemetryController';
import RoleManager from '/js/components/RoleManager';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This handles the streaming of the archive telemetry
export default class ArchiveTelemetryController extends TelemetryController
{
  constructor(app)
  {
    super(app, app.config.archive_telemetry_websocket_host, app.menu_view.tabs.ARCHIVE);
  }

  start_streaming(camera, fragment, playback_time)
  {
    let app_config = ResourceContainer.get_resource('config');
    let playback_offset = playback_time - fragment.start_ms;
    //let start_date_ms = fragment.recording.start_date.valueOf() + playback_offset;
    let camera_id = camera._id;

    let start_time = moment(fragment.recording.start_date).add(playback_offset).format('YYYY-MM-DDTHH:mm:ssZ');
    //  let start_time = '2020-09-11T22:23:11-04:00'; // Test bad start time

    console.log(`[ArchiveTelemetryController:start_streaming] formatted_start_time: ${start_time}`);
    
    camera.is_telemetry_streaming = true;

    let data = {
      start_time: start_time,
      camera_id: camera_id
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/internal/streaming/start`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__start_streaming_response.bind(this)
    });
  }

  stop_streaming(camera)
  {
    console.log(`[ArchiveTelemetryController:stop_streaming]`);
    let app_config = ResourceContainer.get_resource('config');

    camera.is_telemetry_streaming = false;

    let data = {
      camera_id: camera._id
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/internal/streaming/stop`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__stop_streaming_response.bind(this)
    });
  }

  __start_streaming_response(response)
  {
    this.__show_error_messages(response);

    if (response.status !== 200)
    {
      // Fly if telemetry start fails
      PhysicalCameraManager.selected_camera.fly_to_camera_marker();
    }
  }

  __stop_streaming_response(response)
  {
    this.__show_error_messages(response);
  }

  __pad(value)
  {
    if (value < 10)
    {
      return '0' + value;
    }
    else
    {
      return '' + value;
    }
  }

  // __telemetry_extract_response(response)
  // {
  //   console.log(response.responseJSON);
  //   if (response.responseJSON.status === 'error')
  //   {
  //     this.app.modal_window_view.show(response.responseJSON.description);
  //   }
  // }
}
