import UserRole from './UserRole';

export default class CustLiveTagArcRole extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    ViewApi.hide_player_info_button();
    ViewApi.hide_menu_options();
    ViewApi.hide_recordings_tab();
    ViewApi.hide_extract_recording_button();
    ViewApi.set_menu_image_thumbnail();
  }
}
