import MenuViewMode from './MenuViewMode';
import PhysicalCameraManager from '../../PhysicalCameraManager';
import MenuCameraManager from '../../MenuCameraManager';

/**
 * @class ArchiveMode
 * @description User is able to see his archive recorded files.Get activated when archive tab is clicked.
 * @function on_tab_selected() - Get called when user has clicked the tab. (called by MenuView).
 * @function on_player_hide() -  Get called when we want to hide the player. (called by PlayerContainer)
 * @function is_valid_camera() -  It works as filter for whcich camera to display in the menu view.Called by menuView.js
 * 
 */

// This represents the Archive mode of the MenuView
export default class ArchiveMode extends MenuViewMode
{
  constructor()
  {
    super('archive');
  }

  on_tab_selected(menu_view)
  {
    menu_view.fill_menu(0);
  }

  is_valid_camera(physical_camera)
  {
    //return (physical_camera.cam_type !== 'phone');
    return true;
  }

  on_player_hide(player_container)
  {
    player_container.app.archive_telemetry_controller.stop_streaming(PhysicalCameraManager.selected_camera);
  }

  on_camera_selected(menu_view)
  {
    menu_view.show_calendar_view();

    menu_view.app.calendar_view.get_recordings_from_server(PhysicalCameraManager.selected_camera);
  }

  on_day_selected(app)
  {
    app.player_view.extract_recording_view.hide_open_button();

    app.menu_view.show_recordings_list_view();

    PhysicalCameraManager.selected_camera.fly_on_valid_telemetry = true;
  }

  on_recording_played(player_view, fragment)
  {
    player_view.app.archive_telemetry_controller.stop_streaming(PhysicalCameraManager.selected_camera);

    player_view.app.player_container.show_loading();

    setTimeout(function()
    {
      player_view.start_archive_streaming(fragment);
    }, 200);
  }
}
