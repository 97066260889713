import ApplicationView from './common/ApplicationView';
import { ResourceContainer } from 'ohzi-core';
import PhysicalCameraManager from '../PhysicalCameraManager';
import RoleManager from '/js/components/RoleManager';

// This handles the recording-list view
/**
 * RecordingsListView manages the functionallity of a recording row.
 * You can play,delete or download any recording of your choice.
 * @class
 * @description - Class to manage the functionallity of the recording list view.
 * @func play_recording - Called when we want to play a recording. Called by recording_list_row.pug
 * @func download_recording - Called when press the download button at recording_list_row.pug.
 */
export default class RecordingsListView extends ApplicationView
{
  constructor(app)
  {
    super('recordings-list', $('.recordings-list'));
    this.app = app;

    this.nested_click = false;
    this.files_deleted = 0;
  }

  start()
  {
  }

  play_recording(row)
  {
    if (!this.nested_click)
    {
      let ms = row.dataset.startTimeMs;

      let fragment = this.app.player_view.playback_bar.get_fragment_contained_at_ms(ms);
      this.app.player_view.play_recording(fragment);
      this.app.player_container.show();

      this.app.menu_view.close();
    }
    else
    {
      this.nested_click = false;
    }
  }

  delete_recording(delete_button)
  {
    this.nested_click = true;

    let start_date = delete_button.dataset.startDate;
    let end_date = delete_button.dataset.endDate;
    let file_name = delete_button.dataset.fileName;

    let app_config = ResourceContainer.get_resource('config');

    let camera_id = PhysicalCameraManager.selected_camera._id;

    // let hrds = {
    //  'Content-Type' : 'application/json',
    //  'Authorization': 'Bearer ' + RoleManager.bearer_token
    // };

    $.ajax({
      type: 'GET',
      url: `${app_config.recordings_api_host}/telemetry/internal/delete/${camera_id}/${start_date}/${end_date}`,
      // headers: hrds,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      complete: this.__delete_response.bind(this, delete_button)
    });

    $.ajax({
      type: 'GET',
      url: `${app_config.recordings_api_host}/exportedrecordings/delete/${camera_id}/${file_name}`,
      // headers: hrds,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      complete: this.__delete_response.bind(this, delete_button)
    });
  }

  download_recording(download_button)
  {
    this.nested_click = true;
  }

  show()
  {
    super.show();
  }

  hide()
  {
    super.hide();
    this.t = 0.5;
  }

  __delete_response(delete_button)
  {
    this.files_deleted++;

    if (this.files_deleted >= 2)
    {
      this.files_deleted = 0;

      $(delete_button).parent().parent().remove();
    }
    //When we successfully deleted an archive video, make a request to refresh the camera list.
    //TODO Maybe its better to not do the request but instead find the deleted file and remove it from the array.
    this.app.calendar_view.get_recordings_from_server(PhysicalCameraManager.selected_camera);
  }

  __download_response(download_button, response, status, xhr)
  {
    // console.log(`[RecordingsListView:__download_response]`);

    // console.dir(download_button);
    // console.dir(response);
    // console.dir(status);
    // console.dir(xhr);

    // Get filename
    var filename = 'video.mp4';
    var disposition = xhr.getResponseHeader('Content-Disposition');
    // console.log(`disposition: ${disposition}`);

    if (disposition && disposition.indexOf('attachment') !== -1)
    {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1])
      {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    else if (disposition)
    {
      filename = disposition;
    }

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = filename;
    link.click();
  }
}
