export default class MenuViewMode
{
  constructor(name)
  {
    this.name = name;
  }

  on_tab_selected(menu_view)
  {

  }

  is_valid_camera(physical_camera)
  {

  }

  on_player_hide(player_container)
  {

  }

  on_day_selected(app)
  {

  }

  on_camera_selected(menu_view)
  {

  }

  on_recording_played(player_view)
  {

  }
}
