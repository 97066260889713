import CameraMovementMode from './CameraMovementMode';
import { ResourceContainer } from 'ohzi-core';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import PlayerSettings from '/js/components/PlayerSettings';

import RoleManager from '/js/components/RoleManager';

// Control Axis PTZ camera
export default class CameraPTZMode extends CameraMovementMode
{
  constructor()
  {
    super();
    
    this.last_move_update   = Date.now();
    this.last_debug_update  = Date.now();
    this.last_zoom_update   = Date.now();
    this.last_status_update = Date.now();
    this.last_status_request_update = Date.now();

    this.pan_speed          = 0;
    this.tilt_speed         = 0;
    this.pan_speed_f        = 0;
    this.tilt_speed_f       = 0;

    this.last_zoom_t        = 0;

    this.last_pan           = 0;
    this.last_tilt          = 0;

    this.ptz_pan            = 0;
    this.ptz_tilt           = 0;
    this.ptz_zoom           = 0;
    
    this.init_zoom          = false;
    this.movement_active    = false;

    this.api_host           = undefined;
    
    this.camera_ip          = undefined;
    this.camera_port        = undefined;
    this.camera_username    = undefined;
    this.camera_password    = undefined;

    this.request_ptz_status     = false;
  }

  on_enter(camera_controller)
  {
    let selected_camera = undefined;

    if (camera_controller.tandem_mode)
    {
      selected_camera = PhysicalCameraManager.selected_camera_tandem;
    }
    else
    {
      selected_camera = PhysicalCameraManager.selected_camera;
    }

    let app_config = ResourceContainer.get_resource('config');
    this.api_host = app_config.recordings_api_host;
    //this.api_host = "https://gl-cam.fender360.com";
    this.camera_ip = selected_camera.recordings_ip.split(':')[0];
    this.camera_port = selected_camera.recordings_ip.split(':')[1] || 80;
    this.camera_username = selected_camera.username;
    this.camera_password = selected_camera.password;

    this.camera_id = selected_camera._id;

    // show zoom controls
    if (camera_controller.player_container)
    {
      camera_controller.player_container.show_zoom_controls();
    }
    
    this.get_camera_ptz_limits();
    this.get_camera_ptz_status(camera_controller);
  }

  on_exit(camera_controller)
  {
    // Hide zoom controls
    if (camera_controller.player_container)
    {
      camera_controller.player_container.hide_zoom_controls();
    }
  }

  update(camera_controller)
  {
    let new_pan         = camera_controller.new_pan;
    let new_tilt        = camera_controller.new_tilt;
    
    if (new_pan > 180)
    {
      new_pan -= 360;
    }
    let ptz_pan_target = THREE.Math.clamp(new_pan, this.ptz_min_pan, this.ptz_max_pan).toFixed(1);
    let ptz_tilt_target = THREE.Math.clamp(new_tilt, this.ptz_min_tilt, this.ptz_max_tilt).toFixed(1);

    let pan_speed = 0;
    let tilt_speed = 0;
    let zoom_t = 0;

    if (!camera_controller.tandem_mode)
    {
      pan_speed = camera_controller.current_state.pan_speed;
      tilt_speed = camera_controller.current_state.tilt_speed;
      zoom_t = camera_controller.current_state.zoom_t;
      //zoom_t = camera_controller.normalized_zoom;
    }
    else
    {
      zoom_t = camera_controller.normalized_zoom;
    }

    let debug_millis = Date.now() - this.last_debug_update;
    
    // Debug
    if (debug_millis > 2000) {
      //console.log(`[update] camera_controller.normalized_zoom: ${camera_controller.normalized_zoom}, zoom_t: ${zoom_t}`);
      //console.log(`[${millis}] Set new camera target to ${new_pan}, ${new_tilt}. Delte: ${delta_pan}, ${delta_tilt}`);
      //console.log(`[update] camera_controller.new_pan: ${camera_controller.new_pan}, camera_controller.new_tilt: ${camera_controller.new_tilt}`);
      //console.log(`[update] ptz_pan_target (min/max): ${this.ptz_min_pan}/${this.ptz_max_pan}, ptz_tilt_target (min/max): ${this.ptz_min_tilt}/${this.ptz_max_tilt}`);
      //console.log(`[update] ptz_pan_target: ${ptz_pan_target} (${new_pan}), ptz_tilt_target: ${ptz_tilt_target} (${new_tilt})`);
      //console.dir(camera_controller.camera);
      //console.log(`[CameraPTZMode:update] Changing new_pan from: ${new_pan}, tilt: ${new_tilt}`);

      this.last_debug_update = Date.now();
    }

    let move_millis = Date.now() - this.last_move_update;
    // Send absolute movement to camera
    //if (PlayerSettings.player_tag_lock && move_millis > 500 && 
    if (move_millis > 500 && 
      (((this.last_pan != ptz_pan_target) || (this.last_tilt != ptz_tilt_target)) || //) {
      (PlayerSettings.player_tag_lock && ((this.ptz_pan != ptz_pan_target) || (this.ptz_tilt != ptz_tilt_target))))) {
      //console.log(`[update] camera_controller.new_pan: ${camera_controller.new_pan}, camera_controller.new_tilt: ${camera_controller.new_tilt}`);
      //console.log(`[update] ptz_pan_target: ${ptz_pan_target} (${new_pan}), ptz_tilt_target: ${ptz_tilt_target} (${new_tilt})`);
      //console.log(`Send PTZ absolute command: pan: ${ptz_pan_target}, tilt: ${ptz_tilt_target}`);
      this.send_ptz_absolute(ptz_pan_target, ptz_tilt_target);
      this.last_move_update = Date.now();
      this.movement_active = true;
      this.last_pan = ptz_pan_target;
      this.last_tilt = ptz_tilt_target;
      this.__start_requesting_status_update();
    }
    
    // Send manual movement commands, Limit the number of API calls to PTZ
    //if (move_millis > 500 && ((Math.abs(pan_speed) > 0.3) || (Math.abs(tilt_speed) > 0.3))) {
    if (!PlayerSettings.player_tag_lock && move_millis > 500 && ((pan_speed != this.pan_speed) || (tilt_speed != this.tilt_speed))) {
      //console.log(`[${move_millis}] Continuous Movement: pan_speed: ${pan_speed}, tilt_speed: ${tilt_speed}`);
      //console.log(`Send PTZ command.`);
      this.send_ptz_continuous(pan_speed, tilt_speed);
      this.last_move_update = Date.now();
      this.movement_active = true;
      this.pan_speed = pan_speed;
      this.tilt_speed = tilt_speed;
      this.__start_requesting_status_update();
    }

    let zoom_millis = Date.now() - this.last_zoom_update;
    if (zoom_millis > 500 && (zoom_t != this.last_zoom_t)) {
      //console.log(`[${millis}] Continuous Movement: pan_speed: ${pan_speed}, tilt_speed: ${tilt_speed}`);
      //console.log(`Send Zoom command.`);
      this.send_ptz_zoom(zoom_t);
      this.last_zoom_t = zoom_t;
      this.last_zoom_update = Date.now();
      //this.movement_active = true;
      this.__start_requesting_status_update();
    }

    /*
    Request PTZ status from camera. Every 500ms during movements until 2s after last movement
    */
    let status_millis = Date.now() - this.last_status_update;
    let status_request_millis = Date.now() - this.last_status_request_update;
    if (this.request_ptz_status && (status_millis > 200)) {
      this.get_camera_ptz_status(camera_controller);
      this.last_status_update = Date.now();
      // Update variables back to camera for marker etc.
      this.__update_camera_values(camera_controller);
      if (status_request_millis > 2000) {
        this.request_ptz_status = false;
      }
    }
  }

  /*
  Center view on selected pixel location
  Inputs are normalised between 0.0 and 1.0. Scale to 0 to 100 and send with s image width and height to reference against.
  */
  set_rotation_center(narmalised_x, normalised_y)
  {
    let center_x = narmalised_x*100;
    let center_y = normalised_y*100;

    let parameters = `center=${center_x.toFixed(0)},${center_y.toFixed(0)}&imagewidth=100&imageheight=100`;

    this.send_ptz_generic(parameters);
    this.__start_requesting_status_update();
  }
  
  /*
  Get current PTZ camera status, i.e  pan, tilt zoom values
  */
  get_camera_ptz_status(camera_controller) 
  {
    let generic_params = `query=position`;

    let data = {
      //ip: this.camera_ip,
      //port: this.camera_port,
      //username: this.camera_username,
      //password: this.camera_password,
      camera_id: this.camera_id,
      generic_params: generic_params
    };

    $.ajax({
      type: 'POST',
      url: `${this.api_host}/control/external/ptz`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'text',
      //data: data,
      data: JSON.stringify(data),
      complete: this.__on_ptz_status.bind(this, camera_controller)
    }); 
  }

  /*
  TODO: Change these API calls to use the generic callback __on_response_text() which we can pass a specific callback to
  http://62.74.210.139/axis-cgi/com/ptz.cgi?query=limits
  */
  get_camera_ptz_limits() {
    let generic_params = `query=limits`;

    let data = {
      //ip: this.camera_ip,
      //port: this.camera_port,
      //username: this.camera_username,
      //password: this.camera_password,
      camera_id: this.camera_id,
      generic_params: generic_params
    };

    $.ajax({
      type: 'POST',
      url: `${this.api_host}/control/external/ptz`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'text',
      //data: data,
      data: JSON.stringify(data),
      complete: this.__on_ptz_limits.bind(this)
    }); 
  }

  /*
  Get generic camera parameters
  */
  get_camera_parameter(parameter, variable) {
    console.log(`Get camera parameter: ${parameter} into variable: ${variable}`);

    let generic_params = `action=list&group=${parameter}`;

    let data = {
      //ip: this.camera_ip,
      //port: this.camera_port,
      //username: this.camera_username,
      //password: this.camera_password,
      camera_id: this.camera_id,
      generic_params: generic_params
    };

    $.ajax({
      type: 'POST',
      url: `${this.api_host}/parameter/external/get`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'text',
      //data: data,
      data: JSON.stringify(data),
      complete: this.__on_get_parameter.bind(this, parameter, variable)
    });
  }

  /*
  Send pan, tilt in absolute degrees and zoom
  */
  send_ptz_absolute(pan, tilt, zoom) {
    
    let parameters = `${pan ? `pan=${pan}` : ''}` +
      `${tilt ? `&tilt=${tilt}` : ''}` +
      `${zoom ? `&zoom=${zoom}` : ''}`;

    //console.log(`send_ptz_absolute (degrees) - pan: ${pan}, tilt: ${tilt}, zoom: ${zoom} => ${parameters}`);

    this.send_ptz_generic(parameters);
  }
  
  send_ptz_continuous(pan_speed, tilt_speed) {
    this.send_ptz_generic(`continuouspantiltmove=${pan_speed.toFixed(1)},${tilt_speed.toFixed(1)}`);
  }

  /*
  zoom=<int>  1 ... 9999(4) Zooms the device n steps to the specified absolute position. A high value means zoom in, a low value means zoom out.(3)
  max zoom ~12000
  */
  send_ptz_zoom(zoom) {
    // Convert normalised zoom (0-1) to the range accepted by the camera
    let zoom_scaled = zoom * this.ptz_max_zoom;
    
    this.send_ptz_generic(`zoom=${zoom_scaled}`);
  }

  /*
  focus=<int>(2)  1 ... 9999  Moves focus n steps to the specified absolute position. A high value means focus far, a low value means focus near.
  */
  send_ptz_focus_manual(focus) {
    this.send_ptz_generic(`focus=${focus}`);
  }

  /*
  Use a generic PTZ server backend endpoint which allows us to send any combination of parameters
  */
  send_ptz_generic(generic_params) {
    let data = {
      //ip: this.camera_ip,
      //port: this.camera_port,
      //username: this.camera_username,
      //password: this.camera_password,
      camera_id: this.camera_id,
      generic_params: generic_params
    };

    $.ajax({
      type: 'POST',
      url: `${this.api_host}/control/external/ptz`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      //data: data,
      data: JSON.stringify(data),
      complete: this.__on_ptz_control.bind(this)
    }); 
  }
  
  __on_ptz_control(response) {
    //console.info(`[__on_ptz_control]`);
    //console.dir(response);

    if (response.status != 200) {
      console.warn(`[__on_ptz_control] error: ${response.responseJSON}`);
      console.dir(response);
      return;
    }
  }

  /*
  Generic callback that takes another callback as a function
  */
  __on_response_text(callback, callback_scope, response)
  {
    if (response.status != 200) {
      console.log(`[__on_ptz_status] error: ${response.responseJSON}`);
      console.dir(response);
    }

    callback.bind(callback_scope);
  }

  __on_ptz_status(camera_controller, response) {
    if (response.status != 200) {
      console.log(`[__on_ptz_status] error: ${response.responseJSON}`);
      console.dir(response);
      return;
    }

    this.ptz_pan = parseFloat(this.__extract_response_value(response.responseText, "pan"));
    this.ptz_tilt = parseFloat(this.__extract_response_value(response.responseText, "tilt"));
    this.ptz_zoom = parseFloat(this.__extract_response_value(response.responseText, "zoom"));
    this.ptz_focus = parseFloat(this.__extract_response_value(response.responseText, "focus"));
    
    if (camera_controller) {
      camera_controller.current_orientation = this.ptz_pan;
      camera_controller.current_tilt = this.ptz_tilt;
      //camera_controller.normalized_zoom = this.ptz_zoom / this.ptz_max_zoom;
      if (!this.init_zoom)
      {
        camera_controller.current_state.zoom_t = this.ptz_zoom / this.ptz_max_zoom;
        //console.log(`[__on_ptz_status] Initial zoom set to : ${camera_controller.current_state.zoom_t}, ${this.ptz_zoom}, ${this.ptz_max_zoom}`);
        //console.dir(camera_controller.current_state.zoom_t);
        if (isNaN(camera_controller.current_state.zoom_t))
        {
          camera_controller.current_state.zoom_t = 0.0;
        }
        else
        {
          this.init_zoom = true;  
        }
        
      }
    }

    // For display on video info
    if (PhysicalCameraManager.selected_camera && !camera_controller.tandem_mode)
    {
	    PhysicalCameraManager.selected_camera.ptz_pan = this.ptz_pan;
	    PhysicalCameraManager.selected_camera.ptz_tilt = this.ptz_tilt;
	    PhysicalCameraManager.selected_camera.ptz_zoom = this.ptz_zoom;	
    }
    
  }

  __on_ptz_limits(response) {
    if (response.status != 200) {
      console.log(`[__on_ptz_limits] error: ${response.responseJSON}`);
      console.dir(response);
      return;
    }

    this.ptz_min_pan = parseFloat(this.__extract_response_value(response.responseText, "MinPan"));
    this.ptz_max_pan = this.__extract_response_value(response.responseText, "MaxPan");
    this.ptz_min_tilt = this.__extract_response_value(response.responseText, "MinTilt");
    this.ptz_max_tilt = this.__extract_response_value(response.responseText, "MaxTilt");
    this.ptz_min_zoom = this.__extract_response_value(response.responseText, "MinZoom");
    this.ptz_max_zoom = this.__extract_response_value(response.responseText, "MaxZoom");
    this.ptz_min_focus = this.__extract_response_value(response.responseText, "MinFocus");
    this.ptz_max_focus = this.__extract_response_value(response.responseText, "MaxFocus");

  }

  /*
  Response looks like root.PTZ.Limit.L1.MaxZoom=11111↵
  Need to extract after = only
  */
  __on_get_parameter(parameter, variable, response) {
    // TODO: Check for all errors
    if (response.statusText === "timeout") {
      console.log(`Get parameter response error: ${response.responseJSON}`);
      return;
    }

    this[variable] = this.__extract_response_value(response.responseText, parameter);     
  }

  /*
  Extract a reponse value from plain text response. Paraemeters seperated by new line
  */
  __extract_response_value(responseData, parameter) {
    if (typeof responseData === 'undefined') {
      console.log(`Failed to get camera parameter '${parameter}'`);
      console.dir(responseData);
      return;
    }

    let lines = responseData.split("\n");
    let param = undefined;
    let value = undefined;
    
    for(let i in lines) {
      param = lines[i].split("=");
      if(param[0] === parameter) {
        let start = lines[i].indexOf("=");
        value = lines[i].substring(start + 1);
      }
    }
    return value;
  }

  __start_requesting_status_update() {
    this.last_status_request_update = Date.now();
    this.request_ptz_status = true;
  }

  /*
  Update physical camera values so the rotation etc. will be updated
  */
  __update_camera_values(camera_controller) {
    let camera = undefined;
    if (camera_controller.tandem_mode)
      camera = PhysicalCameraManager.selected_camera_tandem;
    else
      camera = PhysicalCameraManager.selected_camera;

    //camera.image_tilt = this.ptz_tilt;
    //camera.image_pan = this.ptz_pan;
    camera.ptz_tilt = this.ptz_tilt;
    camera.ptz_pan = this.ptz_pan;

    camera.update_params();
  }

}
