import SphereBufferGeometry from './SphereBufferGeometry';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

export default class CustomPlane
{
  constructor(planePosition, textureUrl, initialTransparency, isInverted)
  {
    this.planePrimitive = undefined;
    this.planePosition = planePosition;
    this.textureUrl = textureUrl;
    this.initialTransparency = initialTransparency;
    this.isInverted = isInverted;

    this.rotationXPositive180 = Cesium.Matrix3.fromRotationX(Cesium.Math.PI, new Cesium.Matrix3());
    this.rotationXMatrix4Positive180 = Cesium.Matrix4.fromRotationTranslation(this.rotationXPositive180, undefined, new Cesium.Matrix4());
  }

  create_custom_mapping_plane_primitive(scale, widthSegments, heightSegments)
  {

    let material = this.create_material(this.textureUrl, this.initialTransparency);
  
    //console.log("Creating custom_plane primitive. Postiion:")
    //console.dir(position);

    const geometry = new Cesium.PlaneGeometry({
        vertexFormat : Cesium.MaterialAppearance.VERTEX_FORMAT
    });

    const instance = new Cesium.GeometryInstance({
        geometry : geometry,
        attributes: {
            //position: position,
            color : new Cesium.ColorGeometryInstanceAttribute(1.0, 1.0, 0.0, 1)
        }
    });

    this.planePrimitive = new Cesium.Primitive({
        geometryInstances: instance,
        appearance : new Cesium.MaterialAppearance({
            closed: false,
            material: material,
        })//,
        //asynchronous : false
    });

    this.planePrimitive.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(this.planePosition);

    //if (this.isInverted)
    if (true)
    {
      this.planePrimitive.modelMatrix = Cesium.Matrix4.multiply(this.planePrimitive.modelMatrix, this.rotationXMatrix4Positive180, new Cesium.Matrix4());
    }

    this.planePrimitive.modelMatrix = Cesium.Matrix4.setScale(
      this.planePrimitive.modelMatrix,
      new Cesium.Cartesian3(scale, scale, scale),
      new Cesium.Matrix4()
    );

    return this.planePrimitive;
  }

  create_material(url, alpha)
  {
    let videoElement = PhysicalCameraManager.player_video.container;

    //console.dir(videoElement);
    
    // Original working solution
    let material = Cesium.Material.fromType('Image');

    //let material = new Cesium.ImageMaterialProperty({
    //        image: undefined,
     //       transparent: false
     //   })

    //material.uniforms.image = videoElement;
    material.uniforms.image = undefined;
    
    // If using Cesium.ImageMaterialProperty
    //material.image = undefined;

    // To prevent WebGL rendering errors
    //material.minificationFilter = THREE.NearestFilter;
    //material.magnificationFilter = THREE.NearestFilter;

    return material;
  }


}
