
export default class HTMLVideo
{
  constructor(class_name, muted)
  {
    this.container = document.createElement('video');
    this.container.className = `video ${class_name}`;
    this.container.autoplay = true;
    this.container.muted = !!muted;

    this.container.setAttribute('crossorigin', 'anonymous');

    this.play_promise = undefined;
    this.is_playing = false;
  }

  append_to(parent)
  {
    parent.appendChild(this.container);
  }

  load()
  {
    this.container.load();
  }

  play()
  {
    if (!this.is_playing)
    {
      this.play_promise = this.container.play();
      this.is_playing = true;
    }
  }

  pause()
  {
    this.is_playing = false;

    if (this.play_promise)
    {
      this.play_promise.then(_ =>
      {
        this.container.pause();
      });
    }
  }

  stop()
  {
    this.clear_buffer();
  }

  set_volume(volume)
  {
    this.container.volume = volume;
  }

  synchronize()
  {
    if(this.container.buffered && this.container.buffered.length > 0 && this.container.buffered.end)
    {
      this.container.currentTime = this.container.buffered.end(0);
    }
  }

  is_image_showing()
  {
    return !!(this.container.currentTime > 0 && !this.container.paused && !this.container.ended && this.container.readyState > 2);
  }


  clear_buffer()
  {
    this.container.pause();
    this.container.removeAttribute('src'); // empty source
    this.container.srcObject = null; // GG This prevents the video container from going to a tiny size and then messes with thumbnail sizing (webRTC)
    this.container.load();
  }
}
