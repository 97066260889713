import CesiumHandler from './CesiumHandler';
import MapMarker from './MapMarker';
import CameraTargetUtilities from './CameraTargetUtilities';

/**
 * @class  MapCalibrationMarker
 * @description - Show an arrow in the location of the camera pointing in the pan angle from selecting position in video
 */
// This class is a wrapper for a cesium marker for calibration
export default class MapCalibrationMarker extends MapMarker
{
  constructor(text, lon, lat, alt, map_zoom, tgt_distance)
  {
    super({
      text: text,
      lon: lon,
      lat: lat,
      alt: alt,
      map_zoom: map_zoom
    });

    this.tgt_distance = tgt_distance;
  }

  create_marker(img_url)
  {
    console.log(`[MapCalibrationMarker:create_marker]`);

    this.cesium_camera_ground_arrow = CesiumHandler.add_ground_arrow(
      {
        lon: this.lon,
        lat: this.lat,
        alt: this.alt
      }
    );

    this.cesium_camera_arrow = CesiumHandler.add_camera_arrow(
      {
        lon: this.lon,
        lat: this.lat,
        alt: this.alt
      }
    );

    console.dir(this);
  }

  set_position_to_camera(camera)
  {
    this.set_position(camera.cam_lon, camera.cam_lat, camera.default_map_marker_alt, 0, true);
    //this.set_heading(camera.yaw);
  }

  set_arrow_position(lon, lat)
  {
    this.set_position(lon, lat, this.alt, 0, true);
    this.cesium_camera_ground_arrow.polyline.material.color.setValue(Cesium.Color.BLUE);
  }

  set_position(lon, lat, alt, tgt_distance, force_update)
  {
    let valid_position = CameraTargetUtilities.is_valid_non_zero_position(lon, lat, alt);

    if (valid_position)
    {
      this.lon = parseFloat(lon);
      this.lat = parseFloat(lat);
      this.alt = parseFloat(alt);

      let position = Cesium.Cartesian3.fromDegrees(
        this.lon,
        this.lat,
        this.alt
      );

      this.update_marker_ground_arrow(position);
      this.set_heading(this.yaw);
    }
  }

  set_heading(yaw)
  {
    if (isNaN(yaw))
    {
      return;
    }

    this.yaw    = parseFloat(yaw);
    this.pitch  = 0.0;
    this.roll   = 90.0;

    yaw         = Cesium.Math.toRadians(this.yaw);
    let pitch   = Cesium.Math.toRadians(this.pitch);
    let roll    = Cesium.Math.toRadians(this.roll);

    let position = Cesium.Cartesian3.fromDegrees(
      this.lon,
      this.lat,
      this.alt
    );

    const hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll);

    const rotatedPlaneModelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(position, hpr);

    this.update_plane_normal_arrow(position, rotatedPlaneModelMatrix);
  }

  update_plane_normal_arrow(position, rotatedPlaneModelMatrix)
  {
    if (!this.cesium_camera_arrow)
    {
      return;
    }

    // Arrow pointing
    const planeNormalVectorArrowLength = 10;

    // north direction
    let yAxisNormalOfRotatedPlaneModelMatrix = new Cesium.Cartesian4();

    yAxisNormalOfRotatedPlaneModelMatrix.x = -rotatedPlaneModelMatrix[8];
    yAxisNormalOfRotatedPlaneModelMatrix.y = -rotatedPlaneModelMatrix[9];
    yAxisNormalOfRotatedPlaneModelMatrix.z = -rotatedPlaneModelMatrix[10];

    yAxisNormalOfRotatedPlaneModelMatrix.w = 0;

    let extendedWordNormalVector = Cesium.Cartesian3.multiplyByScalar(yAxisNormalOfRotatedPlaneModelMatrix, planeNormalVectorArrowLength, new Cesium.Cartesian3());
    let normalVectorEndPosition = Cesium.Cartesian3.add(position, extendedWordNormalVector, new Cesium.Cartesian3());

    let thePlaneNormalArrowPositions = [position, normalVectorEndPosition.clone()];
    this.cesium_camera_arrow.polyline.positions = thePlaneNormalArrowPositions;
  }


  update_marker_ground_arrow(position)
  {
    if (!this.cesium_camera_ground_arrow)
    {
      return;
    }

    let pos_cart = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);

    let lng = Cesium.Math.toDegrees(pos_cart.longitude);
    let lat = Cesium.Math.toDegrees(pos_cart.latitude);

    let result = (pos_cart.height - (this.height / 2.0));

    let groundNormalVectorEndPosition = Cesium.Cartesian3.fromDegrees(
      lng,
      lat,
      0.0
    );

    let offset = 0;

    let groundNormalVectorStartPosition = Cesium.Cartesian3.fromDegrees(
      lng,
      lat,
      (pos_cart.height - offset)
    );

    let theGroundArrowPositions = [groundNormalVectorStartPosition, groundNormalVectorEndPosition];

    this.cesium_camera_ground_arrow.polyline.positions = theGroundArrowPositions;
  }

  show()
  {
    if (this.__is_valid_marker_position(this.lon, this.lat, this.alt))
    {
      this.__show(this.cesium_camera_arrow);
      this.__show(this.cesium_camera_ground_arrow);
    }
  }

  hide()
  {
    this.__hide(this.cesium_camera_arrow);
    this.__hide(this.cesium_camera_ground_arrow);
  }
}
