export default class UserRole
{
  constructor(name)
  {
    this.name = name;
  }

  configure_app()
  {

  }
}
