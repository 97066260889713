export default class MenuCameraStatusState
{
  constructor()
  {}

  on_config_received(menu_camera_status)
  {}

  update(menu_camera_status)
  {}

  on_enter(menu_camera_status)
  {}

  on_exit(menu_camera_status)
  {}
}
