import { ObjectUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import FisheyeSphere from '/js/components/FisheyeSphere';
import TexturePlane from '/js/components/TexturePlane';
import MapMarker from '/js/components/MapMarker';
import MapTargetMarker from '/js/components/MapTargetMarker';

import PhysicalCamera from './PhysicalCamera';
import PlayerSettings from './PlayerSettings';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import PlaneMapMarker from './PlaneMapMarker';

// This represents the camera containing the config variables used for the application
export default class PhysicalCameraMobile extends PhysicalCamera
{
  constructor(params, stream, plane_stream, texture)
  {
    super(params, stream, texture);

    this.lens_type          = 'flat';
    this.sphere             = false;
    this.map_zoom           = 10;         // Distance from plane i.e the size in the view
    this.marker_scale       = 20;
    this.marker_opacity     = 1;

    this.plane_stream   = plane_stream;
    // this.plane_html_video 		= plane_html_video; 			// HTMLVideo class

    // this.fisheye_sphere         = new FisheyeSphere();
    this.fisheye_sphere         = new TexturePlane();
    this.map_marker             = new PlaneMapMarker(
      this.name,
      this.cam_lon,
      this.cam_lat,
      this.default_map_marker_alt,
      // this.cam_alt,
      this.map_zoom,
      this.marker_scale,
      this.marker_opacity,
      this.use_upper_hemisphere,
      this.sphere
    );

    // this.map_marker.hide(true);

    this.plane_resolution_set = false;

    console.log('[PhysicalCameraMobile]');
    console.dir(texture);
    this.fisheye_sphere.set_texture(texture);
    this.update_params(params);

    // Set fields in the cesium entity to allow clicking on it
    this.map_marker.cesium_text.object_type = 'camera';
    this.map_marker.cesium_marker_point.object_type = 'camera';
    this.map_marker.cesium_text.object_id = this.name;
    this.map_marker.cesium_marker_point.object_id = this.name;
  }

  start()
  {}

  is_live_stream_available()
  {
    return (typeof this.stream.webrtc_janus !== 'undefined');
    //return (typeof this.stream.webrtc_janus !== 'undefined' || this.bodyworn_available);
  }

  is_bodyworn_remote_available()
  {
    return this.bodyworn_available;
  }

  is_selectable()
  {
    return this.is_live_stream_available() || this.is_bodyworn_remote_available();
  }

  update_params()
  {
    // console.log(`[PhysicalCameraMobile:update_params] roll: ${this.roll}`);
    // console.dir(this);

    let roll = this.roll;
    let pitch = this.pitch;
    let yaw = this.yaw;

    // Use RMCH (heading from GPS) if unit is moving. I.e RMCS speed > 5 m/s
    if (this.RMCS > 5.0)
    {
      yaw = this.RMCH;
    }

    switch (this.orientation)
    {
    case 'portrait_front':
      pitch = pitch * -1;
      roll = roll - 90.0;   // Rotate by 90 degrees
      yaw = yaw + 180;
      break;
    case 'portrait_back':
      roll = roll - 90.0;   // Rotate by 90 degrees
      roll = roll * -1;     // Invert roll when using back camera
      break;
    case 'landscape_front':
      pitch = pitch * -1;
      yaw = yaw + 180;
      break;
    case 'landscape_back':
      roll = roll * -1;     // Invert roll when using back camera
      break;
    }

    // console.log(`[PhysicalCameraMobile:update_params] this.yaw: ${this.yaw}, yaw: ${yaw}`);

    this.map_marker.set_position(this.cam_lon, this.cam_lat, this.default_map_marker_alt);
    // this.map_marker.set_position(this.cam_lon, this.cam_lat, this.cam_alt);
    // this.map_marker.set_rotation(this.yaw, this.roll, this.pitch);
    this.map_marker.set_rotation(yaw, roll, pitch);
    this.map_marker.set_name(this.name);
    this.map_marker.set_scale(this.marker_scale);
    this.map_marker.set_opacity(this.marker_opacity);
    this.map_marker.set_inverted(this.use_upper_hemisphere);
    this.map_marker.update_marker_color();

    // if (!this.plane_resolution_set && this.webrtc_stream && this.webrtc_stream.getVideoTracks()[0])
    if (!this.plane_resolution_set && this.stream.stream && this.stream.stream.getVideoTracks()[0])
    {
      // let height = this.webrtc_stream.getVideoTracks()[0].getSettings().height;
      // let width = this.webrtc_stream.getVideoTracks()[0].getSettings().width;
      let height = this.stream.stream.getVideoTracks()[0].getSettings().height;
      let width = this.stream.stream.getVideoTracks()[0].getSettings().width;
      // console.log(this.webrtc_stream.getVideoTracks()[0]);

      if (height && width)
      {
        this.map_marker.set_video_resolution(height, width);
        this.plane_resolution_set = true;
      }
    }

    if (PlayerSettings.lock_view && this === PhysicalCameraManager.selected_camera)
    {
      this.map_marker.set_view_to_plane(this.map_zoom, true);
    }

    this.map_target_marker.set_position(this.tgt_lon, this.tgt_lat, this.tgt_alt, this.tgt_distance);
    this.map_target_marker.set_name(this.tgt_name);

    this.__update_name_in_menu();

    this.stream.set_endpoint(this);
    this.stream.set_profile(this.live_profile);

    this.plane_stream.set_endpoint(this);
  }

  hide_map_marker(partial)
  {
    // Set map_view button on
    let camera_el = $(`.menu__cameras-camera[data-name='${this.name}']`);
    $(camera_el).find('.menu__cameras-camera-map_view').removeClass('active');

    //partial = partial === undefined ? true : partial;

    // Always hide plane, text and arrow but show green dot
    this.map_marker.hide(partial);
    this.show_map_marker_point();
  }

  play()
  {
    this.stream.play(); // this call webrtcStream class
    this.fisheye_sphere.is_playing = true;
  }

  stop()
  {
    console.log('[PhysicalCameraMobile:stop]');
    this.fisheye_sphere.is_playing = false;
  }

  stop_streaming()
  {
    // Override parent behavior
  }
}
