import { Configuration } from 'ohzi-core';
import { Validation } from 'ohzi-core';

import CameraViewState from './states/CameraViewState';
import CameraDebugState from './states/CameraDebugState';
import RotationOnly from './states/RotationOnly';
import ImmediateMode from './movement_mode/ImmediateMode';
import CameraPTZMode from './movement_mode/CameraPTZMode';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This controls the THREE camera
export default class CameraController
{
  constructor()
  {
    this.camera = undefined;
    this.camera_initial_rot = undefined;
    this.camera_initial_pos = undefined;
    this.current_state = new CameraViewState();

    this.current_mode = new ImmediateMode();
    this.tandem_mode = undefined;

    this.player_container = undefined;

    this.normalized_zoom = 0;

    this.vector_up_axis   = new THREE.Vector3(0, 1, 0);
    this.vector_right_axis = new THREE.Vector3(-1, 0, 0);
    this.vector_forward_axis = new THREE.Vector3(0, 0, 1);
    this.tmp_forward = this.vector_forward_axis.clone();
    this.tmp_right = this.vector_right_axis.clone();

    this.tmp_dir = new THREE.Vector3();

    this.zoom = Configuration.max_zoom_distance / 2;
    this.reference_zoom = Configuration.max_zoom_distance / 2;

    this.reference_rotation = new THREE.Quaternion();
    this.reference_position = new THREE.Vector3();
    this.__last_reference_position = new THREE.Vector3();

    this.tmp_size = new THREE.Vector3();
    this.tmp_quat = new THREE.Quaternion();

    this.min_zoom             = 1;
    this.max_zoom             = 400;

    this.current_tilt         = 0;
    this.current_orientation  = 0;

    // Target pan/tilt for PTZ camera to aim for
    this.new_tilt             = 0;
    this.new_pan              = 0;

    this.input_enabled = true;
  }

  set_camera(camera)
  {
    this.camera = camera;
    this.camera_initial_rot = camera.quaternion.clone();
    this.camera_initial_pos = camera.position.clone();
  }

  set_state(state)
  {
    this.current_state.on_exit(this);
    this.current_state = state;
    this.current_state.on_enter(this);
  }

  set_mode(mode)
  {
    this.current_mode.on_exit(this);
    this.current_mode = mode;
    this.current_mode.on_enter(this);
  }

  set_normalized_zoom(zoom)
  {
    this.normalized_zoom = THREE.Math.clamp(zoom, 0, 1);
  }

  set_player_container(player_container)
  {
    this.player_container = player_container;
  }

  update_camera_distance()
  {
    this.current_state.update_camera_distance(this);
  }
/*
  update_normalized_zoom(min_zoom, max_zoom)
  {
    let zoom = this.camera.position.distanceTo(this.reference_position);
    this.normalized_zoom = this.linear_map(zoom, min_zoom, max_zoom, 1, 0);
    this.normalized_zoom = THREE.Math.clamp(this.normalized_zoom, 0, 1);
  }
*/
  update()
  {
    if (this.debug_box)
    {
      this.debug_box.position.copy(this.reference_position);
    }

    this.current_state.update(this);
    this.current_mode.update(this);
    //this.update_normalized_zoom(this.min_zoom, this.max_zoom);
  }

  // Called from GUI player.pug
  zoom_in_down()
  {
    this.zoom_interval_id = setInterval(this.__zoom.bind(this, 0.02), 200);
    this.current_state.zoom_t += 0.02;
  }

  // Called from GUI player.pug
  zoom_out_down()
  {
    this.zoom_interval_id = setInterval(this.__zoom.bind(this, -0.02), 200);
    this.current_state.zoom_t -= 0.02;
  }

  zoom_up()
  {
    clearInterval(this.zoom_interval_id);
  }

  __zoom(zoom_offset)
  {
    this.current_state.zoom_t += zoom_offset;
  }

  set_idle()
  {
    this.set_state(new CameraViewState());
  }

  set_debug_mode()
  {
    this.set_state(new CameraDebugState());
  }

  set_rotation_only_mode()
  {
    this.set_state(new RotationOnly());
  }

  camera_is_zoomed_out()
  {
    return this.normalized_zoom < 0.2;
  }

  set_rotation(tilt, orientation)
  {
    if (this.__is_valid_rotation(orientation, tilt))
    {
      if (PhysicalCameraManager.selected_camera.lens_type !== 'flat')
      {
        this.current_tilt = tilt;
        this.current_orientation = orientation;

        this.reference_rotation.copy(this.build_rotation(this.current_tilt, this.current_orientation));
      }
      else
      {
        this.new_tilt = tilt;
        this.new_pan = orientation;
      }
    }
  }

  __is_valid_rotation(pan, tilt)
  {
    return !!((Validation.is_int(pan) || Validation.is_float(pan)) &&
              (Validation.is_int(tilt) || Validation.is_float(tilt)));
  }

  // set_tilt(tilt)
  // {
  //   let new_tilt = new THREE.Quaternion().setFromAxisAngle(this.vector_right_axis, (-tilt / 360) * Math.PI * 2);
  //   let old_tilt = new THREE.Quaternion().setFromAxisAngle(this.vector_right_axis, (-this.current_tilt / 360) * Math.PI * 2);
  //   old_tilt.conjugate();

  //   this.reference_rotation.multiply(old_tilt).multiply(new_tilt);
  //   this.current_tilt = tilt;
  // }

  set_rotation_delta(delta_x, delta_y)
  {
    this.current_orientation = (this.current_orientation - delta_x) % 360;
    this.current_tilt += delta_y;
    this.set_rotation(this.current_tilt, this.current_orientation);
  }

  build_rotation(tilt, orientation)
  {
    let new_orientation = new THREE.Quaternion().setFromAxisAngle(this.vector_up_axis,    THREE.Math.degToRad(orientation));
    let new_tilt        = new THREE.Quaternion().setFromAxisAngle(this.vector_right_axis, THREE.Math.degToRad(tilt));

    return new_orientation.multiply(new_tilt);
  }

  linear_map(value,
    from_range_start_value,
    from_range_end_value,
    to_range_start_value,
    to_range_end_value)
  {
    return ((value - from_range_start_value) / (from_range_end_value - from_range_start_value)) * (to_range_end_value - to_range_start_value) + to_range_start_value;
  }

  get_current_tilt()
  {
    return this.current_tilt;
  }

  get_current_orientation()
  {
    return this.current_orientation;
  }

  get_zoom_to_show_rect(width, height, scale = 1)
  {
    let v_fov = THREE.Math.degToRad(this.camera.fov / 2);
    let h_fov = (2 * Math.atan(Math.tan(v_fov) * this.camera.aspect)) / 2;

    let distV = (height / 2) / Math.tan(v_fov * scale);
    let distH = (width / 2) / Math.tan(h_fov * scale);
    return Math.max(Math.abs(distH), Math.abs(distV));
  }
}
