import UserRole from './UserRole';
/*
Role: cust_webrtc_viewer

Live tab: Visible
Archive tab: Visible
Recordings tab: Hidden
Taglock menu on the player: Hidden
Option menu: Hidden
*/
export default class CustWebRTCViewerRole extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    ViewApi.hide_player_target_lock_button();
    ViewApi.hide_player_target_select();
    ViewApi.hide_recordings_tab();
    ViewApi.hide_menu_options();
  }
}
