// import PhysicalCamera from '/js/components/PhysicalCamera';
import PhysicalCameraFisheye from '/js/components/PhysicalCameraFisheye';
import PhysicalCameraFlat from '/js/components/PhysicalCameraFlat';
import PhysicalCameraMobile from '/js/components/PhysicalCameraMobile';

import { SceneManager } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { ArrayUtilities } from 'ohzi-core';
import AxisCameraStream from '/js/components/AxisCameraStream';
import WebRtcStream from '/js/components/WebRtcStream';
import PlayerMarkerManager from './PlayerMarkerManager';
import PlayerSettings from './PlayerSettings';
import CesiumHandler from './CesiumHandler';

import HTMLCanvas from '/js/components/html_components/common/HTMLCanvas';
import HTMLVideo from '/js/components/html_components/common/HTMLVideo';

import CameraMovementMode from '/js/components/CameraController/movement_mode/CameraMovementMode';
import ImmediateMode from '/js/components/CameraController/movement_mode/ImmediateMode';
import CameraPTZMode from '/js/components/CameraController/movement_mode/CameraPTZMode';
import CameraViewState from '/js/components/CameraController/states/CameraViewState';
import CameraFixedState from '/js/components/CameraController/states/CameraFixedState';
import FisheyeControls from '/js/components/CameraController/states/FisheyeControls';
import PTZControls from '/js/components/CameraController/states/PTZControls';

import MenuCameraManager from '/js/components/MenuCameraManager';

// This class handles everything related to the physical cameras:
//
// - Keeps track of the current camera being displayed on the viewer
// - Updates the camera configuration when new data is received from the websocket
// - Updates the player marker when a new camera is displayed
// - Sets up the camera_controller movement to match the fov and hemisphere of the active camera
//

/**
 * @class PhysicalCameraManager
 * @description - Keeps track of all cameras functionallity that we have. (focus on PhysicalCameraFisheye as its the most used camera)
 * @function add_camera() -  Adds Camera to the camera array. (called by MainApplication.js)
 * @function set_player_video() - Set the video player element. (Called by MainApplication.js)
 * @function update_camera() - updates the camera with new inputs. (called by CameraTargetUtilities.js)
 */
class PhysicalCameraManager
{
  constructor()
  {
    this.cameras = [];
    this.selected_camera   = undefined;
    this.selected_camera_tandem   = undefined;
    this.camera_controller = undefined;
    this.camera_controller_tandem = undefined;
    this.fisheye_controler = undefined;

    // This is a reference of PlayerView.html_video
    this.player_video       = undefined;
    this.plane_video        = undefined;
    this.app                = undefined;

    this.tandem_mode        = false;
  }

  add_camera(cam_data, webrtc_stream)
  {
    let camera = this.create_camera(cam_data, webrtc_stream);

    this.cameras.push(camera);

    return this.cameras[this.cameras.length - 1];
  }

  // Replace an existing camera with a newly created camera
  replace_camera(existing_camera, cam_data, webrtc_stream)
  {
    let camera = this.create_camera(cam_data, webrtc_stream);

    for (let i = 0; i < this.cameras.length; i++)
    {
      if (this.cameras[i] === existing_camera)
      {
        console.log(`[replace_camera] Replacing existing camera '${existing_camera.name}' with new camera '${camera.name}'`);
        this.cameras[i] = camera;

        return camera;
      }
    }

    return undefined;
  }

  create_camera(cam_data, webrtc_stream)
  {
    let tex         = undefined;
    let camera      = undefined;

    if (Configuration.is_ios)
    {
      tex = new THREE.CanvasTexture(this.player_video.container);
    }
    else
    {
      tex = new THREE.VideoTexture(this.player_video.container);
    }

    let stream = new AxisCameraStream(this.player_video);

    if (Configuration.is_ios)
    {
      this.plane_video = new HTMLCanvas('plane__video');
    }
    else
    {
      this.plane_video = new HTMLVideo('plane__video');
    }

    let plane_stream = new AxisCameraStream(this.plane_video);

    // TODO_GG: Tidy this up, best way to identify different type of cameras. SHould be using type = "" (this only comes in data stream, not camera settings)
    if (cam_data.cam_type === 'phone' || cam_data.bodyworn === 'yes' || cam_data.orientation === 'mobile' ||
      (cam_data.orientation !== 'flat' && cam_data.orientation !== 'down' && cam_data.orientation !== 'up'))
    {
      let stream = new WebRtcStream(this.player_video, webrtc_stream);
  
      // If phone lens is fisheye then load default values
      if (cam_data.lens_type == 'fisheye') {
        camera = new PhysicalCameraFisheye(cam_data, stream, tex);
      }
      else
      {
        camera = new PhysicalCameraMobile(cam_data, stream, plane_stream, tex);
      }
    }
    else if (cam_data.orientation === 'flat')
    {
      camera = new PhysicalCameraFlat(cam_data, stream, plane_stream, tex);
    }
    else
    {
      //PhysicalCameraFisheye is the camera that is mostly used in the app
      camera = new PhysicalCameraFisheye(cam_data, stream, tex);
    }
    return camera;
  }

  update_camera(name, camera_data)
  {
    for (let i = 0; i < this.cameras.length; i++)
    {
      if (this.cameras[i].name === name)
      {
        // console.log(`[PhysicalCameraManager:update_camera] Setting state from JSON for camera: ${name}`);
        // console.dir(camera_data);

        this.cameras[i].set_state_from_json(camera_data);
        this.cameras[i].update_params();

        if (this.selected_camera && this.selected_camera.name === this.cameras[i].name)
        {
          this.hide_all_map_target_markers();
          this.cameras[i].show_map_target_marker();
          this.__set_rotation_from_camera(this.cameras[i]);

          let flat = (this.cameras[i].lens_type === 'flat');

          this.camera_controller.current_state.set_fisheye_fov_and_hemisphere(this.cameras[i].fov, this.cameras[i].use_upper_hemisphere);

          PlayerMarkerManager.update_marker_position('target', this.cameras[i].icon_pan, this.cameras[i].icon_tilt, flat);
        }
        if (this.selected_camera_tandem && this.selected_camera_tandem.name === this.cameras[i].name)
        {
          this.__set_rotation_from_camera_tandem(this.cameras[i]);
        }
      }
    }
  }

  // TODO: Move this function to a possible map target marker manager
  hide_all_map_target_markers()
  {
    for (let i = 0; i < this.cameras.length; i++)
    {
      this.cameras[i].hide_map_target_marker();
    }
  }

  remove_camera(camera)
  {
    camera.dispose();
    ArrayUtilities.remove_elem(this.cameras, camera);
  }

  get_by_name(name)
  {
    for (let i = 0; i < this.cameras.length; i++)
    {
      if (this.cameras[i].name == name)
      {
        return this.cameras[i];
      }
    }

    return undefined;
  }

  get_by_keycloak_id(keycloak_id)
  {
    for (let i = 0; i < this.cameras.length; i++)
    {
      if (this.cameras[i].keycloak_id == keycloak_id)
      {
        return this.cameras[i];
      }
    }

    return undefined;
  }

  get_editable_cameras()
  {
    let cameras = [];

    for (let i = 0; i < this.cameras.length; i++)
    {
      if (this.cameras[i].is_editable())
      {
        cameras.push(this.cameras[i]);
      }
    }

    return cameras;
  }

  set_camera_controller(camera_controller)
  {
    this.camera_controller = camera_controller;
  }

  set_camera_controller_tandem(camera_controller)
  {
    this.camera_controller_tandem = camera_controller;
  }

  set_fisheye_controls(fisheye_controls)
  {
    this.fisheye_controls = fisheye_controls;
  }

  set_active_camera(camera, no_fly)
  {
    if (this.selected_camera)
    {
      this.selected_camera.fisheye_sphere.visible = false;
      this.selected_camera.hide_map_marker();
      SceneManager.current.remove(this.selected_camera.fisheye_sphere);
      // this.player_video.clear_buffer();
    }
    this.player_video.clear_buffer();

    SceneManager.current.add(camera.fisheye_sphere);
    this.selected_camera = camera;
    this.selected_camera.show_map_marker(false);

    // Change texture from video to screenshot then stop video playing
    if (this.selected_camera_tandem)
    {
      this.unset_active_tandem_camera();
    }

    // PlayerSettings.show_select_dropdown();
    // PlayerSettings.show_button_taglock();
console.log(`[set_active_camera]`)
    if ((this.selected_camera.bodyworn === 'yes' || this.selected_camera.cam_type === 'phone') && this.selected_camera.lens_type !== 'fisheye')
    {
      // Mobile (webRTC) camera
      this.camera_controller.set_mode(new CameraMovementMode());
      this.camera_controller.set_state(new CameraFixedState());

      // TODO: DOn't link the plane video to the menu video source (link back to the WebRTC stream)
      // let videoElement = this.selected_camera.stream.html_video.container;
      let menu_cam = MenuCameraManager.get_by_name(camera.name);
      // let videoElement = menu_cam.stream.html_video.container;
      let videoElement = this.selected_camera.stream.html_video.container;
      // console.log(`[PhysicalCameraManager:set_active_camera] 2`);
      // console.dir(videoElement);
      this.selected_camera.map_marker.set_image_url(videoElement);

      PlayerSettings.hide_select_dropdown();
      PlayerSettings.hide_button_taglock();
    }
    else if (this.selected_camera.orientation === 'flat')
    {
      // Axis flat camera - Disable PTZ controls if not live
      if (this.app.menu_view.current_tab.name === 'live')
      {
        this.camera_controller.set_mode(new CameraPTZMode());
      }
      else
      {
        this.camera_controller.set_mode(new CameraMovementMode());
      }
      this.camera_controller.set_state(new PTZControls());

      let videoElement = this.selected_camera.stream.html_video.container;
      this.selected_camera.map_marker.set_image_url(videoElement);
    }
    else
    {
      // Axis Fisheye Camera
      this.camera_controller.set_mode(new ImmediateMode());
      this.camera_controller.set_state(new FisheyeControls());
    }
    this.camera_controller.current_state.start();

    this.update_camera(camera.name, camera);
    if (!no_fly)
    {
      this.selected_camera.fly_to_camera_marker();
    }
  }

  unset_active_camera()
  {
    if (this.selected_camera)
    {
      this.selected_camera.fisheye_sphere.visible = false;
      this.selected_camera.reset_texture();

      if (this.selected_camera.is_bodyworn_live())
      {
        this.selected_camera.hide_map_marker(false);
        this.selected_camera.show_map_marker_point();
      }
      else
      {
        this.selected_camera.hide_map_marker();
      }
      CesiumHandler.unlock();

      SceneManager.current.remove(this.selected_camera.fisheye_sphere);
      // this.player_video.clear_buffer();
      this.camera_controller.set_mode(new ImmediateMode());
      this.camera_controller.set_state(new CameraViewState());
      this.selected_camera = undefined;
      /**
       * We hide the calendarview because when we are at recordings or archive tabs
       * and we are playing a video if we close that video the selected_camera becomes undefind
       * and we dont have any selected cameras. If we dont hide the calendar, then we will able to
       * see the days of which we have avaliable recording but we will be not able to play them.
       * In order to play a video we are required to have a selected_camera.
       * 
       * FIXME This need more debugging
       */
      this.app.menu_view.hide_calendar_view(); 
    }
  }

  set_active_tandem_camera(camera)
  {
    // This appears to freeze the cesium map
    if (camera.lens_type !== 'flat')
    {
      return;
    }

    this.selected_camera_tandem = camera;
    this.selected_camera_tandem.show_map_marker(false);
    this.selected_camera_tandem.tandem_enabled = true;
    this.tandem_mode = true;
    this.camera_controller_tandem.set_mode(new CameraPTZMode());

    let videoElement = this.selected_camera_tandem.plane_stream.html_video.container;
    this.selected_camera_tandem.map_marker.set_image_url(videoElement);
    this.selected_camera_tandem.fly_to_camera_marker();
  }

  unset_active_tandem_camera(camera)
  {
    // Change texture from video to screenshot then stop video playing
    if (this.selected_camera_tandem)
    {
      this.selected_camera_tandem.map_marker.set_image_url(this.selected_camera_tandem.plane_stream.get_screenshot_url());
      this.selected_camera_tandem.plane_stream.html_video.stop();
      this.selected_camera_tandem.hide_map_marker();
      this.selected_camera_tandem.tandem_enabled = false;
      this.selected_camera_tandem = undefined;
      this.tandem_mode = false;

      // Turn off all tandem switches
      var tandem_switches = document.querySelectorAll('.menu__cameras-camera-tandem');
      [].forEach.call(tandem_switches, function(item)
      {
        item.classList.remove('active');
      });
    }
    if (this.selected_camera)
    {
      this.selected_camera.show_map_marker(false);
    }
  }

  set_player_video(html_video)
  {
    this.player_video = html_video;
  }

  set_app(app)
  {
    this.app = app;
  }

  update()
  {
    for (let i = 0; i < this.cameras.length; i++)
    {
      this.cameras[i].update();
    }
  }

  __set_rotation_from_camera(camera)
  {
    // Rotate camera using camera config only if it is enabled on the player UI
    if (PlayerSettings.player_tag_lock)
    {
    // console.log(`[PhysicalCameraManager:__set_rotation_from_camera] pan: ${camera.image_pan}, tilt: ${camera.image_tilt}`);
      this.camera_controller.set_rotation(camera.image_tilt, camera.image_pan);
    }
    // console.log(`[PhysicalCameraManager:__set_rotation_from_camera] PlayerSettings.lock_view: ${PlayerSettings.lock_view}`);
    if (!this.selected_camera_tandem && PlayerSettings.lock_view)
    {
      // console.log(`[PhysicalCameraManager:__set_rotation_from_camera] setting view to plane`);
      // this.selected_camera.set_view_to_plane();
    }
  }

  __set_rotation_from_camera_tandem(camera)
  {
    // Rotate camera using camera config only if it is enabled on the player UI
    // if (PlayerSettings.player_tag_lock)
    // {
    // console.log(`[PhysicalCameraManager:__set_rotation_from_camera_tandem] pan: ${camera.image_pan}, tilt: ${camera.image_tilt}`);
    this.camera_controller_tandem.set_rotation(camera.image_tilt, camera.image_pan);

    // console.log(`[PhysicalCameraManager:__set_rotation_from_camera_tandem] PlayerSettings.lock_view: ${PlayerSettings.lock_view}`);
    if (PlayerSettings.lock_view)
    {
      // console.log(`[PhysicalCameraManager:__set_rotation_from_camera_tandem] setting view to plane`);
      // this.selected_camera_tandem.set_view_to_plane();
    }
    // }
  }
}

export default new PhysicalCameraManager();
