import { Socket } from '/js/components/webrtc/sockets.js';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';
import PlayerSettings from '../PlayerSettings';

export default class BodywornClient {
  _socket;

  constructor(token, parsedToken) {
    console.log(`[BodywornClient]`);
    console.dir(parsedToken);

    this.bodyworn_url = `https://${parsedToken.bodywornSocket}`;     // bodywornSocket: "https://tsock.defender-ai.org"
    //this.bodyworn_url = `https://glsock.defender-ai.org`;
    console.log(`[BodywornClient] this.bodyworn_url: ${this.bodyworn_url}`);
    
    this._socket = undefined;
    this.initiate_connect(token, parsedToken);
  }

  initiate_connect(token, parsedToken)
  {
    console.log(`Initiate 'Bodyworn Command' socketIO connection`);
    this._socket = new Socket(this.bodyworn_url, token, parsedToken, 'bodyworn_command');

    let _this = this;

    this._socket.onConnectMessage(() => {
      //console.log(`[BodywornClient._socket.onConnectMessage]`);
    });

    this._socket.onSystemMessage(({ type, data }) => {
      //console.log(`[BodywornClient._socket.onSystemMessage]`);
    });

    this._socket.onMessage('user_connected', function (data) {
      console.log(`[BodywornClient] socket.on("user_connected")`);
      console.dir(data);

      if (_this.callback_user_connected)
      {
        _this.callback_user_connected(_this.callback_user_connected_scope, data);
      }
    });

    this._socket.onMessage('user_disconnected', function (data) {
      console.log(`[BodywornClient] socket.on("user_disconnected")`);
      console.dir(data);

      if (_this.callback_user_disconnected)
      {
        _this.callback_user_disconnected(_this.callback_user_disconnected_scope, data);
      }
    });

    this._socket.onMessage('user_list', function (data) {
      console.log(`[BodywornClient] socket.on("user_list")`);
      console.dir(data);

      if (_this.callback_user_list)
      {
        _this.callback_user_list(_this.callback_user_list_scope, data);
      }
    });

    this._socket.onMessage('mobile_alerts', function (data) {
      let camera = PhysicalCameraManager.get_by_keycloak_id(data.sub);

      if (camera && data.alert == 1)
      {
        data.name = `${camera.name} [SOS]`;

        data.tgt_lat = data.cam_lat;
        data.tgt_lon = data.cam_lon;
        data.tgt_alt = data.cam_alt;

        PlayerSettings.add_option_to_target_select(data.name);
        TaglockMarkerManager.add_option_to_taglock_select(data.name, data);
        TaglockMarkerManager.update_marker(data.name, data);
      }
    });

    this._socket.onMessage('bodyworn_location', function (data) {
      if (_this.callback_bodyworn_location)
      {
        _this.callback_bodyworn_location(_this.callback_bodyworn_location_scope, data);
      }
    });
  }

  update_auth_token(token)
  {
    this._socket.update_auth_token(token);
  }
  set_user_callback(name, callback, callback_scope) {
    let fn = window[name];
    if(typeof fn === 'function') {
        fn(t.parentNode.id, callback_scope);
    }
  }

  // Set generic event callback
  set_generic_event_callback(event, callback, callback_scope) {
    console.log(`[set_generic_callback] event: ${event}`);

    this._socket.onMessage(event, function (data, data2) {
      if (callback)
      {
        callback(callback_scope, data, data2);
      }
    });
  }

  set_user_connected_callback(callback, callback_scope) {
    this.callback_user_connected = callback;
    this.callback_user_connected_scope = callback_scope;
  }

  set_user_disconnected_callback(callback, callback_scope) {
    this.callback_user_disconnected = callback;
    this.callback_user_disconnected_scope = callback_scope;
  }

  set_user_list_callback(callback, callback_scope) {
    this.callback_user_list = callback;
    this.callback_user_list_scope = callback_scope;
  }

  set_bodyworn_location_callback(callback, callback_scope) {
    this.callback_bodyworn_location = callback;
    this.callback_bodyworn_location_scope = callback_scope;
  }

  stop_streaming(user_name, user_id)
  {
    console.log(`[stop_streaming] user_name: ${user_name}, user_id: ${user_id}`)
    let to = `${user_id}`
    let content = `{"username": "${user_name}", "command" : "Stop_streaming"}`;
    this.send_command(content, to);
  }

  start_streaming(user_name, user_id)
  {
    console.log(`[start_streaming] user_name: ${user_name}, user_id: ${user_id}`)
    let to = `${user_id}`
    let content = `{"username": "${user_name}", "command" : "Start_streaming"}`;
    this.send_command(content, to);
  }

  camera_switch(user_name, user_id)
  {
    console.log(`[start_streaming] user_name: ${user_name}, user_id: ${user_id}`)
    let to = `${user_id}`
    let content = `{"username": "${user_name}", "command" : "switch_camera"}`;
    this.send_command(content, to);
  }

  send_command(content, to)
  {
    this._socket.emitCommandMessage("commands", content, to);
  }
}
