import { ArrayUtilities } from 'ohzi-core';

/**
 * @class NotificationManager
 * @description Handles the sound and visual notifcations that appear on the browser
 * @function add_notification() - Adds a notification to the notifications array
 */

// This class manages the modal notifications
class NotificationManager
{
  constructor()
  {
    this.sound_notify                   = undefined;
    this.sound_alert                    = undefined;

    this.enable_notification_sound      = undefined;
  }

  start()
  {
    this.sound_notify = new Audio('audio/notify.wav');
    this.sound_alert = new Audio('audio/alert.m4a');
    this.sound_connecting = new Audio('audio/calling.m4a');
    this.sound_connecting.loop = true;
  }

  update()
  {

  }

  play_sound_notify()
  {
    if (this.enable_notification_sound && this.sound_notify)
    {
      this.sound_notify.play();
    }
  }

  play_sound_alert()
  {
    if (this.sound_alert)
    {
      this.sound_alert.play();
    }
  }

  play_sound_connecting()
  {
    if (this.sound_connecting)
    {
      this.sound_connecting.play();
    }
  }

  stop_sound_alert()
  {
    if (this.sound_alert)
    {
      this.sound_alert.pause();
      this.sound_alert.currentTime = 0;
    }
  }

  stop_sound_connecting()
  {
    if (this.sound_connecting)
    {
      this.sound_connecting.pause();
      this.sound_connecting.currentTime = 0;
    }
  }
}

export default new NotificationManager();
