export default class CameraViewState
{
  constructor()
  {}

  on_enter(camera_controller)
  {}

  on_exit(camera_controller)
  {}

  start()
  {}

  update(TIME, camera_controller)
  {}

  set_fisheye_fov_and_hemisphere(fisheye_fov, use_upper_hemisphere)
  {}

  update_camera_distance()
  {}
}
