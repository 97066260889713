export class Socket {
  static TYPES = Object.freeze({
    INITIALIZE: 'initialize',
    CONNECT: 'connect',
    SYSTEM: 'system',
    STREAM: 'stream'
  });
  socket;

  constructor(url, authToken, {preferred_username: name}, socket_name) {
    console.log(`[sockets] url: ${url}`);

    this.auth_token = authToken;
    this.socket_name = socket_name;

    this.socket = io(url, {
      query: {
        data: JSON.stringify({
          type: 'watcher',
          name
        }),
      },
      extraHeaders: {
        Authorization: `Bearer ${this.auth_token}`
      }
    });

    let _this = this;

    this.socket.on("connect", () => {
      console.log(`[sockets] connect '${socket_name}' - Socket is successfully connected `);
    });

    this.socket.on('error', function (err) {
      console.log(`[sockets] error: '${socket_name}'`);
      console.log(err);
    });
    
    // either by directly modifying the `auth` attribute
    this.socket.on("connect_error", (err) => {
      console.log(`[sockets] connect_error: '${socket_name}'`);
      console.dir(err);
      
      //_this.socket.connect();

      // Or 
      setTimeout(() => {
        _this.socket.connect();
      }, 5000)
    });

    // reconnecting event will fire when socket.io tries to connect but fails.
    this.socket.on("reconnecting", (tries) => {
      console.log(`[sockets] reconnecting '${socket_name}' tries: ${tries}`);
      if (tries === 3) {
        //handle your offline mode here
      }
    });

    this.socket.on("disconnect", (reason) => {
      console.log(`[sockets] disconnect: '${socket_name}'`);
      console.dir(reason);

      // Show message when disconnected
      let message = `'${socket_name}' socketIO server<br>disconnected.<br><br>Reason: <br>${reason}<br><br>${new Date().toUTCString()}`;
      app.modal_window_view.show(message);

      // TODO: This is a hack to get video to work after a network connection issue. Should be able to use the reclaim feature      
      app.webrtc_view.reinitialise_janus_client();

      // Try to reconnect
      setTimeout(() => {
        _this.socket.connect();
      }, 5000);
    });

    // TEMP for debugging
    this.socket.onAny((eventName, ...args) => {
      // Listen to all messages
      //console.log(`[socket.onAny] eventName: ${eventName}, args:`);
      //console.dir(args);
    });
  }

  // Testing only
  update_auth_token(token)
  {
    console.log(`[update_auth_token] Token: ${token}`);
    this.auth_token = token;
    this.socket.io.opts.extraHeaders.Authorization = `Bearer ${this.auth_token}`;
  }

  disconnect()
  {
    console.log(`Disconnecting socket '${this.socket_name}'`);
    this.socket.disconnect();
  }

  onMessage(type, callback) {
    console.log(`[onMessage] setup callback for type '${type}' message`);
    this.socket.on(type, res => callback(this._parseJSON(res)));
  }

  onConnectMessage(callback) {
    this.socket.on(Socket.TYPES.CONNECT, callback);
  }

  onSystemMessage(callback) {
    this.socket.on(Socket.TYPES.SYSTEM, res => callback(this._parseJSON(res)));
  }

  emitSystemMessage(data) {
    return this.emitMessage(Socket.TYPES.SYSTEM, data)
  }

  emitStreamMessage(data) {
    return this.emitMessage(Socket.TYPES.STREAM, data)
  }

  emitMessage(type, data) {
    return new Promise((resolve, reject) => {
      this.socket.emit(type, JSON.stringify(data), (error, data) => {
        error ? reject(this._parseJSON(error)) : resolve(this._parseJSON(data));
      });
    });
  }

  emitCommandMessage(type, content, to) {
    return new Promise((resolve, reject) => {
      console.log(`[emitCommandMessage] Sending message`);
      this.socket.emit(type, content, to, (error, data) => {
        error ? reject(this._parseJSON(error)) : resolve(this._parseJSON(data));
      });
    });
  }

  _parseJSON(json) {
    try {
      return JSON.parse(json);
    } catch (err) {
      return json;
    }
  }
}
