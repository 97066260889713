import { Configuration } from 'ohzi-core';

import ApplicationView from './common/ApplicationView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import MenuCameraManager from '../MenuCameraManager';
import LiveMode from './menu_view_mode/LiveMode';
import RecordingsMode from './menu_view_mode/RecordingsMode';
import ArchiveMode from './menu_view_mode/ArchiveMode';
import RoleManager from '../RoleManager';
import MenuSearch from './MenuSearch';
import TaglockMarkerManager from '/js/components/TaglockMarkerManager';
import PlayerMarkerManager from '../PlayerMarkerManager';
import menu_camera_pagination from '../../helpers/menu_camera_pagination';

/**
 *  @class MenuView
 *  @description This class is responsible for the display of the main menu of the application
 *  @function fill_menu() - @description populating the menu view with camera objects
 *  @function add_camera() - @description add camera to the menu view
 *  @function select_tab() -  @description Change active tab on click.Called from api.js
 *  @function select_camera() - @description Sets selected camera on click.Called by MenuCamera
 *  @function select_camera_in_menu() - @description When a user selects a camera,removes or add several html elemnet from the ui
 *  @function close() - @description Closes the side menu,when the menu is closed all menu cameras are paused.
 *  @function open() - @description Open the side menu, when the menu is opened all menu cameras are playing. The selected camera is paused in the menu.
 */

// This represents the menu panel that contains the live and recordings section.
export default class MenuView extends ApplicationView
{
  constructor(app)
  {
    super('menu', $('.menu'));

    this.app = app;
    this.cameras_index = 0;
    this.is_opened = true;
    this.nested_click = false;

    this.cameras_per_page = 6;
    this.last_camera_index = 0;
    this.menu_index = 0;

    this.search = new MenuSearch(app, this);

    this.tabs = {
      LIVE: new LiveMode(),
      RECORDINGS: new RecordingsMode(),
      ARCHIVE: new ArchiveMode()
    };
    this.menu_cameras_with_pagination = menu_camera_pagination(this.cameras_per_page);
    
  }

  test(value)
  {
    // To run in chrome devtools
    // app.menu_view.test()
    console.log('[MenuView:test]');

    console.log('[MenuView:test] set test phone camera to test_cam');

    var test_cam = PhysicalCameraManager.get_by_name('Test Phone');
    console.dir(test_cam);

    let cameras_index = PhysicalCameraManager.cameras.length;

    console.log(`[MenuView:test] Physical Cameras: ${cameras_index}`);
    console.dir(PhysicalCameraManager.cameras);
    for (let i = 0; i < cameras_index; i++)
    {
      let physical_camera = PhysicalCameraManager.cameras[i + this.cameras_index];
      console.log(physical_camera.name);
      console.dir(physical_camera);
    }

    if (value == 1)
    {
      console.log('[MenuView:test] Show cesium_marker_plane');
      // PhysicalCameraManager.selected_camera_tandem.map_marker.cesium_marker_plane.show = true;

      let videoElement = PhysicalCameraManager.selected_camera_tandem.plane_stream.html_video.container;
      console.dir(videoElement);
      let video_width = videoElement.videoWidth;
      let video_height = videoElement.videoHeight;
      // this.selected_camera_tandem.map_marker.set_image_url(videoElement);
      PhysicalCameraManager.selected_camera_tandem.map_marker.set_video_resolution(video_height, video_width);
    }

    return;

  }

  start()
  {
    MenuCameraManager.start();

    this.close_button = $('.menu__buttons-close');
    this.open_button = $('.menu__buttons-open');
    this.options_button = $('.menu__body-options');
    this.menu_cameras_container = $('.menu__cameras');
    this.tabs_tab = $('.menu__tabs-tab');
    this.tabs_tab_live = $('.menu__tabs-tab--live');
    this.tabs_tab_recordings = $('.menu__tabs-tab--recordings');
    this.tabs_tab_archive = $('.menu__tabs-tab--archive');
    this.menu_handle = $('.menu__handle');
    this.pagination_dots = $('.menu__pagination-dots');
    this.pagination_dot_template = $('.menu__pagination-dot').clone();

    if (!Configuration.is_mobile)
    {
      this.options_button.removeClass('hidden');
      this.container.css('width', localStorage.getItem('menu_width'));
    }

    this.search.start();

    this.current_tab = this.tabs.LIVE;

    this.menu_camera = $('.menu__cameras-camera');

    this.fill_menu(0);

    this.menu_handle.on('mousedown touchstart', this.__on_mouse_down.bind(this));
  }

  // Closes the menu panel
  close()
  {
    if (!this.app.menu_options_view.keep_menu_visible)
    {
      this.is_opened = false;
      this.container.addClass('closed');
      MenuCameraManager.pause_all_cameras();
    }
  }

  // Opens the menu panel
  open()
  {
    this.is_opened = true;
    this.container.removeClass('closed');

    MenuCameraManager.play_all_cameras();
    let selected_camera = PhysicalCameraManager.selected_camera;

    if(selected_camera)
    {
      let menu_camera = MenuCameraManager.get_by_name(selected_camera.name);
      menu_camera.pause();
    }

    if (Configuration.is_mobile)
    {
      this.app.player_container.hide();
    }
  }

  // The tab refers to live, reordings or archive
  select_tab(tab, tab_el)
  {
    this.menu_cameras_container.removeClass('hidden');
    this.tabs_tab.removeClass('selected');

    this.app.calendar_view.hide();
    this.app.recordings_list_view.hide();

    $('.menu__selected-camera').addClass('hidden');
    $('.menu__pagination').removeClass('hidden');
    $(tab_el).addClass('selected');

    this.current_tab = tab;

    this.app.player_container.hide();

    tab.on_tab_selected(this.app.menu_view);
  }

  open_settings()
  {
    this.app.camera_settings_view.show();
    this.hide();
  }

  open_camera_calibration()
  {
    this.app.camera_calibration_view.show();
    this.hide();
  }

  logout()
  {
    RoleManager.logout();
  }

  // This is called when a camera is selected in one of the tabs.
  // It will set up the cesium location and player video.
  select_camera(camera_el, e)
  {
    if (!this.nested_click)
    {
      let camera_name = $(camera_el).data('name');
      let camera = PhysicalCameraManager.get_by_name(camera_name);

      if (camera && camera !== PhysicalCameraManager.selected_camera) // && camera.is_online
      {
        // Make sure camera is live streamable before selecting
        if (this.current_tab == this.tabs.LIVE && !camera.is_live_stream_available())
        {
          return;
        }

        this.select_physical_camera(camera);
      }
    }
    else
    {
      this.nested_click = false;
    }
  }

  // This is called when a camera tandem switch is clicked
  // This will setup the camera for display on the cesium map only
  toggle_tandem_mode(camera_el, e)
  {
    this.nested_click = true;

    // console.log(`[MenuView:toggle_tandem_mode] Left clicked`);
    // console.dir(e);
    if (Configuration.is_ios || Configuration.is_mobile)
    {
      return;
    }
    let camera_name = $(camera_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    // console.dir(camera_el);
    // console.dir(camera);
    if (camera) // && camera.is_online
    {
      let last_state = camera_el.classList.contains('active');

      // Turn off all tandem switches
      var tandem_switches = document.querySelectorAll('.menu__cameras-camera-tandem');
      [].forEach.call(tandem_switches, function(item)
      {
        item.classList.remove('active');
      });

      this.__toggle_switch_elem(camera_el, !last_state);

      if (last_state)
      {
        PhysicalCameraManager.unset_active_tandem_camera(camera);
      }
      else
      {
        PhysicalCameraManager.set_active_tandem_camera(camera);
      }

      this.current_tab.on_tandem_selected(this);
    }
  }

  // This is called when a camera map view switch is clicked
  // This will toggle the video plane on the map for mobile (webRTC) devices
  toggle_map_view_mode(camera_el, e)
  {
    this.nested_click = true;

    console.log('[MenuView:toggle_map_view_mode]');

    let camera_name = $(camera_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    if (camera)
    {
      let last_state = camera_el.classList.contains('active');

      this.__toggle_switch_elem(camera_el, !last_state);

      if (last_state)
      {
        console.log('[MenuView:toggle_map_view_mode] hide marker');
        // Hide the map marker plane only
        camera.map_marker.hide(true);
      }
      else
      {
        console.log('[MenuView:toggle_map_view_mode] show marker');
        // Hide the map marker plane only
        camera.show_map_marker(false);
      }
      camera.map_marker.update_arrow_text_position();
    }
  }

  // Called from the GUI menu_webrtc_cameras.pug. This will issue a remote start streaming command to bodyworn device
  toggle_remote_stream(button_el, e, new_state)
  {
    this.nested_click = true;

    let camera_name = $(button_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    if (camera)
    {
      let last_state = button_el.classList.contains('active');

      //let menu_camera = MenuCameraManager.get_by_name(camera.name);
      //menu_camera.set_streaming_state(new_state);

      if (new_state)
      {
        this.app.webrtc_view.remote_stream_start(camera);
      }
      else
      {
        this.app.webrtc_view.remote_stream_stop(camera);
      }
    }
  }

  // Called from the GUI menu_webrtc_cameras.pug. This will issue a remote camera switch command to bodyworn device
  toggle_remote_switch(button_el, e)
  {
    this.nested_click = true;

    let camera_name = $(button_el).data('name');
    let camera = PhysicalCameraManager.get_by_name(camera_name);

    if (camera)
    {
      this.app.webrtc_view.remote_stream_camera_switch(camera);

      let menu_camera = MenuCameraManager.get_by_name(camera.name);
      if (menu_camera)
      {
        menu_camera.toggle_camera_switch();
        this.app.player_view.toggle_remote_switch_state(menu_camera.get_remote_switch_state());
      }
    }
  }

  show_calendar_view()
  {
    this.menu_cameras_container.addClass('hidden');

    this.app.calendar_view.show();
    $('.menu__selected-camera').removeClass('hidden');

    $('.menu__selected-camera').text(`Selected camera: ${PhysicalCameraManager.selected_camera.name}`);
    $('.menu__pagination').addClass('hidden');
  }

  hide_calendar_view()
  {
    this.menu_cameras_container.removeClass('hidden');

    this.app.calendar_view.hide();
    this.app.recordings_list_view.hide();

    $('.menu__selected-camera').addClass('hidden');
    $('.menu__pagination').removeClass('hidden');
  }

  show_recordings_list_view()
  {
    this.app.recordings_list_view.show();
  }

  // This will set up the camera video elements being displayed on the menu
  fill_menu(offset, filter = '')
  {
    const cameras = this.get_valid_cameras(filter);
    this.handle_cameras_before_pagination({cameras,offset,filter});
  }



  /**
   * 
   * This function is handling the before pagination process
   * It checks if we can add cameras to the current page and if not
   * it stops the adding of cameras. 
   * 
   */

  calculate_menu_index({cameras,offset})
  {
    const maxPages = Math.ceil(cameras.length/this.cameras_per_page);
    this.menu_index += offset;
    const is_menu_index_valid = this.menu_index <= maxPages -1 ;
    this.menu_index = is_menu_index_valid ? this.menu_index : maxPages -1;
  }

  handle_cameras_before_pagination({offset,cameras = [],filter=''})
  {
    const camerasLength = cameras.length;
    this.calculate_menu_index({offset,cameras});
    this.cameras_index = this.menu_index > 0 ? this.last_camera_index : 0;

    if(this.cameras_index <= camerasLength )
    {
      let number_of_cameras = 0;
      this.pagination_dots.empty();
      const last_pagination_camera = this.cameras_index + this.cameras_per_page;

      for(let i=this.cameras_index; i<last_pagination_camera; i++)
      {
        let physical_camera = cameras[i];

        if(physical_camera)
        {
          // add camera to pagination tuple
          number_of_cameras++;
          this.add_camera(physical_camera);
          this.last_camera_index = i + 1;
        }

        //if cameras number is equal the cameras per page break the function and start adding cameras to a new page
        if(number_of_cameras === this.cameras_per_page)
        {
          break;
        }

      }
    }
    //handle the dipslay for the cameras pagination
    this.display_menu_camera_manager_pagination({filter});
  }

  // TODO: Move to PhysicalCameraManager
  //TODO: I HAVE COMMENTED OUT THE SORT FUNCTIONALLITY MAKE TEST TO SEE IF THIS NOT MAKE ANY ERRORS
  get_sorted_cameras()
  {
    return PhysicalCameraManager.cameras.sort(function(a, b)
    {
      return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
    });
  }

  get_valid_cameras(filter = '')
  {
    const  cameras = this.get_sorted_cameras();
    const valid_cameras = cameras.filter(camera=>this.current_tab.is_valid_camera(camera) && camera.name.toLowerCase().includes(filter.toLowerCase()));
    return valid_cameras;
  }

  add_pagination_dots({cameras})
  {
    this.pagination_dots.empty();
    let dots_amount = Math.ceil(cameras.length/this.cameras_per_page);

    for (let i = 0; i < dots_amount; i++)
    {
      let active_dot_index = Math.floor(this.cameras_index / this.cameras_per_page);
      let new_dot = this.pagination_dot_template.clone();

      if (active_dot_index === i)
      {
        new_dot.addClass('active');
      }

      this.pagination_dots.append(new_dot);
    }
  }

  
  /**
 * This function handles the pagination in the menu camera manager
 * it removes any not active camera and stops the stream from cameras that 
 * they are not currently displayed on the screen.
 * 
 * TODO ADD HERE ON PAGINATION A SHORTING FUNCTIONALLITY SO IT WILL NEVER HAVE AN EMPTY SLOTS OF CAMERAS
 */
  display_menu_camera_manager_pagination({filter=''})
  {
    const cameras = this.get_active_cameras(filter);
    const cameraIndex = cameras.length <= 1 ? 0 : this.menu_index;
    MenuCameraManager.pagination_cameras(cameras[cameraIndex]);
    // Save latest list of cameras from search results
    this.latest_search_cameras = cameras;

    const all_valid_cameras = this.get_valid_cameras(filter);
    this.add_pagination_dots({cameras:all_valid_cameras});
  }

  /**
   * 
   * Not active cameras might be a camera that doesnt fit the the current tab selection
   * 
   */

  get_active_cameras(filter='')
  {
    const {get_tuple} = this.menu_cameras_with_pagination();
    const before_remove_not_active_cameras = get_tuple(filter);
    this.remove_not_active_cameras({cameras:before_remove_not_active_cameras});
    const active_cameras = get_tuple(filter);
    return active_cameras;
  }

  //This function remove from the sidemenu all the webrtc(bodyworn) cameras that are not active
  remove_not_active_cameras({cameras})
  {
    const allActiveCameras = this.get_valid_cameras();
    const {remove_camera} = this.menu_cameras_with_pagination();
    if(!cameras && !cameras.length) return;
    cameras.some((cameraTuple)=>cameraTuple.some((camera)=>
    {
      const isCameraActive = allActiveCameras.some((activeCamera)=>
      {
        return activeCamera.name === camera.name;
        
      });
      if(!isCameraActive)
      {
        remove_camera(camera.name);
        MenuCameraManager.remove_not_active_camera(camera.name);
      }
    }));

    
  }

  /**
   * 
   * TODO REWORK THIS FUNCTION AND CHECK IF IT IS POSSIBLE TO ACTUALLY DESTROY THE CAMERA ELEMENTS(INSTEAD OF HIDING.)
   * If we destroy the element we need to make sure that the start_streaming function is not get called multiple times 
   * but only one. This will happen by playing the sttream that we have already saved inside the camera tuple.
   */
 

  add_camera(physical_camera)
  {
    let menu_camera;
    try
    {
      const {add_camera:add_camera_to_pagination} = this.menu_cameras_with_pagination();
      add_camera_to_pagination(physical_camera);
      menu_camera = MenuCameraManager.add_camera(physical_camera);
    }
    catch(err)
    {

      return;
    }

    // console.dir(menu_camera);
    if (menu_camera)
    {
      this.temp_menu_camera = menu_camera;
      /* if (physical_camera.bodyworn === `yes` &&)
      {
        //console.log(`[MenuView:add_camera] Hiding bodyworn menu camera`);
        menu_camera.hide();
      } */

      if (this.app.menu_options_view.show_thumbnail_video)
      {

        menu_camera.start_streaming();
      }

      if (this.app.menu_options_view.show_thumbnail_image)
      {
        menu_camera.start_streaming(true);
      }
      if (!physical_camera || (physical_camera && physical_camera.lens_type === 'flat'))
      {
        this.hide_camera_fisheye_frame(physical_camera.name);
      }
    }
  }

  select_physical_camera(camera)
  {
    this.select_camera_in_menu(camera.name);
    TaglockMarkerManager.hide_select_taglock();

    if(PhysicalCameraManager.selected_camera)
    {
      PhysicalCameraManager.selected_camera.stop_streaming();
    }

    if (this.current_tab == this.tabs.LIVE)
    {
      PhysicalCameraManager.set_active_camera(camera);
    }
    else
    {
      PhysicalCameraManager.set_active_camera(camera, true);  // Don't fly to camera location
    }

    this.app.player_container.show_loading();

    let menu_camera = MenuCameraManager.get_by_name(camera.name);
    if (camera.lens_type === 'fisheye')
    {
      camera.map_marker.set_image_url(menu_camera.stream.get_screenshot_url());
      // this.app.player_settings.hide_lockview_all_axis_target();
    }

    MenuCameraManager.play_all_cameras();

    // Will not get camera if it isn't in current menu page
    if (menu_camera)
    {
      menu_camera.pause();
    }

    this.app.camera_settings_view.fill_hotspot_select();
    PlayerMarkerManager.reset_temp_camera();

    //this fuction is called triggers the play of the video
    this.current_tab.on_camera_selected(this);
  }

  select_camera_in_menu(camera_name)
  {
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);

    $('.menu__cameras-camera__container').removeClass('selected');
    $('.menu__cameras-camera-image-img').removeClass('selected');
    $(camera_el).find('.menu__cameras-camera__container').addClass('selected');
    $(camera_el).find('.menu__cameras-camera-image-img').addClass('selected');

    // Set map_view button on
    $(camera_el).find('.menu__cameras-camera-map_view').addClass('active');
  }

  hide_camera_fisheye_frame(camera_name)
  {
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);

    $(camera_el).find('.menu__cameras-camera-image-img').addClass('invisible');
  }

  unselect_all_cameras_in_menu()
  {
    $('.menu__cameras-camera__container').removeClass('selected');
    $('.menu__cameras-camera-image-img').removeClass('selected');
  }

  update()
  {
    // ios let height = $('.menu__cameras-camera-image-img').width();
    // $('.menu__cameras-camera-image').css('min-height', height);

    let menu_cameras = document.querySelectorAll('.menu__cameras-camera-image');

    for (let i = 0; i < menu_cameras.length; i++)
    {
      const height = menu_cameras[i].querySelector('.menu__cameras-camera-image-img').getBoundingClientRect().height;
      menu_cameras[i].style.height = `${height - 4}px`;
    }
  }

  hide_options()
  {
    this.options_button.addClass('hidden');
  }

  hide_live_tab()
  {
    this.tabs_tab_live.addClass('hidden');
  }

  hide_recordings_tab()
  {
    this.tabs_tab_recordings.addClass('hidden');
  }

  hide_archive_tab()
  {
    this.tabs_tab_archive.addClass('hidden');
  }

  __on_mouse_down(e)
  {
    let origin = this.menu_handle.position().left;
    let touch = e.originalEvent.touches;
    let start = touch ? touch[0].pageX : e.pageX;

    $(window).on('mousemove touchmove', this.__on_mouse_move.bind(this, origin, touch, start));
    $(window).one('mouseup touchend', this.__on_mouse_up.bind(this));

    e.preventDefault();
  }

  __on_mouse_move(origin, touch, start, e)
  {
    let contact = e.originalEvent.touches;
    let end = touch ? contact[0].pageX : e.pageX;
    let difference = end - start;

    let newleft = Math.max(0, origin + difference);

    let newwidth = newleft + 35;

    this.menu_handle.css('left', newleft);
    this.container.css('width', newwidth);
  }

  __on_mouse_up(e)
  {
    e.preventDefault();
    $(window).off('mousemove touchmove');
    localStorage.setItem('menu_width', this.container.width());
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }
}
