import UserRole from './UserRole';

export default class CustUserRole extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    ViewApi.hide_player_info_button();
    ViewApi.hide_recordings_download_buttons();
    ViewApi.hide_recordings_delete_buttons();
    ViewApi.hide_menu_options();
    ViewApi.hide_extract_recording_button();
  }
}
