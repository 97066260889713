import ApplicationView from './common/ApplicationView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import { ResourceContainer } from 'ohzi-core';
import RoleManager from '../RoleManager';
import validate from './utlis/validation/camera_settings/validate';

// This handles the Camera Settings view
/**
 * Handles the functionalitty of camera settings (camera settings is located under options>camera settings).
 * The pug template is camera_settings.pug
 * 
 * @class CameraSettingsView 
 * @description Initiazes or modifies the Camera with the desired settings.
 */
export default class CameraSettingsView extends ApplicationView
{
  constructor(app)
  {
    super('camera-settings', $('.camera-settings'));
    this.app = app;

    this.is_creating_new_camera = false;
  }

  start()
  {
    this.url = `https://${this.app.config.cameras_api_ip}/cameras`;

    // this.headers = {};

    // this.headers['Content-Type'] = 'application/json';

    // this.headers.Authorization = 'Bearer ' + RoleManager.bearer_token;

    this.close_button = $('.menu__buttons-close');

    this.lock = $('.camera-settings__lock');
    this.form = $('.camera-settings__form');
    this.inputs = $('.camera-settings__inputs-input-input');

    this.new_camera_button = $('.camera-settings__options-new-camera');
    this.delete_camera_button = $('.camera-settings__options-delete-camera');
    this.cameras_select = $('.camera-settings__cameras_select');
    this.hotspot_selects = $('.hotspot-select');

    this.__fill_cameras_select();
    this.fill_hotspot_select();
    this.reload_camera_settings();
  }

  get_headers()
  {
    const headers = {};

    headers['Content-Type'] = 'application/json';

    headers.Authorization = 'Bearer ' + RoleManager.bearer_token;

    return headers;
  }

  close()
  {
    this.hide();
    this.app.menu_view.show();
  }

  show()
  {
    // this.container.removeClass('closed')
    super.show();

    this.__fill_cameras_select();
    this.fill_hotspot_select();
    this.reload_camera_settings();

    this.app.menu_options_view.hide();
  }

  // Called by UI (camera_settings.pug) when the user changes the camera select
  reload_camera_settings()
  {
    let form_data = this.__form_data();

    let camera = PhysicalCameraManager.get_by_name(form_data.camera_name);
    let keys = Object.keys(form_data);

    if (camera)
    {
      for (let i = 0; i < keys.length; i++)
      {
        let value = camera[keys[i + 1]];
        switch (typeof value)
        {
        case 'object':
          value = JSON.stringify(value);
          break;
        case 'number':
          value = value.toString().length > 7 ? value.toFixed(7) : value;
          break;
        }

        $(this.inputs[i]).val(value);
      }
    }
  }

  // Called by UI (camera_settings.pug) when the user clicks in new camera button
  new_camera()
  {
    if (!this.new_camera_button.hasClass('active'))
    {
      this.is_creating_new_camera = true;

      this.__clear_form();
      this.__disable_cameras_select();

      this.new_camera_button.addClass('active');
    }
    else
    {
      this.is_creating_new_camera = false;
      this.new_camera_button.removeClass('active');
      this.__enable_cameras_select();
      this.show();
    }
  }

  __default_form_values()
  {
    const bodyworn = document.getElementsByName('bodyworn')[0];
    bodyworn.value = 'No';
  }

  // Called by UI (camera_settings.pug) when the user clicks in the trash icon
  delete_camera()
  {
    let response = confirm('Are you sure?');
    if (response === true)
    {
      let camera = PhysicalCameraManager.get_by_name(this.cameras_select.val());

      if (camera)
      {
        this.delete_camera_on_server(camera._id);
        PhysicalCameraManager.remove_camera(camera);
      }
    }
  }

  delete_camera_on_server(camera_id)
  {
    let delete_url = `${this.url}/delete/${camera_id}`;

    this.__lock_UI();

    fetch(delete_url, {
      method: 'DELETE',
      headers: this.get_headers()
    })
      .then(this.__on_camera_deleted.bind(this));
  }

  isFormValid()
  {
    let form_data = this.__form_data();
    const isValid = validate(form_data);
    return isValid;
  }

  // Called by UI (camera_settings.pug) when the user clicks in apply settings button
  apply_settings()
  {
    let form_data = this.__form_data();
    // if(!this.isFormValid())
    // {
    //   return false;
    // } 
    let camera = undefined;
    let is_creating_new_camera = this.is_creating_new_camera;

    if (this.is_creating_new_camera)
    {
      this.is_creating_new_camera = false;

      this.new_camera_button.click();

      // Delete select param
      delete form_data._id;

      form_data.camera_name = form_data.name;

      if (is_creating_new_camera)
      {
        form_data.bodyworn = 'no';
        form_data.keycloak_id = ' ';
      }

      PhysicalCameraManager.add_camera(form_data);
    }

    camera = PhysicalCameraManager.get_by_name(form_data.camera_name);

    if (camera.bodyworn === 'yes' || camera.cam_type === 'phone')
    {
      console.warn(`Cannot save setings for bodyworn/phone cameras. Name: ${camera.name}`);
      return;
    }

    if (camera)
    {
      PhysicalCameraManager.update_camera(camera.name, form_data);
      this.last_updated_camera = camera;

      this.__fill_cameras_select(camera.name);
      // this.fill_hotspot_select();
      this.reload_camera_settings();
      this.app.menu_view.fill_menu(0);

      this.update_camera_on_server(camera, is_creating_new_camera);
    }
  }

  update_camera_on_server(camera, is_creating_new_camera)
  {
    delete camera._etag;

    let camera_settings = {};

    let config = ResourceContainer.get_resource('camera_settings');
    let keys = Object.keys(config);

    for (let i = 0; i < keys.length; i++)
    {
      if (camera[keys[i]] || camera[keys[i]] === 0)
      {
        camera_settings[keys[i]] = camera[keys[i]];
      }
      else
      {
        if (keys[i] !== '_id')
        {
          camera_settings[keys[i]] = '';
        }
      }
    }

    if (is_creating_new_camera)
    {
      camera_settings.bodyworn = 'no';
      camera_settings.keycloak_id = ' ';
    }

    console.dir(camera_settings);

    this.__lock_UI();

    fetch(this.url, {
      method: 'POST',
      headers: this.get_headers(),
      body: JSON.stringify(camera_settings)
    })
      .then(this.__parse_to_json.bind(this))
      .then(this.__on_server_save.bind(this))
      .catch(this.__on_api_error.bind(this));
  }

  __parse_to_json(response)
  {
    if (response.status === 401)
    {
      throw new Error(response.status);
    }

    return response.json();
  }

  __on_server_save(response)
  {
    if (response.status === 'created')
    {
      this.last_updated_camera._id = response._id;
      this.reload_camera_settings();
    }

    this.__unlock_UI();
  }

  __on_api_error(error)
  {
    this.__unlock_UI();
    this.app.modal_window_view.show(`${error} Unauthorized`);
  }

  __on_camera_deleted(response)
  {
    this.app.menu_view.fill_menu(0);

    this.__fill_cameras_select();
    this.fill_hotspot_select();

    this.reload_camera_settings();

    this.__unlock_UI();
  }

  __fill_cameras_select(selected_camera_name)
  {
    if (PhysicalCameraManager.selected_camera)
    {
      selected_camera_name = selected_camera_name || PhysicalCameraManager.selected_camera.name;
    }

    let select = this.cameras_select[0];

    this.__reset_select(select);
    let cameras = PhysicalCameraManager.get_editable_cameras();

    this.__fill_select(select, cameras, selected_camera_name);
  }

  fill_hotspot_select()
  {
    for (let i = 0; i < this.hotspot_selects.length; i++)
    {
      const select = this.hotspot_selects[i];

      this.__reset_select(select);

      let none_option = document.createElement('option');
      none_option.setAttribute('value', 'none');

      let none_text_node = document.createTextNode('None');
      none_option.appendChild(none_text_node);

      select.appendChild(none_option);

      let cameras = PhysicalCameraManager.cameras;
      let selected_camera_name = PhysicalCameraManager.selected_camera ? PhysicalCameraManager.selected_camera.hotspot : '';

      this.__fill_select(select, cameras, selected_camera_name);
    }
  }

  __reset_select(select)
  {
    select.innerHTML = '';

    let option = document.createElement('option');
    option.setAttribute('disabled', 'disabled');

    let text_node = document.createTextNode(select.dataset.disabledText);
    option.appendChild(text_node);
    select.appendChild(option);
  }

  __fill_select(select, cameras, selected_camera_name)
  {
    for (let i = 0; i < cameras.length; i++)
    {
      let option = document.createElement('option');
      option.setAttribute('value', cameras[i].name);

      if (selected_camera_name === cameras[i].name)
      {
        option.setAttribute('selected', 'selected');
      }

      let text_node = document.createTextNode(cameras[i].name);
      option.appendChild(text_node);

      select.appendChild(option);
    }
  }

  __form_data()
  {
    return this.form.serializeArray().reduce(function(obj, item)
    {
      obj[item.name] = item.value;
      return obj;
    }, {});
  }

  __clear_form()
  {
    for (let i = 0; i < this.inputs.length; i++)
    {
      $(this.inputs[i]).val('');
    }
  }

  __disable_cameras_select()
  {
    this.cameras_select.addClass('disabled');
  }

  __enable_cameras_select()
  {
    this.cameras_select.removeClass('disabled');
  }

  __lock_UI()
  {
    this.lock.addClass('enabled');
  }

  __unlock_UI()
  {
    this.lock.removeClass('enabled');
  }
}
