import Legitity from '../Legitity';

const validateForm = (form_data)=>
{
  let isFormValid = false;
  const fields = 
  {
    bodyworn:{
      validate:()=> validateBodyWarm(form_data['bodyworn'])
    },
    name:{
      validate:()=>validateCameraName(form_data['name'])
    }
  };

  isFormValid = areAllFieldsValid(fields);
  return isFormValid;
};

const validateFields = (fields)=>
{
  return Object.keys(fields).reduce((obj,name)=>
  {
    obj[name] = fields[name].validate();
    return obj;
  },{});
};

const areAllFieldsValid = (fields) => 
{
  const allFields = validateFields(fields);
  const allFiedsKeys = Object.keys(allFields);
  const areFieldsValid = allFiedsKeys.every((fieldKey) => !allFields[fieldKey].error);
  return areFieldsValid;
};

const validateBodyWarm = (bodyworn)=>
{
  const inputName = 'bodyworn';
  const isBodyWarmValid = (value)=> value === 'Yes';
  const validate = new Legitity(bodyworn).trim().custom(isBodyWarmValid,'BodyWarm shoud be yes');
  appendErrorClassToInputField(validate,inputName);
  return validate;
};

const validateCameraName = (name)=>
{
  const inputName = 'name';
  const validate = new Legitity(name).trim().required('Camera name is required');
  appendErrorClassToInputField(validate,inputName);
  return validate;
};

const appendErrorClassToInputField = (validate,inputName)=>
{
  const element = document.getElementsByName(inputName)[0];
  if(validate.error)
  {
    element.classList.add('input-error');
  }
  else
  {
    element.classList.remove('input-error');
  }
};



export default validateForm;