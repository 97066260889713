import PlayerMarkerMaterial from '/js/materials/PlayerMarkerMaterial';

// This class represents the icon marker placed in the camera environment, displayed on the player
export default class PlayerMarker extends THREE.Points
{
  constructor()
  {
    let geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0], 3));

    super(geometry, new PlayerMarkerMaterial());

    this.renderOrder = 9000;
    this.visible = false;
  }

  set_position(pos)
  {
    this.position.copy(pos);
    this.show();
  }

  update_label(label)
  {

  }

  show()
  {
    this.visible = true;
  }

  hide()
  {
    this.visible = false;
  }
}
