import ApplicationView from './common/ApplicationView';
import { Time } from 'ohzi-core';

// This represents Detailed playback Bar UI
export default class DetailedPlaybackBar extends ApplicationView
{
  constructor()
  {
    super('player__detailed-playback-bar', $('.player__detailed-playback-bar'));

    this.scale_t = 0;
    this.showing_toggle = -1;
  }

  start()
  {
    this.minute_lines_container = $('.player__minute-lines');
  }

  show(playback_bar)
  {
    super.show(0.2);

    let mouse_px = playback_bar.get_mouse_px();

    this.minute_lines_container[0].style['transform-origin'] = `${mouse_px}px center`;

    this.showing_toggle = 1;
  }

  hide()
  {
    super.hide();

    this.scale_t = 0;
    this.showing_toggle = -1;
  }

  update()
  {
    this.scale_t += Time.delta_time * this.showing_toggle;
    this.scale_t = THREE.Math.clamp(this.scale_t, 0, 1);

    if (this.scale_t >= 0.5)
    {
      this.minute_lines_container[0].style.transform = 'scaleX(2)';
    }
    else
    {
      this.minute_lines_container[0].style.transform = 'scaleX(1)';
    }
  }
}
