import { Input } from 'ohzi-core';
import { Time } from 'ohzi-core';
import { MathUtilities } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';

import CameraViewState from '../states/CameraViewState';

// This class handles the movement of the THREE Camera
export default class FisheyeControls extends CameraViewState
{
  constructor()
  {
    super();

    this.upper_tilt_limit = 90;
    this.lower_tilt_limit = -90;

    this.horizontal_rot_speed = 0;
    this.vertical_rot_speed = 0;

    this.zoom_t = 0;

    this.starting_fov = 60;
    this.zoomed_in_fov = undefined;

    this.fisheye_fov = 180;
    this.use_upper_hemisphere = false;

    this.pan_enabled = false;

    this.last_NDC = new THREE.Vector2();
  }

  start()
  {
    let app_config = ResourceContainer.get_resource('config');
    this.x_rotation_speed = app_config.x_rotation_speed;
    this.y_rotation_speed = app_config.y_rotation_speed;
  }

  set_fisheye_fov_and_hemisphere(fisheye_fov, use_upper_hemisphere)
  {
    this.fisheye_fov          = fisheye_fov;
    this.use_upper_hemisphere = use_upper_hemisphere;
  }

  on_enter(camera_controller)
  {
    this.starting_fov = camera_controller.camera.fov;
    this.zoomed_in_fov = this.starting_fov * 0.1;
  }

  update(camera_controller)
  {
    if (!camera_controller.input_enabled)
    {
      return;
    }

    if (Input.left_mouse_button_pressed && Input.mouse_is_within_bounds())
    {
      this.last_NDC.copy(Input.NDC);

      this.pan_enabled = true;
    }
    if (Input.left_mouse_button_down && this.pan_enabled)
    {
      let delta_NDC = Input.NDC.clone().sub(this.last_NDC);
      this.horizontal_rot_speed   += delta_NDC.x * this.x_rotation_speed;
      this.vertical_rot_speed     -= delta_NDC.y * this.y_rotation_speed;
      this.last_NDC.copy(Input.NDC);
    }
    if (Input.left_mouse_button_released)
    {
      this.pan_enabled = false;
    }

    if (Input.mouse_is_within_bounds())
    {
      this.update_zoom(camera_controller);
    }

    this.update_tilt_bounds(camera_controller);
    this.update_rotation(camera_controller, this.horizontal_rot_speed, this.vertical_rot_speed);

    this.horizontal_rot_speed *= 0.9; // decay
    this.vertical_rot_speed   *= 0.9; // decay
  }

  update_rotation(camera_controller, horizontal_rot, vertical_rot)
  {
    if (camera_controller.current_tilt + vertical_rot > this.upper_tilt_limit)
    {
      vertical_rot = this.upper_tilt_limit - camera_controller.current_tilt;
    }

    if (camera_controller.current_tilt + vertical_rot < this.lower_tilt_limit)
    {
      vertical_rot = this.lower_tilt_limit - camera_controller.current_tilt;
    }

    camera_controller.set_rotation_delta(horizontal_rot, vertical_rot);
  }

  update_zoom(camera_controller)
  {
    let zoom_delta = this.get_zoom_delta();

    let power = Input.is_mac() ? -10 : -3;
    this.zoom_t += zoom_delta * Time.delta_time * power;
    this.zoom_t = THREE.Math.clamp(this.zoom_t, 0, 1);
    camera_controller.camera.fov = THREE.Math.lerp(this.starting_fov, this.zoomed_in_fov, this.zoom_t);
    camera_controller.camera.updateProjectionMatrix();
  }

  get_zoom_delta()
  {
    if (Input.zoom_started)
    {
      return Input.touch_zoom_delta * -0.05;
    }
    else
    {
      return Input.wheel_delta;
    }
  }

  update_tilt_bounds(camera_controller)
  {
    if (this.use_upper_hemisphere)
    {
      this.upper_tilt_limit = 90;
      this.lower_tilt_limit = MathUtilities.linear_map(this.fisheye_fov, 0, 360, 90, -90) + camera_controller.camera.fov / 2;
    }
    else
    {
      this.upper_tilt_limit = MathUtilities.linear_map(this.fisheye_fov, 0, 360, -90, 90) - camera_controller.camera.fov / 2;
      this.lower_tilt_limit = -90;
    }
  }
}
