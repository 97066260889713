import TelemetryController from './TelemetryController';

// This handles the streaming of the live telemetry
export default class LiveTelemetryController extends TelemetryController
{
  constructor(app)
  {
    super(app, app.config.live_telemetry_websocket_host, app.menu_view.tabs.LIVE);
  }
}
