import MenuCameraStatusState  from './MenuCameraStatusState';
import WaitingForStreaming    from './WaitingForStreaming';
import { Time } from 'ohzi-core';

export default class BootingUp extends MenuCameraStatusState
{
  constructor()
  {
    super();

    this.elapsed_time = 0;
  }

  on_enter(menu_camera_status)
  {

  }

  update(menu_camera_status)
  {
    this.elapsed_time += Time.delta_time;
    let color = this.elapsed_time % 1 > 0.5 ? '#f7ff0f' : '#000000';
    menu_camera_status.set_rec_icon_color(color);

    if (this.elapsed_time > 3)
    {
      menu_camera_status.set_state(new WaitingForStreaming());
    }
  }

  on_config_received(menu_camera_status)
  {
  }
}
