import CameraViewState from '../states/CameraViewState';
import { Input } from 'ohzi-core';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This class sets the THREE camera to a fixed position
export default class CameraFixedState extends CameraViewState
{
  constructor(oobb)
  {
    super();

    this.plane_width = 160;
    this.plane_height = 90;
  }

  on_enter(camera_controller)
  {
    this.update_camera_distance(camera_controller);
  }

  on_exit(camera_controller)
  {
  }

  update(camera_controller)
  {
    camera_controller.camera.position.set(0, 0, camera_controller.get_zoom_to_show_rect(this.plane_width, this.plane_height));
  }

  update_camera_distance(camera_controller)
  {
    // PTZ current 640x360
    // Test fisheye is 1440x1440
    // console.log(`[PTZControls:on_enter]`);
    // console.dir(camera_controller);
    let fov = camera_controller.camera.fov;

    let selected_camera = PhysicalCameraManager.selected_camera;

    // let video_height = selected_camera.video_height;
    // let video_width = selected_camera.video_width;
    let video_height = 720;
    let video_width = 720;

    // console.dir(selected_camera);

    // let dist = selected_camera.video_height / 2 / Math.tan(Math.PI * fov / 360);
    // let dist = video_height / 2 / Math.tan(Math.PI * fov / 360);
    // let dist = video_width / 2 / Math.tan(Math.PI * fov / 360);
    let dist = 0;

    let player_width = camera_controller.player_container.container.width();
    let player_height = camera_controller.player_container.container.height();

    // let new_dist = dist / (player_height / 70);
    // let new_dist = dist / (player_width / 65);
    let new_dist = 0;

    let fovh = fov * video_width / video_height;  // Calculate FOV (horiztonal plane) based on fov and spect ratio

    if (camera_controller.player_container.is_maximized)
    {
      // Player is full screen
      dist = video_width / 2 / Math.tan(Math.PI * fovh / 360);
      new_dist = dist / (player_width / 417);
      // console.log(`[PTZControls:on_enter] full screen - fovh: ${fovh}, dist: ${dist}, new_dist: ${new_dist}`);
    }
    else
    {
      // Not full screen
      dist = video_width / 2 / Math.tan(Math.PI * fovh / 360);
      new_dist = dist / (player_width / 152);
      // console.log(`[PTZControls:on_enter] not full screen - fov: ${fovh}, dist: ${dist}, new_dist: ${new_dist}`);
    }
    // console.log(`[PTZControls:on_enter] selected_camera.video_height: ${video_height}, dist: ${dist}, new_dist: ${new_dist}`);

    // console.log(`[PTZControls:on_enter] player_container player_width: ${player_width}, player_height: ${player_height}`);

    // Test app.camera_controller.camera.position.set(0,0,90)

    if (PhysicalCameraManager.selected_camera)
    {
      this.plane_width = PhysicalCameraManager.selected_camera.fisheye_sphere.scale.x;
      this.plane_height = PhysicalCameraManager.selected_camera.fisheye_sphere.scale.y;
    }

    // Set camera perspective to default for flat camera (we don't change this during this view)
    // camera_controller.camera.near = 0.1;
    camera_controller.camera.far = 1000;
    // camera_controller.camera.position.set(0,0,new_dist);

    camera_controller.camera.position.set(0, 0, camera_controller.get_zoom_to_show_rect(this.plane_width, this.plane_height));
    camera_controller.camera.rotation.set(0, 0, 0);
    camera_controller.camera.quaternion.set(0, 0, 0, 1);
    camera_controller.camera.updateProjectionMatrix();
  }
}
