import { ResourceContainer } from 'ohzi-core';
import TelemetryController from './TelemetryController';
import RoleManager from '/js/components/RoleManager';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

/* global moment */
// This handles the streaming of the recorded telemetry
export default class RecordingTelemetryController extends TelemetryController
{
  constructor(app)
  {
    super(app, app.config.recording_telemetry_websocket_host, app.menu_view.tabs.RECORDINGS);
  }

  start_streaming(camera, fragment, playback_time)
  {
    let app_config = ResourceContainer.get_resource('config');

    let playback_offset = playback_time - fragment.start_ms;
    let start_time = moment(fragment.recording.start_date).add(playback_offset).format('YYYY-MM-DDTHH:mm:ssZ');

    camera.is_telemetry_streaming = true;

    let data = {
      camera_id: camera._id,
      start_time: start_time
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/external/streaming/start`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__start_streaming_response.bind(this)
    });
  }

  stop_streaming(camera)
  {
    let app_config = ResourceContainer.get_resource('config');

    camera.is_telemetry_streaming = false;

    let data = {
      camera_id: camera._id
    };

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/external/streaming/stop`,
      headers: RoleManager.get_auth_header(),
      timeout: 2000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__stop_streaming_response.bind(this)
    });
  }

  extract(data)
  {
    let app_config = ResourceContainer.get_resource('config');

    console.log(data.start_time);
    console.log(data.stop_time);

    $.ajax({
      type: 'POST',
      url: `${app_config.recordings_api_host}/telemetry/external/export`,
      headers: RoleManager.get_auth_header(),
      timeout: 5000,
      dataType: 'json',
      data: JSON.stringify(data),
      complete: this.__telemetry_extract_response.bind(this)
    });
  }

  __start_streaming_response(response)
  {
    this.__show_error_messages(response);

    if (response.status !== 200)
    {
      // Fly if telemetry start fails
      PhysicalCameraManager.selected_camera.fly_to_camera_marker();
    }
  }

  __stop_streaming_response(response)
  {
    this.__show_error_messages(response);
  }

  __telemetry_extract_response(response)
  {
    this.__show_error_messages(response);
  }
}
