import SphereBufferGeometry from './SphereBufferGeometry';

/**
 * @class CustomSphere
 * @description Creates the sphere that appears on the map when a user selects a camera.
 */

export default class CustomSphere
{
  constructor(spherePosition, textureUrl, initialTransparency, isInverted)
  {
    this.spherePrimitive = undefined;
    this.spherePosition = spherePosition;
    this.textureUrl = textureUrl;
    this.initialTransparency = initialTransparency;
    this.isInverted = isInverted;

    this.rotationXPositive180 = Cesium.Matrix3.fromRotationX(Cesium.Math.PI, new Cesium.Matrix3());
    this.rotationXMatrix4Positive180 = Cesium.Matrix4.fromRotationTranslation(this.rotationXPositive180, undefined, new Cesium.Matrix4());
  }

  create_custom_mapping_sphere_primitive(scale, widthSegments, heightSegments)
  {
    let ballRadius = 1;
    let sphereGeometry = new SphereBufferGeometry(ballRadius, widthSegments, heightSegments);

    let positions = sphereGeometry.position;

    for (let i = 0, l = positions.length / 3; i < l; i++)
    {
      let x = positions[i * 3 + 0];
      let y = positions[i * 3 + 1];
      let z = positions[i * 3 + 2];

      // three js axis direction to cesium js axis direction
      positions[i * 3 + 1] = z;
      positions[i * 3 + 2] = -y;
    }

    let normals = sphereGeometry.normal;
    let uvs = sphereGeometry.uv;

    const canvasHeight = 2048;
    const canvasWidth = 2048;
    const verticalFov = 360;

    for (let i = 0, l = normals.length / 3; i < l; i++)
    {
      let x = normals[i * 3 + 0];
      let y = normals[i * 3 + 1];
      let z = normals[i * 3 + 2];

      // radius center
      let correction = (x == 0 && z == 0) ? 1 : (Math.acos(y) / Math.sqrt(x * x + z * z)) * (2 / ((verticalFov * Math.PI) / 180));

      uvs[i * 2 + 0] = x * ((canvasHeight / 2) / canvasWidth) * correction + ((canvasWidth / 2) / canvasWidth);
      uvs[i * 2 + 1] = z * ((canvasHeight / 2) / canvasHeight) * correction + ((canvasHeight / 2) / canvasHeight);
    }

    let position = new Cesium.GeometryAttribute({
      componentDatatype: Cesium.ComponentDatatype.DOUBLE,
      componentsPerAttribute: 3,
      values: new Float64Array(sphereGeometry.position)
    });

    let normal = new Cesium.GeometryAttribute({
      componentDatatype: Cesium.ComponentDatatype.FLOAT,
      componentsPerAttribute: 3,
      values: new Float32Array(sphereGeometry.normal)
    });

    let st = new Cesium.GeometryAttribute({
      componentDatatype: Cesium.ComponentDatatype.FLOAT,
      componentsPerAttribute: 2,
      values: new Float32Array(sphereGeometry.uv)
    });

    let material = this.create_material(this.textureUrl, this.initialTransparency);

    this.spherePrimitive = new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.Geometry({
          attributes: {
            position: position,
            normal: normal,
            st: st
          },
          indices: new Uint16Array(sphereGeometry.index),
          primitiveType: Cesium.PrimitiveType.TRIANGLES,
          boundingSphere: Cesium.BoundingSphere.fromVertices(sphereGeometry.position)
        })
      }),
      appearance: new Cesium.MaterialAppearance({
        material: material,
        closed: true
      }),
      asynchronous: false
    });

    this.spherePrimitive.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(this.spherePosition);

    if (this.isInverted)
    {
      this.spherePrimitive.modelMatrix = Cesium.Matrix4.multiply(this.spherePrimitive.modelMatrix, this.rotationXMatrix4Positive180, new Cesium.Matrix4());
    }

    this.spherePrimitive.modelMatrix = Cesium.Matrix4.setScale(
      this.spherePrimitive.modelMatrix,
      new Cesium.Cartesian3(scale, scale, scale),
      new Cesium.Matrix4()
    );

    return this.spherePrimitive;
  }

  create_material(url, alpha)
  {
    return new Cesium.Material({
      fabric: {
        type: 'DiffuseMap',
        uniforms: {
          image: url,
          repeat: new Cesium.Cartesian2(1.0, 1.0),
          alpha: alpha
        },
        components: {
          diffuse: 'texture2D(image, fract(repeat * materialInput.st)).rgb',
          alpha: 'texture2D(image, fract(repeat * materialInput.st)).a * alpha'
        }
      }
      // translucent : true
    });
  }
}
