import { Screen } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

// import FisheyeDebugMaterial from '/js/materials/FisheyeDebugMaterial';

// This class represents the plane geometry (in the player) where the camera video texture is applied
export default class TexturePlane extends THREE.Mesh
{
  constructor(fisheye_fov, use_upper_hemisphere)
  {
    // TODO: Change this to dynamic size based on video resolution and device resolution, most flat cameras are 16:9 ratio
    // DOn't know what resolution the webRTC stream is until it is live
    let geo = new THREE.PlaneGeometry(1, 1); // Have been using this

    // let material = new THREE.MeshBasicMaterial( { map: videoTexture, overdraw: true, side:THREE.DoubleSide } );
    let material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide });

    super(geo, material);

    this.horizontal_geometry = geo;
    // this.vertical_geometry = new THREE.PlaneGeometry(1, 1);
    // this.vertical_geometry.rotateZ(-Math.PI / 2);

    this.last_fov = undefined;
    this.last_use_upper_hemisphere = undefined;

    this.frames = 0;
    this.is_playing = false;

    // TODO: Get this instance from PlayerView
    this.player_video_element = document.querySelector('.player__video');
  }

  update()
  {
    if (Configuration.is_ios)
    {
      if (this.is_playing)
      {
        if (this.frames > 30)
        {
          this.frames = 0;
          this.material.map.needsUpdate = true;
        }

        this.frames++;
      }
    }
  }

  on_video_player_play(is_recording)
  {
    let video_width = $(this.player_video_element).width();
    let video_height = $(this.player_video_element).height();

    this.geometry = this.horizontal_geometry;

    if (video_height > video_width)
    {
      this.scale.x = 90;
      this.scale.y = 160;

      // if (is_recording)
      // {
      //   this.geometry = this.vertical_geometry;
      // }
    }
    else
    {
      this.scale.x = 160;
      this.scale.y = 90;
    }
  }

  set_rotation(yaw, roll, pitch)
  {
    // Convert from earth bearing to threejs sphere yaw
    let fixed_yaw = 360 - yaw;

    // Convert roll, yaw and pitch to Quaternions
    let roll_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.Math.degToRad(parseFloat(roll)));
    let yaw_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.Math.degToRad(parseFloat(fixed_yaw)));
    let pitch_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.Math.degToRad(parseFloat(pitch)));
  }

  set_fov_and_use_upper_hemisphere(fov, use_upper_hemisphere)
  {

  }

  set_texture(map)
  {
    this.material.map = map;
    // Set debug texture to main texture for testing
    // this.material.map = ResourceContainer.get_resource('fisheye_tex');
  }

  dispose()
  {
    this.geometry.dispose();
    this.material.dispose();
    if (this.parent)
    {
      this.parent.remove(this);
    }
  }
}
