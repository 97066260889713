import { Graphics } from 'ohzi-core';
import { RenderLoop } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { Debug } from 'ohzi-core';
import { Input } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import RoleManager from './js/components/RoleManager';

// APP
import MainApplication from './MainApplication';
import PlayerSettings from './js/components/PlayerSettings';
import RecordingsListRowManager from './js/components/RecordingsListRowManager';

const application = new MainApplication(Graphics);
const render_loop = new RenderLoop(application, Graphics);

// Global variable to access to the app from the browser
window.ViewApi = {
  init: (parameters) =>
  {
    let body = document.querySelector('body');
    let canvas = document.getElementById('main-canvas');

    Configuration.is_mobile = parameters.is_mobile;
    Configuration.is_ios = parameters.is_ios;
    Configuration.is_ipad = parameters.is_ipad;

    Graphics.init(canvas);

    Input.init(body, canvas);
    Debug.init(Graphics);
  },

  draw_debug_axis: () =>
  {
    Debug.draw_axis();
  },

  on_orientation_change: () =>
  {
    application.on_orientation_change();
  },

  resize_canvas: () =>
  {
    application.on_resize();
  },

  resource_loading_completed: () =>
  {
    application.resources_fully_loaded();
  },

  start: () =>
  {
    render_loop.start();
  },

  stop: () =>
  {
    render_loop.stop();
  },

  hide_player_info_button: () =>
  {
    PlayerSettings.hide_info_button();
  },

  hide_player_target_lock_button: () =>
  {
    PlayerSettings.hide_button_taglock();
  },

  hide_player_target_select: () =>
  {
    PlayerSettings.hide_select_target();
  },
  hide_player_select_dropdown: () =>
  {
    PlayerSettings.hide_select_dropdown();
  },

  hide_recordings_download_buttons: () =>
  {
    RecordingsListRowManager.hide_download_buttons();
  },

  hide_recordings_delete_buttons: () =>
  {
    RecordingsListRowManager.hide_delete_buttons();
  },

  hide_menu_options: () =>
  {
    application.menu_view.hide_options();
  },

  hide_extract_recording_button: () =>
  {
    application.player_view.extract_recording_view.hide_open_button(true);
  },

  hide_live_tab: () =>
  {
    application.menu_view.hide_live_tab();
  },

  hide_recordings_tab: () =>
  {
    application.menu_view.hide_recordings_tab();
  },

  hide_archive_tab: () =>
  {
    application.menu_view.hide_archive_tab();
  },

  select_live_tab: () =>
  {
    application.menu_view.select_tab(application.menu_view.tabs.LIVE, document.querySelector('.menu__tabs-tab--live'));
  },

  select_recordings_tab: () =>
  {
    application.menu_view.select_tab(application.menu_view.tabs.RECORDINGS, document.querySelector('.menu__tabs-tab--recordings'));
  },

  select_archive_tab: () =>
  {
    application.menu_view.select_tab(application.menu_view.tabs.ARCHIVE, document.querySelector('.menu__tabs-tab--archive'));
  },

  set_menu_image_thumbnail: () =>
  {
    application.menu_options_view.toggle_thumbnail('image');
  },

  set_menu_video_thumbnail: () =>
  {
    application.menu_options_view.toggle_thumbnail('video');
  },

  app: application,
  config: Configuration,
  resource_container: ResourceContainer,
  role_manager: RoleManager
};
