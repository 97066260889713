export default class Vector3
{
  constructor(x, y, z)
  {
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
  }

  length()
  {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  copy(v)
  {
    this.x = v.x;
    this.y = v.y;
    this.z = v.z;

    return this;
  }

  multiplyScalar(scalar)
  {
    this.x *= scalar;
    this.y *= scalar;
    this.z *= scalar;

    return this;
  }

  divideScalar(scalar)
  {
    return this.multiplyScalar(1 / scalar);
  }

  normalize()
  {
    return this.divideScalar(this.length() || 1);
  }
}
