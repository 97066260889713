/* global moment */
// This class represents a row in RecordingListView
export default class RecordingsListRow
{
  constructor(start_time_ms, end_time_ms, fragment, url, html_element)
  {
    let start_time = moment(start_time_ms).utc().format('HH:mm:ss');
    let end_time = moment(end_time_ms).utc().format('HH:mm:ss');

    // Save start time for sorting purposes
    this.start_time_ms = start_time_ms;

    this.__html_element = html_element;
    this.__html_element[0].setAttribute('data-start-time-ms', start_time_ms);

    let start_date = moment(fragment.recording.start_date).format('YYYY-MM-DDTHH:mm:ssZ');
    let end_date = moment(fragment.recording.end_date).format('YYYY-MM-DDTHH:mm:ssZ');

    this.__html_element.find('.recordings-list__button--delete')[0].setAttribute('data-start-date', start_date);
    this.__html_element.find('.recordings-list__button--delete')[0].setAttribute('data-end-date', end_date);
    this.__html_element.find('.recordings-list__button--delete')[0].setAttribute('data-file-name', fragment.file_name);

    this.__html_element.find('.recordings-list__button--download').prop('href', url);
    this.__html_element.find('.recordings-list__button--download')[0].setAttribute('data-file-name', fragment.file_name);
    this.__html_element.find('.recordings-list__from-time').text(start_time);
    this.__html_element.find('.recordings-list__to-time').text(end_time);
  }

  append_to(container)
  {
    container.append(this.__html_element);
  }

  update()
  {
  }
}
