import UserRole from './UserRole';

/*
Role: cust_suboperator

Taglock menu on the player: Hidden
Option menu: Hidden
*/

export default class CustSuboperator extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    ViewApi.hide_menu_options();   
    ViewApi.hide_player_target_lock_button();
    ViewApi.hide_player_target_select();
    ViewApi.hide_player_select_dropdown();
    ViewApi.hide_recordings_download_buttons();
    ViewApi.hide_recordings_delete_buttons();

  }
}
