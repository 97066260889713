import { Time } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Validation } from 'ohzi-core';
import PhysicalCameraManager from './PhysicalCameraManager';
import CameraTargetUtilities from './CameraTargetUtilities';
import CustomSphere from './cesium/CustomSphere';
import CustomPlane from './cesium/CustomPlane';
import CustomCameraMovement from './cesium/CustomCameraMovement';
import PlayerSettings from './PlayerSettings';

import {getMapLabelColor,color_mapping} from '../helpers/';

// This is a wrapper that handles all interactivity with cesium app
class CesiumHandler
{
  constructor(parameters = {})
  {
    console.log(`Cesium Version: ${Cesium.VERSION}`);

    this.viewer = undefined;
    this.markers = {};
    this.can_fly = true;
    this.current_animation_duraction = 2;
    this.t = 0;

    this.custom_camera_movement = undefined;

    this.default_label_color = Cesium.Color.YELLOW;
    this.handler                = undefined;
    this.plane_mouse_over       = false;
    this.label_color = this.default_label_color;

    this.app = undefined;
  }

  set_app(app)
  {
    this.app = app;
  }

  start()
  {
    let app_config = ResourceContainer.get_resource('config');

    let esri = undefined;

    if (navigator.onLine)
    {
      Cesium.Ion.defaultAccessToken = app_config.cesium_token;
      // Access to CC3D tileset
      //Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ZTAyY2Q0ZC00Y2RhLTQ1YzktOTc1Yi1lYzJkZjkzNTgwMzEiLCJpZCI6NDIsImlhdCI6MTYwMzg0MDgxNX0.1CQ6M1YPJvyZGrYpeXNR3LVyVoVSIsFyQk-ZS3UjLCQ";

      esri = new Cesium.ArcGisMapServerImageryProvider({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer'
      });
    }
    else
    {
      esri = new Cesium.ProviderViewModel({
        name: 'Natural Earth\u00a0II',
        iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/naturalEarthII.png'),
        tooltip: 'Natural Earth II, use for Offline mode',
        creationFunction: function()
        {
          return new Cesium.TileMapServiceImageryProvider({
            url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII')
          });
        }

      });
    }

    this.viewer = new Cesium.Viewer('cesiumContainer', {
      imageryProvider: navigator.onLine ? esri : false,
      useBrowserRecommendedResolution: true,
      showRenderLoopErrors: false,
      // showRenderLoopErrors: true,
      shouldAnimate: true,
      requestRenderMode : true
    });

    //Listen to Imagery change event,
    this.viewer.imageryLayers.layerAdded.addEventListener((data)=>
    {
      this.change_label_color(data.imageryProvider.url);


    });

    // Add Cesium Inspector
    // this.viewer.extend(Cesium.viewerCesiumInspectorMixin);

    // Add Cesium OSM buildings to the scene
    this.osm_buildings_tileset = Cesium.createOsmBuildings();

    // Add CC3D 3D Model tileset (Philadelphia only?)
    var cc3d_tileset = this.viewer.scene.primitives.add(
      new Cesium.Cesium3DTileset({
        url: Cesium.IonResource.fromAssetId(1010),
      })
    );

    cc3d_tileset.readyPromise
    .then(function () {
      console.log('CC3D 3D Model tileset ready');
      //viewer.zoomTo(cc3d_tileset);

      // Apply the default style if it exists
      var extras = cc3d_tileset.asset.extras;

      if (Cesium.defined(extras) &&
        Cesium.defined(extras.ion) &&
        Cesium.defined(extras.ion.defaultStyle))
      {
        cc3d_tileset.style = new Cesium.Cesium3DTileStyle(extras.ion.defaultStyle);
      }
    })
    .otherwise(function (error) {
      console.log(error);
    });

    if (navigator.onLine)
    {

      let esri_button = $('.cesium-baseLayerPicker-item')[11];

      if (esri_button)
      {
        // Select esri imagery provider
        esri_button.click();
      }
    }
    else
    {
      this.base_layer_picker = new Cesium.BaseLayerPicker('baseLayerPickerContainer', {
        globe: this.viewer.scene.globe,
        imageryProviderViewModels: [esri],
        selectedImageryProviderViewModel: esri
      });
    }

    this.custom_camera_movement = new CustomCameraMovement(this.viewer);

    $(this.viewer._animation.container).css('visibility', 'hidden');
    $(this.viewer._timeline.container).css('visibility', 'hidden');
    this.viewer.forceResize();

    // Mouse pointer tool tip entity
    this.pointer_tooltip = this.viewer.entities.add({
      label: {
        show: false,
        showBackground: true,
        font: "14px monospace",
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        pixelOffset: new Cesium.Cartesian2(50, 50),
        // From marker label text
        //text: payload.text,
        //pixelOffset: new Cesium.Cartesian2(0, -30),
        //font: '18px Helvetica',
        //fillColor: this.label_color,
        //outlineWidth: 2,
        //style: Cesium.LabelStyle.FILL
          
      },
    });

    // Update target on right click
    this.viewer.canvas.addEventListener(
      'contextmenu', // Right Click
      this.__set_target_position.bind(this)
    );

    let viewer = this.viewer;
    let _this = this;

    // Detect mouse over plane geometery
    this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);

    this.handler.setInputAction(function(movement)
    {
      let selected_obj= _this.get_selected_obj_from_point(movement.endPosition);
      if (selected_obj != null) {
        if (_this.is_object_clickable(selected_obj))
        {
          viewer._container.style.cursor = "pointer";
          //_this.pointer_tooltip.label.show = true;
          //_this.pointer_tooltip.label.text = 'test tooltip';
          //console.dir(_this.pointer_tooltip)
        }
        else
        {
          viewer._container.style.cursor = "default";
        }
      } else{
        _this.pointer_tooltip.label.show = false;
        viewer._container.style.cursor = "default";
      }

      if (PhysicalCameraManager.selected_camera_tandem)
      {
        try
        {
          var pickedObject = viewer.scene.pick(movement.endPosition); // This can generate a RangeError: Invalid array length

          // Check if we are mousing over plane geometry
          if (pickedObject && pickedObject.primitive.appearance && pickedObject.primitive.appearance.material.type === 'Image' && PhysicalCameraManager.selected_camera_tandem)
          {
            // Disable map controls
            _this.custom_camera_movement.enableDisableScreenSpaceCameraController(false);
            _this.plane_mouse_over = true;
          }
          else
          {
            // Enable map controls (only if view lock is not on)
            if (!PlayerSettings.lock_view)
            {
              _this.custom_camera_movement.enableDisableScreenSpaceCameraController(true);
            }
            _this.plane_mouse_over = false;
          }
        }
        catch (err)
        {
          console.log(`[CesiumHandler:start] Mouse movement handler error: ${err.name}`);
          console.log(`[CesiumHandler:start] plane_mouse_over: ${_this.plane_mouse_over}`);
          console.dir(err);
          console.dir(movement);
          console.dir(_this.viewer.scene);
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    this.handler.setInputAction(function(wheel)
    {
      if (_this.plane_mouse_over)
      {
        wheel = THREE.Math.clamp(wheel, -50, 50);
        PhysicalCameraManager.camera_controller_tandem.set_zoom_delta(wheel);
      }
    }, Cesium.ScreenSpaceEventType.WHEEL);

    // Handle selecting icons in the Cesium Map, i.e clicking on a camera icon to open
    this.handler.setInputAction(function(click)
    {
      let object = _this.get_selected_obj_from_point(click.position);
      if (object != null) {
        if (_this.is_object_clickable(object))
        {
          let object_id = object.id && object.id.object_id ? object.id.object_id : object.primitive.object_id;
          let object_type = object.id && object.id.object_type ? object.id.object_type : object.primitive.object_type;
           console.log(`Clicked on: ${object_id} | ${object_type}`);

          if (object_id)
          {
            if (object_type === 'camera')
            {
              let camera = PhysicalCameraManager.get_by_name(object_id);
              console.log('Selecting camera ' + camera);
              if (camera)
              {
                if (camera.is_live_stream_available())
                {
                  // Use same mechanism as selecting thumb in menu
                  _this.app.menu_view.select_physical_camera(camera);
                }
                else if (camera.is_bodyworn_remote_available())
                {
                  // Remote start video stream then open
                  _this.app.webrtc_view.remote_stream_start(camera, true);
                }
                
              }
            }
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

  }

  get_selected_obj_from_point(Position)
  {
    //console.dir(this);
    var valueToReturn= null;
    var pickedObject = undefined
    var pickedObjects = undefined;
    
    try
    {
      pickedObject = this.viewer.scene.pick(Position);        // This can generate a RangeError: Invalid array length
      pickedObjects = this.viewer.scene.drillPick(Position);
    }
    catch (err)
    {
      //console.log(`[CesiumHandler:get_selected_obj_from_point] Mouse movement handler error: ${err.name}`);
      //console.dir(err);
      //console.dir(this.viewer.scene);
    }

    if (!Cesium.defined(pickedObject)) {
       valueToReturn = null;
    }
    else
    {
      valueToReturn = pickedObjects[0];
    }
    
    return valueToReturn;  
  }

  is_object_clickable(object)
  {
    if (Cesium.defined(object)) {
      var entityName = object.id?.object_id ? object.id.object_id : undefined;
      //console.log(`Object name: ${entityName}`);

      if (entityName)
      {
        let camera = PhysicalCameraManager.get_by_name(entityName);
        if (camera && camera.is_selectable())
        {
          return true;
        }
      }
    }
    return false;
  }

  change_label_color(selected_imagery_url)
  {

    if(!selected_imagery_url)
    {
      this.label_color = this.default_label_color;
      this.change_burger_button_color()

    }
    else
    {
      this.label_color = getMapLabelColor(selected_imagery_url,color_mapping) || this.default_label_color;
      this.change_burger_button_color("red")
    }

    if(PhysicalCameraManager.selected_camera)
      PhysicalCameraManager.update_camera(PhysicalCameraManager.selected_camera.name,PhysicalCameraManager.selected_camera);

  }
  change_burger_button_color (color=""){
    const burger_button  = $(".menu__buttons-open > svg > g > path");
    for(let i=0; i< burger_button.length; i++){
     burger_button[i].style.stroke = color;
    }
  }

  update()
  {
    this.t += Time.delta_time;

    if (this.t > this.current_animation_duraction)
    {
      this.can_fly = true;
    }
  }

  /**@function __set_target_position
   *
   * @description - This is the function that beeing called when the user press right click on the map.
   * It will display to the screen the lat,lng and the Rng metrics.
   */

  __set_target_position(e)
  {
    let canvas_rect = this.viewer.canvas.getBoundingClientRect();

    let mousePosition = new Cesium.Cartesian2(e.clientX - canvas_rect.left, e.clientY - canvas_rect.top);

    let ellipsoid = this.viewer.scene.globe.ellipsoid;
    let cartesian = this.viewer.camera.pickEllipsoid(mousePosition, ellipsoid);

    if (cartesian)
    {
      let cartographic = ellipsoid.cartesianToCartographic(cartesian);
      let lng = Cesium.Math.toDegrees(cartographic.longitude);
      let lat = Cesium.Math.toDegrees(cartographic.latitude);

      let alt = 0.0;

      if (this.app.camera_calibration_view.camera_calibration_target_active)
      {
        this.app.camera_calibration_view.set_target_location(lat, lng);
      }
      else
      {
        if (this.app.camera_calibration_view.camera_calibration_camera_active)
        {
          this.app.camera_calibration_view.set_camera_location(lat, lng);
        }
        else
        {
          if (PhysicalCameraManager.selected_camera)
          {
            PhysicalCameraManager.selected_camera.tgt_lon = parseFloat(lng.toFixed(7));
            PhysicalCameraManager.selected_camera.tgt_lat = parseFloat(lat.toFixed(7));
            PhysicalCameraManager.selected_camera.tgt_alt = parseFloat(alt.toFixed(7));

            // Set tgt name undefined to hide it on cesium label
            PhysicalCameraManager.selected_camera.tgt_name = undefined;

            CameraTargetUtilities.compute_and_set_camera_image_and_icon(PhysicalCameraManager.selected_camera);
            // PhysicalCameraManager.update_camera(PhysicalCameraManager.selected_camera.name, PhysicalCameraManager.selected_camera);

            if (PhysicalCameraManager.tandem_mode)
            {
              PhysicalCameraManager.selected_camera_tandem.tgt_lon = parseFloat(lng.toFixed(7));
              PhysicalCameraManager.selected_camera_tandem.tgt_lat = parseFloat(lat.toFixed(7));
              PhysicalCameraManager.selected_camera_tandem.tgt_alt = parseFloat(alt.toFixed(7));

              CameraTargetUtilities.compute_and_set_camera_image_and_icon(PhysicalCameraManager.selected_camera_tandem);
            }
          }
        }
      }
    }
  }

  lock_to_marker(target)
  {
    // console.log(`[CesiumHandler:lock_to_marker] [${target.text}]`);
    // console.dir(target);

    // Set the entity viewFrom attriubte to the default position we flyto so there is no jump in position after flight
    const offsetLength = 10;
    let length = target.map_zoom * 2 + offsetLength;
    let distance = length / Math.tan(this.viewer.camera.frustum.fov / 2);

    let radians = Cesium.Math.toRadians(target.yaw);
    let vDistance = 0;
    let viewFromPos = new Cesium.Cartesian3(-Math.sin(radians) * distance, -Math.cos(radians) * distance, vDistance);
    target.cesium_marker_tracked.viewFrom = viewFromPos;

    this.viewer.trackedEntity = target.cesium_marker_tracked;
  }

  unlock()
  {
    // console.log(`[CesiumHandler:unlock]`);
    // console.dir(this.viewer.trackedEntity);
    this.viewer.trackedEntity = undefined;
  }

  lock_to_plane(lock, target)
  {
    if (!lock)
    {
      this.custom_camera_movement.enableDisableScreenSpaceCameraController(true);
    }
    else
    {
      // Lock all manual map controls
      this.custom_camera_movement.enableDisableScreenSpaceCameraController(false);
    }
  }

  fly_to(lon, lat, alt, zoom)
  {
    if (this.can_fly)
    {
      // let position = Cesium.Cartesian3.fromDegrees(lon, lat, zoom);

      // this.viewer.camera.flyTo({
      //   destination: position
      //   // duration: this.current_animation_duraction,
      // });

      let camera = PhysicalCameraManager.selected_camera;

      console.log(`[CesiumHandler:fly_to] [${camera.name}] rpy: ${camera.roll}, ${camera.pitch}, ${camera.yaw}, alt: ${alt}`);

      this.custom_camera_movement.flyTo(
        lon,
        lat,
        // camera.default_map_marker_alt,
        alt,
        // 0,
        camera.yaw,
        // 0,
        camera.pitch,
        // 0,
        camera.roll,
        zoom
      );

      this.can_fly = false;
      this.t = 0;
    }
  }

  fly_to_target(lon, lat, zoom)
  {
    console.log(`[CesiumHandler:fly_to_target] [${this.name}] lon: ${lon}, lat: ${lat}, zoom: ${zoom}`);
    let position = Cesium.Cartesian3.fromDegrees(lon, lat, zoom);

    this.viewer.camera.flyTo({
      destination: position
    });
  }

  fly_to_camera(marker, camera, zoom)
  {
    console.log(`[CesiumHandler:fly_to_camera] [${camera.name}] rpy: ${marker.roll}, ${marker.pitch}, ${marker.yaw}, alt: ${marker.alt}`);

    if (this.can_fly)
    {
      this.custom_camera_movement.flyTo(
        camera.cam_lon,
        camera.cam_lat,
        // camera.cam_alt,
        marker.alt,
        marker.yaw,
        marker.roll,   // camera.pitch
        marker.pitch,    // camera.roll
        zoom
      );
    }
  }

  fly_to_marker(marker)
  {
    if (this.can_fly)
    {
      this.custom_camera_movement.flyTo(
        marker.lon,
        marker.lat,
        marker.alt,
        marker.yaw,
        marker.roll,
        marker.pitch,
        marker.map_zoom
      );
    }
  }

  fly_to_marker_direct(marker)
  {
    if (this.can_fly)
    {
      this.custom_camera_movement.flyToDirect(
        marker.lon,
        marker.lat,
        marker.alt,
        marker.yaw,
        marker.roll,
        marker.pitch,
        marker.map_zoom,
        marker,
        true
      );
    }
  }

  fly_to_cameras(cameras)
  {
    console.log('[fly_to_cameras]');

    // Longitude limits -180 to 180
    // Latitude limits -90 to 90
    let west = 180;
    let south = 90.0;
    let east = -180.0;
    let north = -90.0;

    // Get extreme positions for rectangle
    cameras.forEach(function (camera) {
      // console.log(`'${camera.name}' is_valid_non_zero_position: ${camera.is_valid_non_zero_position()}: cam_lat: ${camera.cam_lat}, cam_lon: ${camera.cam_lon}`);
      if (camera.is_valid_non_zero_position())
      {
        if (camera.cam_lat > north) north = camera.cam_lat;
        if (camera.cam_lat < south) south = camera.cam_lat;
        if (camera.cam_lon > east) east = camera.cam_lon;
        if (camera.cam_lon < west) west = camera.cam_lon;        
      }
    });

    // Add 10% extra to the sides so a camera doesn't show on the extremes of the view
    let span = east - west;

    // console.log(`[fly_to_cameras] span: ${span}`);
    // If span (zoom level) is too wide then bypass zooming to entities as it doesn't show well
    if (span > 90 || span < -90)
    {
      return;
    }
    // Set minimum zoom level
    if (span < 0.005)
    {
      span = 0.005;
    }
    let offset = span * 0.1;
    
    north += offset;
    south -= offset;
    east += offset;
    west -= offset;

    const rectangle = Cesium.Rectangle.fromDegrees(
      west,
      south,
      east,
      north
    );

    var flyOptions = {
      destination: rectangle,
      //duration: 6,
      pitchAdjustHeight: 100,
    };

    // Fly to position to include all entities in view
    this.fly_start_height = this.viewer.camera.positionCartographic.height;
    this.viewer.camera.flyTo(flyOptions);
  }

  set_view_to_plane(position, heading, pitch, roll, zoom, lock_all_axis)
  {
    // console.log(`[CesiumHandler:set_view_to_plane] roll: ${roll}, pitch: ${pitch}, yaw: ${heading}, lock_all_axis: ${lock_all_axis}`);
    // this.custom_camera_movement.setViewToPlane(position, heading, zoom);
    this.custom_camera_movement.setViewToPlane(position, heading, pitch, roll, zoom, lock_all_axis);

    this.can_fly = false;
    this.t = 0;
  }

  add_marker(payload)
  {
    // Sanitise values for Cesium
    [payload.lat,  payload.lon, payload.alt] = this.sanitise_coords(payload.lat, payload.lon, payload.alt);

    var position = Cesium.Cartesian3.fromDegrees(payload.lon, payload.lat, payload.alt);

    var heading = Cesium.Math.toRadians(0);
    var pitch = 0;
    var roll = 80;
    var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
    var orientation = Cesium.Transforms.headingPitchRollQuaternion(
      position,
      hpr
    );

    let data = {
      position: position,
      orientation: orientation,
      show: payload.show
    };

    let text_in_map = undefined;

    if (payload.text)
    {
      let defaultModelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position, undefined, new Cesium.Matrix4());
      let zAxisNormal = new Cesium.Cartesian3();

      zAxisNormal.x = defaultModelMatrix[8];
      zAxisNormal.y = defaultModelMatrix[9];
      zAxisNormal.z = defaultModelMatrix[10];

      // let extendedZAxisNormal = Cesium.Cartesian3.multiplyByScalar(zAxisNormal, 21, new Cesium.Cartesian3());
      let extendedZAxisNormal = Cesium.Cartesian3.multiplyByScalar(zAxisNormal, 12, new Cesium.Cartesian3());
      let labelPosition = Cesium.Cartesian3.add(position, extendedZAxisNormal, new Cesium.Cartesian3());

      text_in_map = this.viewer.entities.add({
        position: labelPosition,
        label: {
          text: payload.text,
          pixelOffset: new Cesium.Cartesian2(0, -30),
          font: '18px Helvetica',
          fillColor: this.label_color,
          outlineWidth: 2,
          style: Cesium.LabelStyle.FILL,
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0,60000.0),
        },
        minimumPixelSize: 128,
        maximumScale: 20000
      });
    }

    if (payload.target_text)
    {
      data.label = {
        text: payload.text,
        font: '18px Helvetica',
        style: Cesium.LabelStyle.FILL,
        outlineWidth: 2,
        fillColor: this.label_color,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        eyeOffset: new Cesium.Cartesian3(0.0, payload.eye_offset, 0.0),
        pixelOffset: new Cesium.Cartesian2(0, 30)
      };
    }

    let custom_3d_object = undefined;
    if (payload.model_url)
    {
      let modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);
      custom_3d_object = this.viewer.scene.primitives.add(Cesium.Model.fromGltf({
        url : payload.model_url,
        modelMatrix : modelMatrix,
        scale : payload.scale ? payload.scale * 0.01 : 0.1
      }));

      custom_3d_object.readyPromise.then(function(model) {
        // Play all animations when the model is ready to render
        model.activeAnimations.addAll({
          loop : Cesium.ModelAnimationLoop.REPEAT
        });
      });
    }

    let custom_sphere_in_map = undefined;
    if (payload.custom_sphere)
    {
      let custom_sphere = new CustomSphere(position, '', payload.opacity || 1, payload.is_inverted);
      let custom_sphere_primitive = custom_sphere.create_custom_mapping_sphere_primitive(payload.scale || 1, 40, 40);

      custom_sphere_in_map = this.viewer.scene.primitives.add(custom_sphere_primitive);
    }

    // Custom Plane
    let custom_plane_maker = undefined;
    let custom_plane_scale = undefined;
    let custom_plane_in_map = undefined;

    if (payload.custom_plane === true)
    {
      let custom_plane = new CustomPlane(position, '', payload.opacity || 1, payload.is_inverted);
      let custom_plane_primitive = custom_plane.create_custom_mapping_plane_primitive(payload.scale || 1, 16, 9);

      custom_plane_maker = custom_plane;
      custom_plane_scale = payload.scale;
      custom_plane_in_map = this.viewer.scene.primitives.add(custom_plane_primitive);
    }

    // data.ellipsoid = {
    //   radii: new Cesium.Cartesian3(payload.scale, payload.scale, payload.scale),
    //   material: new Cesium.ImageMaterialProperty({
    //     image: '',
    //     color: new Cesium.Color(1.0, 1.0, 1.0, payload.opacity),
    //     repeat: new Cesium.Cartesian2(1.0, 1.0)
    //   })
    // };

    if (payload.image_url)
    {
      data.billboard = {
        image: payload.image_url,
        width: 32,
        height: 32,
        color: this.label_color
      };
    }

    // Create a plain green point marker
    let marker_point_in_map = undefined;

    // Do this for cameras on all modes now
    if (payload.show_point)
    {
      console.log(`Add camera marker point lat: ${payload.lat}, lon: ${payload.lon}`)

      marker_point_in_map = this.viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(payload.lon, payload.lat),
        //position: position, // This increase marker altitude but we want it on the ground
        point: {
          show: true,
          color: Cesium.Color.fromCssColorString('#00FF82'),
          pixelSize: 10,
        },
        label: {
          text: payload.text,
          //pixelOffset: new Cesium.Cartesian2(0, -30),
          pixelOffset: new Cesium.Cartesian2(0, 30),
          font: '18px Helvetica',
          fillColor: this.label_color,
          outlineWidth: 2,
          style: Cesium.LabelStyle.FILL,
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0,60000.0),
        },
        minimumPixelSize: 128,
        maximumScale: 20000,
        show: false
      });
    }

    let custom_tracked_entity = undefined;

    if (payload.track)
    {
      custom_tracked_entity = this.viewer.entities.add({
        position: position,
        point: {
          pixelSize: 8,
          color: Cesium.Color.TRANSPARENT,
          //outlineColor: Cesium.Color.YELLOW,
          outlineColor: Cesium.Color.TRANSPARENT,
          outlineWidth: 3,
        }
      });

/*
      // Use primitive to keep visual update rendering consistent (NOTE: Cannot track Primitives only Entities)
      custom_tracked_entity = this.viewer.scene.primitives.add(new Cesium.PointPrimitiveCollection());
      custom_tracked_entity.add({
        position : position,
        pixelSize: 8,
        color: Cesium.Color.TRANSPARENT,
        //outlineColor: Cesium.Color.YELLOW,
        outlineColor: Cesium.Color.TRANSPARENT,
        outlineWidth: 3,
      });
*/
    }

    return {
      marker: this.viewer.entities.add(data),
      marker_sphere: custom_sphere_in_map,
      marker_plane: custom_plane_in_map,
      marker_plane_maker: custom_plane_maker,
      marker_plane_scale: custom_plane_scale,
      marker_tracked: custom_tracked_entity,
      marker_custom_3d_object: custom_3d_object,
      marker_point: marker_point_in_map,
      text: text_in_map
    };
  }

  add_ground_arrow(payload)
  {
    // Sanitise values for Cesium
    [payload.lat,  payload.lon, payload.alt] = this.sanitise_coords(payload.lat, payload.lon, payload.alt);

    let groundNormalVectorEndPosition = Cesium.Cartesian3.fromDegrees(
      payload.lon,
      payload.lat,
      0.0
    );

    let groundNormalVectorStartPosition = Cesium.Cartesian3.fromDegrees(
      payload.lon,
      payload.lat,
      (payload.alt - 4.5)
    );

    let theGroundArrowPositions = [groundNormalVectorStartPosition, groundNormalVectorEndPosition];

    // ground normal arrow
    // Use primitive to keep visual update rendering consistent
    const polylines = new Cesium.PolylineCollection();
    polylines.add({
      positions: theGroundArrowPositions,
      width: 10,
      //arcType: Cesium.ArcType.NONE,
      //material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.LAVENDER)
      material: new Cesium.Material.fromType("PolylineArrow", {
        color: Cesium.Color.LAVENDER
      })
    });
    let arrow = this.viewer.scene.primitives.add(polylines);

    return arrow;
  }

  add_ground_text(payload)
  {
    // Sanitise values for Cesium
    [payload.lat,  payload.lon, payload.alt] = this.sanitise_coords(payload.lat, payload.lon, payload.alt);

    const defaultPlanePosition = Cesium.Cartesian3.fromDegrees(payload.lon, payload.lat, payload.alt);
    let thePlanePosition = defaultPlanePosition.clone();
    let theLabelPosition = thePlanePosition.clone();

    // ground normal arrow label
    return this.viewer.entities.add({
      // position : new Cesium.CallbackProperty(labelPosition, false),
      position: theLabelPosition,
      label: {
        text: 'test text'
      }
    });
  }

  add_camera_arrow(payload)
  {
    // Sanitise values for Cesium
    [payload.lat,  payload.lon, payload.alt] = this.sanitise_coords(payload.lat, payload.lon, payload.alt);

    const defaultPlanePosition = Cesium.Cartesian3.fromDegrees(payload.lon, payload.lat, payload.alt);
    let thePlanePosition = defaultPlanePosition.clone();
    let thePlaneNormalArrowPositions = [thePlanePosition, thePlanePosition];

    // plane normal vector arrow
    // Use primitive to keep visual update rendering consistent
    const polylines = new Cesium.PolylineCollection();
    polylines.add({
      positions: thePlaneNormalArrowPositions,
      width: 10,
      //arcType: Cesium.ArcType.NONE,
      //material: new Cesium.PolylineArrowMaterialProperty(Cesium.Color.RED)
      material: new Cesium.Material.fromType("PolylineArrow", {
        color: Cesium.Color.RED
      })
    });
    let arrow = this.viewer.scene.primitives.add(polylines);

    return arrow;
  }

  add_image_marker(payload)
  {
    // Sanitise values for Cesium
    [payload.lat,  payload.lon, payload.alt] = this.sanitise_coords(payload.lat, payload.lon, payload.alt);

    var position = Cesium.Cartesian3.fromDegrees(payload.lon, payload.lat, payload.alt);

    var heading = Cesium.Math.toRadians(0);
    var pitch = 0;
    var roll = 80;
    var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
    var orientation = Cesium.Transforms.headingPitchRollQuaternion(
      position,
      hpr
    );

    let data = {
      position: position,
      orientation: orientation,
      show: payload.show
    };


    //custom_sphere_in_map = this.viewer.scene.primitives.add(custom_sphere_primitive);


    let primitive_rectangle = new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.Geometry({
          attributes: {
            position: position,
            normal: normal,
            st: st
          },
          indices: new Uint16Array(sphereGeometry.index),
          primitiveType: Cesium.PrimitiveType.TRIANGLES,
          boundingSphere: Cesium.BoundingSphere.fromVertices(sphereGeometry.position)
        })
      }),
      appearance: new Cesium.MaterialAppearance({
        material: material,
        closed: true
      }),
      asynchronous: false
    });

    this.spherePrimitive.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(this.spherePosition);



    let primitive_rectangle2 = this.viewer.scene.primitives.add(
      new Cesium.Primitive({
        geometryInstances: new Cesium.GeometryInstance({
          geometry: new Cesium.RectangleGeometry({
            rectangle: Cesium.Rectangle.fromDegrees(
              -120.0,
              20.0,
              -60.0,
              40.0
            ),
            vertexFormat: Cesium.EllipsoidSurfaceAppearance.VERTEX_FORMAT,
          }),
        }),
        appearance: new Cesium.EllipsoidSurfaceAppearance({
          aboveGround: false,
        }),
      })
    );

    primitive.appearance.material = new Cesium.Material({
      fabric: {
        type: "Image",
        uniforms: {
          image: "../images/Cesium_Logo_Color.jpg",
        },
      },
    });

    return primitive_rectangle;
  }

  select_bing_aerial_map()
  {
    // Manually set layer
    //var viewer = new Cesium.Viewer('cesiumContainer', {
    //imageryProviderViewModels : providerViewModels,
    //selectedImageryProviderViewModel : providerViewModels[1],
    //this.viewer.selectedImageryProviderViewModel = providerViewModels[1];

    let bing_aerial_button = $('.cesium-baseLayerPicker-item')[0];

    if (bing_aerial_button)
    {
      // Select Bing Aerial imagery provider
      bing_aerial_button.click();
    }
  }

  enable_osm_buildings()
  {
    this.osm_buildings_tileset = Cesium.createOsmBuildings();
    this.viewer.scene.primitives.add(this.osm_buildings_tileset);
  }

  disable_osm_buildings()
  {
    this.viewer.scene.primitives.remove(this.osm_buildings_tileset);
  }

  // Make sure coordinate values are valid for Cesium functions else you can get internal errors that are difficult to debug. 
  sanitise_coords(lat, lon, alt)
  {
    if (lon === undefined) lon = 0.0;
    if (lat === undefined) lat = 0.0;
    if (alt === undefined) alt = 0.0;

    if (!Validation.is_float(lon)) lon = isNaN(parseFloat(lon)) ? 0.0 : parseFloat(lon);
    if (!Validation.is_float(lat)) lat = isNaN(parseFloat(lat)) ? 0.0 : parseFloat(lat);
    if (!Validation.is_float(alt)) alt = isNaN(parseFloat(alt)) ? 0.0 : parseFloat(alt);

    return [lat, lon, alt];
  }
}

export default new CesiumHandler();
