/* global Keycloak */

import DefenderAdminRole from './user_roles/DefenderAdminRole';
import CustAdminRole from './user_roles/CustAdminRole';
import CustOperatorRole from './user_roles/CustOperatorRole';
import CustUserRole from './user_roles/CustUserRole';
import CustLiveRole from './user_roles/CustLiveRole';
import CustLiveTagRole from './user_roles/CustLiveTagRole';
import CustLiveRecRole from './user_roles/CustLiveRecRole';
import CustLiveTagRecRole from './user_roles/CustLiveTagRecRole';
import CustLiveTagArcRole from './user_roles/CustLiveTagArcRole';
import CustRecViewerRole from './user_roles/CustRecViewerRole';
import CustArchiveRole from './user_roles/CustArchiveRole';
import CustRecArchiveRole from './user_roles/CustRecArchiveRole';
import CustWebRTCAdminRole from './user_roles/CustWebRTCAdminRole';
import CustWebRTCViewerRole from './user_roles/CustWebRTCViewerRole';
import CustSuboperator from './user_roles/CustSuboperator';


// This handles the Authorization and Roles of logged user
class RoleManager
{
  constructor()
  {
    this.bearer_token = undefined;
    this.keycloak = undefined;
    this.current_user = undefined;

    this.initialized = false;
    this.authenticated = false;
    this.config = {};

    this.roles = {
      DEFENDER_ADMIN: 'defender_admin',
      CUST_ADMIN: 'cust_admin',
      CUST_OPERATOR: 'cust_operator',
      CUST_USER: 'cust_user',
      CUST_LIVE: 'cust_live',
      CUST_LIVE_TAG: 'cust_live_tag',
      CUST_LIVE_REC: 'cust_live_rec',
      CUST_LIVE_TAG_REC: 'cust_live_tag_rec',
      CUST_LIVE_TAG_ARC: 'cust_live_tag_arc',
      CUST_REC_VIEWER: 'cust_rec_viewer',
      CUST_ARCHIVE_VIEWER: 'cust_archive_viewer',
      CUST_REC_ARCHIVE: 'cust_rec_archive',
      CUST_BW_USER: 'cust_bodyworn_user',
      CUST_BW_ADMIN: 'cust_bodyworn_admin',
      CUST_WEBRTC_VIEWER: 'cust_webrtc_viewer',
      CUST_WEBRTC_ADMIN: 'cust_webrtc_admin',
      CUST_SUBOPERATOR: 'cust_suboperator'
    };
  }

  init()
  {
    // section below sets up and makes call to Keycloak using the config file
    this.keycloak = new Keycloak('./data/keycloak.json');

    // Comment this line to get it working locally
    //this.keycloak.init({ onLoad: 'login-required', flow: 'hybrid' })
    this.keycloak.init({ onLoad: 'login-required', flow: 'hybrid', checkLoginIframe: false})
      .then(this.__on_authenticated.bind(this))
      .catch(this.__on_error.bind(this));

    this.keycloak.onTokenExpired = this.__on_token_expired.bind(this);
    this.keycloak.onAuthRefreshSuccess = this.on_auth_refresh_success.bind(this);
    this.keycloak.onAuthRefreshError = this.__on_AuthRefreshError.bind(this);


    // Mockup users and roles to test locally
    // this.initialized = true;
    // this.authenticated = true;
    // this.bearer_token = 'fake';
    // this.logged_user = { groups: ['customer_1', 'customer_2'] };

    // this.user_role = new CustOperatorRole(this.roles.CUST_OPERATOR);
    // this.user_role = new CustRecViewerRole(this.roles.CUST_REC_VIEWER);
    // this.user_role = new CustRecArchiveRole(this.roles.CUST_ARCHIVE_VIEWER);
    // this.user_role = new DefenderAdminRole(this.roles.DEFENDER_ADMIN);
  }

  configure_app()
  {
    this.user_role.configure_app();
  }

  logout()
  {
    this.keycloak.logout();
  }

  get_auth_header() 
  {
    let headers = {
      'Content-Type' : 'application/json',
      'Authorization': 'Bearer ' + this.bearer_token
    };
    return headers;
  }

  // Test only. Not used in code
  force_token_update()
  {
    console.log('Force token to refresh and update');
    this.keycloak.updateToken(-1)
      .then(this.__token_updated.bind(this))
      .catch(this.__on_error.bind(this));
  }

  on_auth_refresh_success()
  {
    console.log('Keycloak token refreshed successfully. Update local copy of token.');
    this.__on_authenticated(true);
  }

  // TODO: Review the order of the roles are these is important. Should have higher levels first else it will give lower rights one first
  __on_authenticated(authenticated)
  {
    console.log('Authenticated?', authenticated);

    if (authenticated)
    {
      this.bearer_token = this.keycloak.token;
      this.logged_user = this.keycloak.tokenParsed;
console.dir(this);

      this.__retrieveUserConfigFromToken(this.logged_user);

      if (this.keycloak.hasRealmRole(this.roles.DEFENDER_ADMIN))
      {
        this.authenticated = true;
        this.user_role = new DefenderAdminRole(this.roles.DEFENDER_ADMIN);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_ADMIN))
      {
        this.authenticated = true;
        this.user_role = new CustAdminRole(this.roles.CUST_ADMIN);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_LIVE_TAG_ARC))
      {
        this.authenticated = true;
        this.user_role = new CustLiveTagArcRole(this.roles.CUST_LIVE_TAG_ARC);
      }

      else if(this.keycloak.hasRealmRole(this.roles.CUST_SUBOPERATOR))
      {
        this.authenticated = true;
        this.user_role = new CustSuboperator(this.roles.CUST_SUBOPERATOR);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_OPERATOR))
      {
        this.authenticated = true;
        this.user_role = new CustOperatorRole(this.roles.CUST_OPERATOR);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_USER))
      {
        this.authenticated = true;
        this.user_role = new CustUserRole(this.roles.CUST_USER);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_LIVE))
      {
        this.authenticated = true;
        this.user_role = new CustLiveRole(this.roles.CUST_LIVE);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_LIVE_TAG))
      {
        this.authenticated = true;
        this.user_role = new CustLiveTagRole(this.roles.CUST_LIVE_TAG);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_LIVE_REC))
      {
        this.authenticated = true;
        this.user_role = new CustLiveRecRole(this.roles.CUST_LIVE_REC);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_LIVE_TAG_REC))
      {
        this.authenticated = true;
        this.user_role = new CustLiveTagRecRole(this.roles.CUST_LIVE_TAG_REC);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_REC_VIEWER))
      {
        this.authenticated = true;
        this.user_role = new CustRecViewerRole(this.roles.CUST_REC_VIEWER);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_ARCHIVE_VIEWER))
      {
        this.authenticated = true;
        this.user_role = new CustArchiveRole(this.roles.CUST_ARCHIVE_VIEWER);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_REC_ARCHIVE))
      {
        this.authenticated = true;
        this.user_role = new CustRecArchiveRole(this.roles.CUST_REC_ARCHIVE);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_WEBRTC_VIEWER))
      {
        this.authenticated = true;
        this.user_role = new CustWebRTCViewerRole(this.roles.CUST_WEBRTC_VIEWER);
      }

      else if (this.keycloak.hasRealmRole(this.roles.CUST_WEBRTC_ADMIN))
      {
        this.authenticated = true;
        this.user_role = new CustWebRTCAdminRole(this.roles.CUST_WEBRTC_ADMIN);
      }
   
      else if (this.keycloak.hasRealmRole(this.roles.CUST_BW_USER))
      {
        this.authenticated = true;
        this.user_role = new CustBWUserRole(this.roles.CUST_BW_USER);
      }
   
       else if (this.keycloak.hasRealmRole(this.roles.CUST_BW_ADMIN))
      {
        this.authenticated = true;
        this.user_role = new CustBWAdminRole(this.roles.CUST_BW_ADMIN);
      }
    }

    this.initialized = true;
  }

  __retrieveUserConfigFromToken(token)
  {
    const config_keys = [
      'cameras_websocket_ip',
      'live_telemetry_websocket_host',
      'archive_telemetry_websocket_host',
      'recording_telemetry_websocket_host',
      'x_rotation_speed',
      'recordings_api_host',
      'cesium_token',
      'y_rotation_speed',
      'target_telemetry_websocket_host',
      'cameras_api_ip'];

    /**
       * creates a new object by mapping the keys of config_keys with those in token object.
       * The value of each key is the value of the mapped token key value.
       */

    try
    {
      this.config = config_keys.reduce((configObject,currentKey)=>
      {
        configObject[currentKey] = token[currentKey];
        return configObject;
      },{});
    }
    catch(err)
    {
      console.log(err);
    }



  }

  __on_token_expired()
  {
    console.log('token expired, updating it');
    this.keycloak.updateToken(3000)
      .then(this.__token_updated.bind(this))
      .catch(this.__on_error.bind(this));
  }

  __token_updated(refreshed)
  {
    console.log('[RoleManager:__token_updated]');
    this.bearer_token = this.keycloak.token;
    if (refreshed)
    {
      console.log('Token was successfully refreshed');
    }
    else
    {
      console.log('Token is still valid');
    }
  }

  __on_error(error)
  {
    this.initialized = true;
    console.log('failed to initialize', error);
  }
  __on_AuthRefreshError()
  {
    this.logout();
  }
}

export default new RoleManager();
