import { MathUtilities } from 'ohzi-core';

import Fragment from './Fragment';

// This class manages the single-day recordings fragments of a camera.

/**
 * @class FragmentManager
 * @function addFragment() - Adds the fragment to the playback bar, it also give the blue color to it.
 */
export default class FragmentManager
{
  constructor(html_bar_container, html_fragment_container)
  {
    this.fragments = [];
    this.current_fragment_playing = undefined;
    this.html_bar_container = html_bar_container;
    this.html_fragment_container = html_fragment_container;
  }

  add_fragment(recording, start_ms, end_ms)
  {
    let fragment_elem = document.createElement('div');
    fragment_elem.className = `player__playback-fragments-fragment player__playback-fragments-fragment--${(this.fragments.length % 4) + 1}`;

    let fragment = new Fragment(
      recording,
      start_ms,
      end_ms,
      fragment_elem
    );

    fragment.set_transform(this.__ms_to_px(start_ms, start_ms, end_ms), this.__ms_to_px(end_ms, start_ms, end_ms));

    this.fragments.push(fragment);
    this.html_fragment_container.append(fragment_elem);

    return fragment;
  }

  clear_fragments()
  {
    this.html_fragment_container[0].innerHTML = '';
    this.fragments.length = 0;
  }

  get_fragment_containing(ms)
  {
    // console.log('[FragmentManager.get_fragment_containing]');
    // console.dir(this);
    for (let i = 0; i < this.fragments.length; i++)
    {
      console.log({length:this.fragments.length});
      if (this.fragments[i].contains_ms(ms))
      {
        return this.fragments[i];
      }
    }
    return undefined;
  }

  __get_container_width()
  {
    return this.html_bar_container[0].getBoundingClientRect().width;
  }

  __ms_to_px(ms, start_ms, end_ms)
  {
    // The linear map function maps a value x from range A to range B
    return MathUtilities.linear_map(ms, start_ms, end_ms, 0, this.__get_container_width());
  }

  get_first_fragment()
  {
    let min_start_ms = Number.MAX_SAFE_INTEGER;
    let first_fragment;

    for (let i = 0; i < this.fragments.length; i++)
    {
      if (this.fragments[i].start_ms < min_start_ms)
      {
        first_fragment = this.fragments[i];
        min_start_ms = this.fragments[i].start_ms;
      }
    }

    return first_fragment;
  }

  update_fragments_in_range(start_ms, end_ms)
  {
    for (let i = 0; i < this.fragments.length; i++)
    {
      let fragment = this.fragments[i];

      let frag_start_ms = THREE.Math.clamp(fragment.start_ms, start_ms, end_ms);
      let frag_end_ms = THREE.Math.clamp(fragment.end_ms, start_ms, end_ms);

      let start_px = this.__ms_to_px(frag_start_ms, start_ms, end_ms);
      let end_px = this.__ms_to_px(frag_end_ms, start_ms, end_ms);

      fragment.set_transform(start_px, end_px, this.__get_container_width());

      fragment.set_transform(
        this.__ms_to_px(fragment.start_ms, start_ms, end_ms),
        this.__ms_to_px(fragment.end_ms, start_ms, end_ms),
        this.__get_container_width()
      );
    }
  }

  get_current_fragment_playing()
  {
    return this.current_fragment_playing;
  }

  set_current_fragment_playing(current_fragment_playing)
  {
    this.current_fragment_playing = current_fragment_playing;
  }

  on_resize()
  {
  }
}
