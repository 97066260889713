import {BaseShaderMaterial} from 'ohzi-core';
import frag from '/shaders/fisheye_debug/fisheye_debug.frag';
import vert from '/shaders/fisheye_debug/fisheye_debug.vert';

export default class FisheyeDebugMaterial extends BaseShaderMaterial
{
  constructor()
  {
    super(vert, frag,{
      _MainTex: {value: undefined},
      _DebugTex: {value: undefined}
    });
  }

  set_main_tex(tex)
  {
    this.uniforms._MainTex.value = tex;
    this.uniforms._MainTex.value.generateMipmaps = false;
    this.uniforms._MainTex.value.minFilter = THREE.LinearFilter;
  }
  set_debug_tex(tex)
  {
    this.uniforms._DebugTex.value = tex;
  }
}