
import Recording from './Recording';
import TimeUtilities from './TimeUtilities';

/* global moment */

// This class manages the single-day recordings of a camera.
export default class RecordingManager
{
  constructor()
  {
    this.recordings = [];
  }

  // This function receives the raw recording range of a camera and splits them
  // into single days for easier management
  add_recording_date_range(recording_id, start_date, end_date, file_name, encodedurl, downloadurl)
  {
    let days = TimeUtilities.get_days_between(start_date, end_date);
    //let timezone = moment.parseZone(start_date);
    let timezone = moment.parseZone(start_date, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:s']);

    if (timezone)
    {
      timezone = timezone.format('Z');
    }

    let offset_in_seconds_from_start = 0;
    for (let i = 0; i < days.length; i++)
    {
      let rec = new Recording(recording_id, offset_in_seconds_from_start, days[i], file_name, timezone, encodedurl, downloadurl);
      this.recordings.push(rec);
      offset_in_seconds_from_start += rec.duration_in_seconds_from_start;
    }
  }

  get_recordings_on_date(date)
  {
    let recordings = [];

    for (let i = 0; i < this.recordings.length; i++)
    {
      //console.dir(this.recordings[i]);
      if (this.recordings[i].is_on_date(date))
      {
        recordings.push(this.recordings[i]);
      }
    }

    return recordings;
  }

  clear()
  {
    this.recordings.length = 0;
  }
}
