/* global IMU, update_tracking, update_reverse_tracking */

import PhysicalCameraManager from './PhysicalCameraManager';
import { Validation } from 'ohzi-core';

/**
 * @class CameraTargetUtilities
 * @description - This class handles the effects of right click on the map or the double click at the player view.
 */

class CameraTargetUtilities
{
  // From Cesium map coordinates to Webgl camera pan and tilt
  compute_and_set_camera_image_and_icon(camera)
  {
    if (this.is_valid_position(camera.tgt_lat, camera.tgt_lon, camera.tgt_alt))
    {
      // Inputs
      let cam_loc = new Location(camera.cam_lat, camera.cam_lon, camera.cam_alt);
      let cam_imu = new IMU(camera.roll, camera.pitch, camera.yaw); // camera.roll, camera.pitch, camera.yaw

      let tgt_lat = camera.tgt_lat;
      let tgt_lon = camera.tgt_lon;
      let tgt_alt = camera.tgt_alt;

      let target_loc = new Location(tgt_lat, tgt_lon, tgt_alt);

      //console.log(`[compute_and_set_camera_image_and_icon] Inputs:, cam_loc, cam_imu, target_loc`);
      //console.dir(cam_loc);
      //console.dir(cam_imu);
      //console.dir(target_loc);
      //console.log(`[compute_and_set_camera_image_and_icon] ${camera.name}: lat: ${camera.tgt_lat}, lon, ${camera.tgt_lon}, alt: ${camera.tgt_alt}`);
 
      // Calculated results
      let icon_pan = 0.0;
      let icon_tilt = 0.0;
      let distance = 0.0;

      // Perform tracker calculation
      [icon_pan, icon_tilt, distance] = update_tracking(target_loc, cam_loc, cam_imu);

      // console.log('Outputs');
      // console.log('Calculated to Target - Pan: ', icon_pan.toFixed(2), ', Tilt: ',
      //   icon_tilt.toFixed(2), ', Distance: ', distance.toFixed(2));

      camera.icon_pan = parseFloat(icon_pan.toFixed(2));
      camera.icon_tilt = parseFloat(icon_tilt.toFixed(2));
      camera.image_pan = parseFloat(icon_pan.toFixed(2));
      camera.image_tilt = parseFloat(icon_tilt.toFixed(2));

      camera.tgt_distance = parseFloat(distance.toFixed(2));

      //console.log(`[compute_and_set_camera_image_and_icon] ${camera.name}: pan: ${camera.image_pan}, tilt, ${camera.image_tilt}, dist: ${camera.tgt_distance}`);
 
      PhysicalCameraManager.update_camera(camera.name, camera);
    }
  }

  // From Webgl camera pan and tilt to Cesium map coordinates
  compute_and_set_map_marker_target_position(camera)
  {
    let cam_loc = new Location(camera.cam_lat, camera.cam_lon, camera.cam_alt);

    let cam_imu = new IMU(camera.roll, camera.pitch, camera.yaw); // camera.roll, camera.pitch, camera.yaw

    let cam_agl = parseFloat(camera.cam_alt);
    let icon_pan = parseFloat(camera.icon_pan);
    let icon_tilt = parseFloat(camera.icon_tilt);

    // Perform Reverse Tracker Calculation. Results to be processd in process_reverse_tracking() function
    let target_loc = update_reverse_tracking(cam_loc, cam_imu, cam_agl, icon_pan, icon_tilt);

    // let clamped_alt = THREE.Math.clamp(parseFloat(target_loc.alt), 0.1, Number.MAX_SAFE_INTEGER);

    camera.tgt_lat = parseFloat(target_loc.lat.toFixed(7));
    camera.tgt_lon = parseFloat(target_loc.lon.toFixed(7));
    camera.tgt_alt = parseFloat(target_loc.alt.toFixed(7));

    // Set tgt name and distance undefined to hide them on cesium label
    camera.tgt_name = undefined;
    camera.tgt_distance = undefined;

    PhysicalCameraManager.update_camera(camera.name, camera, true);
  }

  compute_distance_between_camera_and_marker(camera, marker)
  {
    let camera_loc = new Location(camera.cam_lat, camera.cam_lon, camera.cam_alt);
    let marker_loc = new Location(marker.lat, marker.lon, marker.alt);

    //console.log(`compute_distance_between_camera_and_marker`);
    //console.dir(camera_loc);
    //console.dir(marker_loc);

    // Calculate distance from camera to target marker
    let distance = get_distance(camera_loc, marker_loc);       // Excludes altitide difference
  
    return distance;
  }

  is_valid_position(lon, lat, alt)
  {
    return !!((Validation.is_int(lon) || Validation.is_float(lon)) &&
      (Validation.is_int(lat) || Validation.is_float(lat)) &&
      (Validation.is_int(alt) || Validation.is_float(alt)));
  }

  is_valid_non_zero_position(lon, lat, alt)
  {
    return (this.is_valid_position(lon, lat, alt) && lon !== 0 && lat !== 0);
  }
}

export default new CameraTargetUtilities();
