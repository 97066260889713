import MenuCameraStatusState from './menu_camera_status_states/MenuCameraStatusState';
import BootingUp from './menu_camera_status_states/BootingUp';
export default class MenuCameraStatus
{
  constructor(menu_camera)
  {
    this.menu_camera = menu_camera;
    this.stream = menu_camera.stream;
    this.current_state = new MenuCameraStatusState();
    this.set_state(new BootingUp());
  }

  config_updated()
  {
    this.current_state.on_config_received(this);
  }

  set_rec_icon_color(color)
  {
    this.menu_camera.set_rec_icon_color(color);
  }

  set_state(new_state)
  {
    this.current_state.on_exit(this);
    this.current_state = new_state;
    this.current_state.on_enter(this);
  }

  update()
  {
    this.current_state.update(this);
  }
}
