// This represents a fragmented telemetry.
export default class RecordingTelemetry
{
  constructor(id, offset_in_seconds_from_absolute_start, day, file_name, timezone)
  {
    this.id = id;
    this.file_name = file_name;
    this.offset_in_seconds_from_absolute_start = offset_in_seconds_from_absolute_start;

    this.start_date                     = day.start;
    this.end_date                       = day.end;
    this.timezone                       = timezone;
    this.duration_in_seconds_from_start = day.duration_in_seconds;

    this.absolute_start_date = day.absolute_start_date;
    this.absolute_end_date = day.absolute_end_date;
  }

  is_on_date(date)
  {
    return this.start_date.date() === date.getDate() &&
           this.start_date.month() === date.getMonth() &&
           this.start_date.year() === date.getFullYear();
  }

  get_local_start_date_in_ms()
  {
    return this.start_date.hours()   * 60 * 60 * 1000 +
           this.start_date.minutes() * 60 * 1000 +
           this.start_date.seconds() * 1000;
  }
}
