import HTMLVideo from '/js/components/html_components/common/HTMLVideo';
import HTMLCanvas from '/js/components/html_components/common/HTMLCanvas';
import WebRtcStream from '/js/components/WebRtcStream';
import { Configuration } from 'ohzi-core';

import MenuCameraStatus from './MenuCameraStatus';

// This class stores the PhysicalCamera and WebRTC stream of a camera in the menu

/**
 * @class MenuCameraWebRtc
 * @description This is the class to be called to pouplate the camera view ui with Thumbnail,camera name, etc...
 */
export default class MenuCameraWebRtc
{
  constructor(physical_camera, menu_camera_element)
  {
    this.physical_camera = physical_camera;
    // use the getter to access this
    this.__html_element = menu_camera_element;
    this.__html_element[0].setAttribute('onclick', 'app.menu_view.select_camera(this, event)');
    this.__html_element[0].setAttribute('data-name', physical_camera.name);

    //this.stream = new AxisCameraStream(this.create_html_video_element());
    this.stream = new WebRtcStream(this.create_html_video_element(), physical_camera.stream.stream);
    this.stream.set_profile('thumb');
    this.stream.set_endpoint(this.physical_camera);

    let video_container = this.__html_element.find('.menu__cameras-camera-image-video-container')[0];
    this.stream.html_video.append_to(video_container);

    this.__html_element.find('.menu__cameras-camera-title').text(physical_camera.name);
    this.rec_icon_circle = this.__html_element.find('.menu__cameras-camera-rec-icon').find('circle');
    
    // Assign action to switch
    // TODO: Change tandem and map_view switch to be hidden by default. Then add __show_elem to enable the switch if required
    this.tandem_switch_el = menu_camera_element.find('.menu__cameras-camera-tandem');
    this.tandem_switch = physical_camera.tandem_enabled;
   
    this.tandem_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_tandem_mode(this, event)');
    this.tandem_switch_el[0].setAttribute('data-name', physical_camera.name);
    
    //console.log(`[MenuCameraWebRtc] Camera '${physical_camera.name}' Lens: ${physical_camera.lens_type} Type: ${physical_camera.type}`);
      
    this.__hide_elem(this.tandem_switch_el);
    
    // Assign action to map_view switch
    this.map_view_switch_el = menu_camera_element.find('.menu__cameras-camera-map_view');
    this.map_view_switch = physical_camera.tandem_enabled;
   
    // Disable 'map view' feature for production release
    //this.map_view_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_map_view_mode(this, event)');
    //this.map_view_switch_el[0].setAttribute('data-name', physical_camera.name);

    this.remote_stream_el = menu_camera_element.find('.menu__cameras-remote_stream');
    this.remote_stream_start_el = menu_camera_element.find('.menu__cameras-remote_stream__play-button');
    this.remote_stream_stop_el = menu_camera_element.find('.menu__cameras-remote_stream__pause-button');
    this.remote_stream_switch_el = menu_camera_element.find('.menu__cameras-remote_stream__switch-button');

    this.remote_stream_start_el[0].setAttribute('onclick', 'app.menu_view.toggle_remote_stream(this, event, true)');
    this.remote_stream_start_el[0].setAttribute('data-name', physical_camera.name);

    this.remote_stream_stop_el[0].setAttribute('onclick', 'app.menu_view.toggle_remote_stream(this, event, false)');
    this.remote_stream_stop_el[0].setAttribute('data-name', physical_camera.name);

    this.remote_stream_switch_el[0].setAttribute('onclick', 'app.menu_view.toggle_remote_switch(this, event)');
    this.remote_stream_switch_el[0].setAttribute('data-name', physical_camera.name);
   
    this.remote_stream_battery_el = menu_camera_element.find('.menu__cameras-remote_stream__battery');
    this.remote_stream_battery_text_el = menu_camera_element.find('.menu__cameras-remote_stream__battery-text');

    //if (physical_camera.cam_type !== 'phone' && physical_camera.orientation !== 'mobile') {
    this.__hide_elem(this.map_view_switch_el);
    //}
    
    this.streaming_state = false;

    this.status = new MenuCameraStatus(this);
  }

  set_rec_icon_color(color)
  {
    this.rec_icon_circle.css('fill', color);
  }

  set_streaming_state(state)
  {
    this.streaming_state = state;

    if (this.streaming_state)
    {
      this.__show_elem(this.remote_stream_stop_el);
      this.__show_elem(this.remote_stream_switch_el);
      this.__hide_elem(this.remote_stream_start_el);
      this.remote_stream_switch_el.removeClass('active');
    }
    else
    {
      this.__show_elem(this.remote_stream_start_el);
      this.__hide_elem(this.remote_stream_stop_el);
      this.__hide_elem(this.remote_stream_switch_el);
      this.remote_stream_switch_el.removeClass('active');
    }
  }

  toggle_camera_switch()
  {
    if (this.remote_stream_switch_el[0].classList.contains('active'))
    {
      this.remote_stream_switch_el.removeClass('active');
    }
    else
    {
      this.remote_stream_switch_el.addClass('active');
    }
  }

  set_battery_value(value)
  {
    if (value) {
      this.remote_stream_battery_text_el[0].innerHTML = `${value}%`;
    }
  }

  create_html_video_element()
  {
    let html_video = undefined;
    let video_class_name = 'menu__cameras-camera-image-video';
    if (Configuration.is_ios)
    {
      html_video = new HTMLCanvas(video_class_name);
    }
    else
    {
      html_video = new HTMLVideo(video_class_name, true);
    }
    return html_video;
  }

  // Called by MenuView
  start_streaming(should_freeze_after_start)
  {
    let callback = should_freeze_after_start ? this.__freeze_image.bind(this) : undefined;
    //let callback = undefined;
    this.stream.start_streaming(callback);
  }

  play()
  {
    this.stream.play();
  }

  pause()
  {
    this.stream.pause();
  }

  restart()
  {
    if(this.is_playing || this.is_is_trying_to_connect) return;
    this.stop();
    this.start_streaming();

  }

  stop()
  {
    this.stream.stop();
  }

  config_updated()
  {
    this.status.config_updated(this);
  }

  append_to(container)
  {
    container.append(this.__html_element);
  }

  update()
  {
    this.status.update(this);

    if (this.physical_camera.bodyworn_available)
    {
      this.__show_elem(this.remote_stream_el);
      this.__show_elem(this.remote_stream_battery_el);
    }
    else
    {
      this.__hide_elem(this.remote_stream_el);
      this.__hide_elem(this.remote_stream_switch_el);
      this.__hide_elem(this.remote_stream_battery_el);
    }
  }
  
  show()
  {
    this.__show_elem(this.__html_element);
  }

  hide()
  {
    this.__hide_elem(this.__html_element);
  }

  has_remote_control()
  {
    return true;
  }

  get_remote_switch_state()
  {
    return this.remote_stream_switch_el[0].classList.contains('active');
  }

  __freeze_image()
  {
    this.stream.pause();
    this.stream.__stop_video_pipeline();
  }

  __toggle_switch_elem(elem, should_activate)
  {
    if (should_activate)
    {
      $(elem).addClass('active');
    }
    else
    {
      $(elem).removeClass('active');
    }
  }

  __hide_elem(elem)
  {
    $(elem).addClass('hidden');
  }

  __show_elem(elem)
  {
    $(elem).removeClass('hidden');
  }
}
