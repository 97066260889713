import { Input } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import PlayerViewMode from './PlayerViewMode';
import DetailedRecordingMode from './DetailedRecordingMode';
import TimeUtilities from '../../TimeUtilities';
import PhysicalCameraManager from '../../PhysicalCameraManager';

// This represents the Recording mode of the PlayerView
export default class RecordingMode extends PlayerViewMode
{
  on_enter(player_view)
  {
    player_view.playback_bar.set_range(0, TimeUtilities.full_day_ms);

    player_view.live_label.addClass('hidden');
    player_view.turn_off_hd();
    player_view.selected_camera_label.addClass('hidden');

    player_view.extract_recording_view.hide();
    player_view.extract_recording_view.open_button.removeClass('active');

    player_view.playback_bar.show();
    player_view.playback_bar.show_range_labels();
    // player_view.playback_bar.show_time_label();
    player_view.playback_bar.detailed_playback_bar.hide();
  }

  update(player_view)
  {
    player_view.app.camera_controller.update();

    player_view.playback_bar.set_cursor_ms(player_view.playback_time);
    player_view.playback_bar.set_progress_ms(player_view.playback_time);

    player_view.playback_bar.set_time_label_ms(player_view.playback_time);

    if (!Configuration.is_mobile)
    {
      this.__position_lines_based_off_the_mouse_position(player_view);
    }

    this.__check_for_detailed_mode(player_view);
  }

  on_pause(player_view)
  {
    player_view.app.recording_telemetry_controller.stop_streaming(PhysicalCameraManager.selected_camera);
  }

  on_play(player_view, fragment)
  {
    PhysicalCameraManager.selected_camera.fisheye_sphere.on_video_player_play(true);
    PhysicalCameraManager.selected_camera.map_marker.set_image_url(player_view.html_video.container);

    // Request telemetry from the recording fragment
    if (fragment)
    {
      player_view.app.recording_telemetry_controller.start_streaming(
        PhysicalCameraManager.selected_camera,
        fragment,
        player_view.playback_time
      );
    }
  }

  __check_for_detailed_mode(player_view)
  {
    if (Input.left_mouse_button_pressed && player_view.playback_bar.is_mouse_over())
    {
      player_view.set_mode(new DetailedRecordingMode());
    }
  }

  __position_lines_based_off_the_mouse_position(player_view)
  {
    // If mouse is inside the footer of the player controls,
    // set the hover line and the time label on the mouse position.

    // Get updated size of the controls footer
    let footer_body_rect = player_view.player_footer_body_elem.getBoundingClientRect();

    // Mouse is inside footer_body_rect
    if (Input.mouse_is_within_bounds(footer_body_rect))
    {
      let mouse_ms = player_view.playback_bar.get_mouse_ms();

      if (mouse_ms > player_view.playback_time)
      {
        player_view.playback_bar.set_progress_ms(mouse_ms);
      }

      // Set the current time position and label based off the mouse position
      player_view.playback_bar.set_time_label_ms(mouse_ms);
    }
  }
}
