import { MathUtilities } from 'ohzi-core';

// This represents a recording on the seekbar
export default class Fragment
{
  constructor(recording, start_ms, end_ms, elem)
  {
    this.id = recording ? recording.id : undefined;
    this.recording_start_offset = recording ? recording.offset_in_seconds_from_absolute_start : 0;
    this.start_ms = start_ms;
    this.end_ms   = end_ms;
    this.elem     = elem;
    this.file_name = recording ? recording.file_name : undefined;
    this.timezone = recording ? recording.timezone : undefined;
    this.recording = recording;
  }

  show()
  {
    this.elem.style.display = 'none';
  }

  hide()
  {
    this.elem.style.display = 'flex';
  }

  contains_ms(ms)
  {
    return MathUtilities.between(ms, this.start_ms, this.end_ms);
  }

  set_transform(start_px, end_px, container_width)
  {
    let width   = (end_px - start_px) / container_width;
    this.elem.style.transform = `translate3d(${start_px}px, 0, 0) scaleX(${width})`;
  }

  get_relative_offset_ms(ms)
  {
    return this.recording_start_offset * 1000 + (ms - this.start_ms);
  }
}
