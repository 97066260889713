export default class PlayerViewMode
{
  constructor()
  {

  }

  on_enter(player_view)
  {

  }

  update(player_view)
  {

  }

  on_play(player_view, fragment)
  {
  }

  on_pause(player_view)
  {
  }

  on_exit(player_view)
  {

  }
}
