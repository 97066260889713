import { ArrayUtilities } from 'ohzi-core';

import MapMarker from '/js/components/MapMarker';
//import MapTargetMarker from '/js/components/MapTargetMarker';
//import TaglockMarker from '/js/components/TaglockMarker';

import PlayerSettings from '/js/components/PlayerSettings';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import CameraTargetUtilities from '/js/components/CameraTargetUtilities';

// TODO: Change to use TaglockMarkerMarker

/**
 * @class TaglockMarkerManager
 * @description Handles the behaviour of the taglock devices that appear on the map
 * @function add_marker() - Adds a marker object to the taglock_markers array this function is called by *
 */

// This class manages the taglock device markers on the map
class TaglockMarkerManager
{
  constructor()
  {
    this.taglock_markers            = [];
    this.default_map_marker_alt     = 50;
    this.default_map_zoom           = 50;

    this.selected_taglock_target    = undefined;

    this.app                        = undefined;
  }

  set_app(app)
  {
    this.app = app;
  }

  start()
  {
    let cesium_toolbar_right = $('.cesium-toolbar__right');

    // Add all to the Cesium Toolbar
    var divContainer = $('.cesium-viewer-toolbar');
    divContainer.append(cesium_toolbar_right);

    this.select_taglock = $('.cesium-toolbar__select-taglock');
    this.select_taglock_select = $('.cesium-toolbar__select-taglock-select');

    this.hide_select_taglock();
  }

  update()
  {
    if (PhysicalCameraManager.selected_camera && PlayerSettings.taglock_auto_track && this.selected_taglock_target)
    {
      this.update_taglock_auto_track();
    }
  }

  add_marker(data)
  {
    // let marker = new TaglockMarker(
    // TODO: Temporary until TagLockMarker added
    let marker = new MapMarker({
      text: data.name,
      lon: data.tgt_lon,
      lat: data.tgt_lat,
      alt: this.default_map_marker_alt,
      map_zoom: this.default_map_zoom,
      scale: 10,
      opacity: 1,
      is_inverted: false,
      show_custom_sphere: false,
      show_custom_plane: true // true?
    });

    marker.show(true);
    marker.roll = 0.0;
    marker.pitch = 45.0;
    marker.yaw = 0.0;

    this.taglock_markers.push(marker);
  }

  remove_marker(name)
  {
    let marker = this.get_by_name(name);

    if (marker)
    {
      marker.dispose();
      ArrayUtilities.remove_elem(this.taglock_markers, marker);
    }
  }

  update_marker(name, marker_data)
  {
    for (let i = 0; i < this.taglock_markers.length; i++)
    {
      if (this.taglock_markers[i].text === name)
      {
        this.__update_params(this.taglock_markers[i], marker_data);
      }
    }
  }

  get_by_name(name)
  {
    for (let i = 0; i < this.taglock_markers.length; i++)
    {
      if (this.taglock_markers[i].text == name)
      {
        return this.taglock_markers[i];
      }
    }

    return undefined;
  }

  on_select_taglock()
  {
    this.selected_taglock = this.select_taglock_select.val();
    let marker = this.get_by_name(this.selected_taglock);
    if (marker)
    {
      marker.fly_to();
    }
  }

  set_active_taglock_by_name(name)
  {
    let taglock_marker = this.get_by_name(name);

    if (taglock_marker)
    {
      this.set_active_taglock(taglock_marker);
    }
  }

  set_active_taglock(taglock)
  {
    this.selected_taglock_target = taglock;
  }

  // Fill Select element when websocket message of type 'target' is received
  add_option_to_taglock_select(target_name, data)
  {
    if (target_name)
    {
      let existing_option = this.select_taglock_select.find(`option[value="${target_name}"]`);

      if (existing_option.length === 0)
      {
        let select = this.select_taglock_select[0];

        let option = document.createElement('option');
        option.setAttribute('value', target_name);

        let text_node = document.createTextNode(target_name);
        option.appendChild(text_node);

        select.appendChild(option);

        // Show select on first valid option (only if player not selected?)
        if (select.length <= 2)
        {
          this.show_select_taglock();
        }

        // Add target marker on map
        this.add_marker(data);
      }
    }
  }

  remove_option_to_taglock_select(target_name)
  {
    if (target_name)
    {
      let existing_option = this.select_taglock_select.find(`option[value="${target_name}"]`);

      if (existing_option.length > 0)
      {
        if (existing_option.is(':selected'))
        {
          $(this.select_taglock_select.find('option')[0]).prop('selected', true);
          this.selected_taglock_target = undefined;
        }

        existing_option.remove();

        // Remove target marker on map
        this.remove_marker(target_name);
      }

      if (this.select_taglock_select[0].length <= 1)
      {
        this.hide_select_taglock();
      }
    }
  }

  hide_select_taglock()
  {
    this.select_taglock_select[0].selectedIndex = '0';
    this.select_taglock.addClass('hidden');
  }

  show_select_taglock()
  {
    if (this.select_taglock_select[0].length > 1)
    {
      this.select_taglock.removeClass('hidden');
    }
  }

  hide_taglock_marker()
  {
    if (this.selected_taglock_target)
    {
      this.selected_taglock_target.hide();
    }
  }

  show_taglock_marker()
  {
    if (this.selected_taglock_target)
    {
      this.selected_taglock_target.show(true);
    }
  }

  update_taglock_auto_track()
  {
    let closest_camera = PhysicalCameraManager.selected_camera;
    let closest_distance = CameraTargetUtilities.compute_distance_between_camera_and_marker(closest_camera, this.selected_taglock_target);
    //console.log(`Selected Camera [${closest_camera.name}] distance: ${selected_camera_distance}`);

    // TODO: :TEmp
    //closest_distancelet closest_distance = selected_camera_distance;
    //let distance = 0;

    for (let i = 0; i < PhysicalCameraManager.cameras.length; i++)
    {
      let camera = PhysicalCameraManager.cameras[i];

      if (camera)
      {
        let distance = CameraTargetUtilities.compute_distance_between_camera_and_marker(camera, this.selected_taglock_target);

        // Use a hysteresis value of 5.0m so we aren't jumping between cameras when target gets similar distance from two cameras
        if ((distance + 5.0) < closest_distance)
        {
          closest_camera = camera;
          closest_distance = distance;
        }
      }
    }

    if (closest_camera !== PhysicalCameraManager.selected_camera)
    {
      this.app.menu_view.select_physical_camera(closest_camera);
    }
  }

  __update_params(marker, data)
  {
    marker.set_position(data.tgt_lon, data.tgt_lat, this.default_map_marker_alt);
    // this.map_marker.set_position(data.tgt_lon, data.tgt_lat, data.tgt_alt);
    
    let yaw = (typeof data.yaw !== 'undefined') ? data.yaw : marker.yaw;
    let roll = (typeof data.roll !== 'undefined') ? data.roll : marker.roll;
    let pitch = (typeof data.pitch !== 'undefined') ? data.pitch : marker.pitch;

    marker.set_alert(data.alert);
    //marker.set_rotation(data.yaw, data.roll, data.pitch);
    marker.set_rotation(yaw, pitch, roll);
    marker.set_name(data.name);
  }
}

export default new TaglockMarkerManager();
