
const menu_camera_pagination = (camerasPerPage)=>
{
  let camerasTuple = [];

  return ()=>
  {
    
    return {
      get_camera_by_name:(cameraName)=>get_camera_by_name(camerasTuple,cameraName),
      get_tuple:(filter='')=>
      {
        if(!camerasTuple && camerasTuple.length < 1) return [];
        let shortedArray = [];
        const flatedCameras = camerasTuple.reduce((flatArray,currentTuple)=>
        {
          flatArray = [...flatArray,...currentTuple.map((camera)=>camera)];

          
          return flatArray.filter((arrayItem=>arrayItem.name.toLowerCase().includes(filter.toLowerCase()))).sort(function(a, b)
          {
            return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
          });
        },[]);

        flatedCameras.some((camera)=>
        {
          add_camera_to_tuple({camera,camerasPerPage,camerasTuple:shortedArray});
        });

        return shortedArray;
        
        
      },
      reset_tuple:()=>
      {
        camerasTuple = [];
      },
      remove_camera:(cameraName)=>
      {
        camerasTuple=remove_camera_from_tuple({cameraName,camerasTuple});
      },
      add_camera:(camera)=>add_camera_to_tuple({camera,camerasPerPage,camerasTuple})};
    
  };
};

const remove_camera_from_tuple = ({camerasTuple,cameraName})=>
{
  if( camerasTuple && camerasTuple.length < 1) return;
  const filteredCameras = camerasTuple.map((tuple)=>tuple.filter((cameraTuple)=>cameraName!==cameraTuple.name));
  return remove_empty_tuple({camerasTuple:filteredCameras});
};

const remove_empty_tuple = ({camerasTuple})=>
{
  if(camerasTuple.length <1) [];
  return camerasTuple.filter(tuple=>tuple.length);
};


const add_camera_to_tuple = ({camerasPerPage,camerasTuple,camera,currentTupleIndex = 0})=>
{
  if(check_if_camera_already_exist(camerasTuple,camera)) throw new Error('Camera already exist');

  camerasTuple[currentTupleIndex] = camerasTuple[currentTupleIndex] || [];
  if(check_if_tuple_has_space({camerasPerPage,camerasTuple,currentTupleIndex}))
  {
    camerasTuple[currentTupleIndex] = [...camerasTuple[currentTupleIndex],camera];
    return camerasTuple;
  }
  else
  {
    return add_camera_to_tuple({camerasPerPage,camerasTuple,currentTupleIndex:currentTupleIndex + 1,camera});
  }

};

const check_if_tuple_has_space =({camerasTuple,camerasPerPage,currentTupleIndex})=>
{
  if(camerasTuple.length < 1) return true;
  const cameraTupleLength =  camerasTuple[currentTupleIndex].length || 0; 
  const hasTupleSpace = cameraTupleLength < camerasPerPage;
  return hasTupleSpace;
};

export const check_if_camera_already_exist = (camerasTuple,camera)=>
{
  if(camerasTuple.length < 1) return false;
  const doesCameraAlreadyExist =  camerasTuple.some((tuple)=>
    tuple.some((tuppleCamera)=> tuppleCamera.name === camera.name)
  );
  return doesCameraAlreadyExist;
};

export const get_camera_by_name = (camerasTuple,cameraName)=>
{
  let camera;
  camerasTuple.some((tuple)=>
  {
    tuple.some((tuppleCamera)=>
    {
      if(tuppleCamera.name === cameraName)
      {
        camera = tuppleCamera;
        return true;
      }
    });
  });
  return camera;
};


export default menu_camera_pagination;