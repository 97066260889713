
import RecordingTelemetry from './RecordingTelemetry';
import TimeUtilities from './TimeUtilities';

/* global moment */

// This class manages the single-day telemetries of a camera.
export default class RecordingTelemetryManager
{
  constructor()
  {
    this.telemetries = [];
  }

  // This function receives the raw telemetry range of a camera and splits them
  // into single days for easier management
  add_recording_date_range(recording_id, start_date, end_date, file_name)
  {
    if (start_date === '' || end_date === '')
    {
      return;
    }

    let days = TimeUtilities.get_days_between(start_date, end_date);
    let timezone = moment.parseZone(start_date, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:s']);

    if (timezone)
    {
      timezone = timezone.format('Z');
    }

    let offset_in_seconds_from_start = 0;
    for (let i = 0; i < days.length; i++)
    {
      let recording_telemetry = new RecordingTelemetry(recording_id, offset_in_seconds_from_start, days[i], file_name, timezone);
      this.telemetries.push(recording_telemetry);
      offset_in_seconds_from_start += recording_telemetry.duration_in_seconds_from_start;
    }
  }

  // This function receives the raw recording range of a camera and splits them
  // into single days for easier management
  // add_recording_date_range(recording_id, start_date, end_date, file_name, encodedurl)
  // {
  //   let days = TimeUtilities.get_days_between(start_date, end_date);
  //   // let timezone = moment.parseZone(start_date);
  //   let timezone = moment.parseZone(start_date, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:s']);

  //   if (timezone)
  //   {
  //     timezone = timezone.format('Z');
  //   }

  //   let offset_in_seconds_from_start = 0;
  //   for (let i = 0; i < days.length; i++)
  //   {
  //     let rec = new Recording(recording_id, offset_in_seconds_from_start, days[i], file_name, timezone, encodedurl);
  //     this.recordings.push(rec);
  //     offset_in_seconds_from_start += rec.duration_in_seconds_from_start;
  //   }
  // }

  get_telemetries_on_date(date)
  {
    let telemetries = [];

    for (let i = 0; i < this.telemetries.length; i++)
    {
      if (this.telemetries[i].is_on_date(date))
      {
        telemetries.push(this.telemetries[i]);
      }
    }

    return telemetries;
  }

  clear()
  {
    this.telemetries.length = 0;
  }
}
