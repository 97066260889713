/* global moment */

// This class helps with parsing an entire recording that spans several days, into one-day recordings
// that start at 0:00:00 and ends at 23:59:59, except for the first and last day of a recording range
class TimeUtilities
{
  get full_day_ms()
  {
    return 86400000;
  }

  get_days_contained_in_range(start_date, end_date)
  {
    //let startDate = moment.parseZone(start_date);
    //let endDate = moment.parseZone(end_date);
    let startDate = moment.parseZone(start_date, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:sZ']);
    let endDate = moment.parseZone(end_date, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:sZ']);
    
    let target_day = endDate.date();
    let target_month = endDate.month();
    let target_year = endDate.year();
    let days_count = 1;

    while (target_day !== startDate.date() ||
      target_month !== startDate.month() ||
      target_year !== startDate.year())
    {
      startDate.add(1, 'day');
      days_count++;
    }

    return days_count;
  }

  get_days_between(start_date, end_date)
  {
    // let startDate = moment.parseZone("2020-03-15T18:31:23.623794-04:00");
    // let endDate = moment.parseZone("2020-03-23T12:17:06.815451-04:00");
    //console.log(`[TimeUtilities:get_days_between]`);
    //console.dir(start_date);
    //console.dir(end_date);

    let start, end;
    let days_array = [];
    
    //let startDate = moment.parseZone(start_date);
    //let endDate = moment.parseZone(end_date);
    
    // Add suggested format to handle timestamp strings with missing leading zeros i.e `2020-08-18T03:33:3-04:00`
    let startDate = moment.parseZone(start_date, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:sZ']);
    let endDate = moment.parseZone(end_date, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:sZ']);
    //console.dir(startDate);
    //console.dir(endDate);
    
    // Write corrected date back to string
    //start_date = startDate().format();
    
    let duration = moment.duration(endDate.diff(startDate));
    let days = this.get_days_contained_in_range(start_date, end_date);

    for (let i = 0; i < days; i++)
    {
      // If the recording starts and ends at the same day
      if ((startDate.date() === endDate.date()) && (startDate.month() === endDate.month()))
      {
        start = startDate;
        end = endDate;
      }
      else
      {
        if (i === 0)
        {
          // The first fragment of the recording
          start = startDate;
          end = moment(startDate).endOf('day');
        }
        else if (i === days - 1)
        {
          // The last fragment of the recording
          start = moment(endDate).startOf('day');
          end = endDate;
        }
        else
        {
          // Full days in between
          //start = moment(startDate).add('days', i).startOf('day');
          //end = moment(startDate).add('days', i).endOf('day');
          start = moment(startDate).add(i, 'days').startOf('day');
          end = moment(startDate).add(i,'days').endOf('day');
        }
      }

      days_array.push({
        start: moment(start),
        end: moment(end),
        duration_in_seconds: moment.duration(moment(end).diff(moment(start))).asSeconds(),
        absolute_start_date: start_date,
        absolute_end_date: end_date,
        asd_start: start.toString(),
        asd_end: end.toString()
      });
    }

    return days_array;
  }
}

export default new TimeUtilities();
