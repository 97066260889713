import { SceneManager } from 'ohzi-core';
import { Validation } from 'ohzi-core';
import CameraTargetUtilities from './CameraTargetUtilities';
import PhysicalCameraManager from './PhysicalCameraManager';

// This handles the PlayerMarker icons being displayed on the player
class PlayerMarkerManager
{
  constructor()
  {
    this.player_markers = {};
    this.temp_camera = {};

    this.app = undefined;

    this.player_hotspot_select = document.querySelector('.player__hotspot-select');
  }

  set_app(app)
  {
    this.app = app;
  }

  add_marker(type, marker)
  {
    this.player_markers[type] = marker;
    SceneManager.current.add(marker);
  }

  // Update marker position
  // If pan or tilt is invalid or empty, we hide the marker
  update_marker_position(type, pan, tilt, flat)
  {
    if (this.__is_valid_marker_position(pan, tilt))
    {
      let quaternion_forward = undefined;
      let forward = undefined;

      if (!flat)
      {
        // console.log(`Updating sphere PlayerMarker`);
        // 360 - pan fixes 180 degress offset position
        quaternion_forward = this.__build_marker_rotation(tilt, 360 - pan);
        forward = new THREE.Vector3(0, 0, 1);

        forward.applyQuaternion(quaternion_forward);
        forward.multiplyScalar(10);
      }
      else
      {
        // console.log(`Updating flat PlayerMarker`);
        // 360 - pan fixes 180 degress offset position
        // quaternion_forward = this.__build_marker_rotation(tilt, 360 - pan);
        forward = new THREE.Vector3(1, 2, 1);
      }

      this.player_markers[type].set_position(forward);
    }
    else
    {
      this.player_markers[type].hide();
    }
  }

  update_marker_label(type, label)
  {
    this.player_markers[type].update_label(label);
  }

  hide_marker(type)
  {
    this.player_markers[type].hide();
  }

  reset_temp_camera()
  {
    this.temp_camera = {};
  }

  update()
  {
    this.__update_hotspot_marker_position();
  }

  __update_hotspot_marker_position()
  {
    if (this.app.menu_view.current_tab.name === 'live' &&
    !this.app.player_container.is_loading() &&
    PhysicalCameraManager.selected_camera)
    {
      const hotspot = this.player_hotspot_select.options[this.player_hotspot_select.selectedIndex].value;

      if (hotspot !== 'none')
      {
        let hotspot_camera = PhysicalCameraManager.get_by_name(hotspot);

        if (hotspot_camera)
        {
          this.temp_camera.cam_lat = PhysicalCameraManager.selected_camera.cam_lat;
          this.temp_camera.cam_lon = PhysicalCameraManager.selected_camera.cam_lon;
          this.temp_camera.cam_alt = PhysicalCameraManager.selected_camera.cam_alt;

          this.temp_camera.tgt_lat = hotspot_camera.cam_lat;
          this.temp_camera.tgt_lon = hotspot_camera.cam_lon;
          this.temp_camera.tgt_alt = hotspot_camera.cam_alt;

          this.temp_camera.roll = PhysicalCameraManager.selected_camera.roll;
          this.temp_camera.pitch = PhysicalCameraManager.selected_camera.pitch;
          this.temp_camera.yaw = PhysicalCameraManager.selected_camera.yaw;

          if (!this.__is_valid_marker_position(this.temp_camera.icon_pan, this.temp_camera.icon_tilt))
          {
            CameraTargetUtilities.compute_and_set_camera_image_and_icon(this.temp_camera);
          }

          this.update_marker_position('hotspot', this.temp_camera.icon_pan, this.temp_camera.icon_tilt, false);
          this.update_marker_label('hotspot', hotspot_camera.name);
        }
      }
      else
      {
        this.hide_marker('hotspot');
      }
    }
    else
    {
      this.hide_marker('hotspot');
    }
  }

  __is_valid_marker_position(pan, tilt)
  {
    return !!((Validation.is_int(pan) || Validation.is_float(pan)) &&
             (Validation.is_int(tilt) || Validation.is_float(tilt)));
  }

  __build_marker_rotation(tilt, orientation)
  {
    let new_orientation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.Math.degToRad(orientation));
    let new_tilt = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(-1, 0, 0), THREE.Math.degToRad(tilt));

    return new_orientation.multiply(new_tilt);
  }
}

export default new PlayerMarkerManager();
