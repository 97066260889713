import { Configuration } from 'ohzi-core';
import { Validation } from 'ohzi-core';

import CameraViewState from './states/CameraViewState';
import CameraDebugState from './states/CameraDebugState';
import RotationOnly from './states/RotationOnly';
import ImmediateMode from './movement_mode/ImmediateMode';
import CameraPTZMode from './movement_mode/CameraPTZMode';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';

// This controls the THREE camera
export default class CameraControllerTandem
{
  constructor()
  {
    this.camera = undefined;
    this.camera_initial_rot = undefined;
    this.camera_initial_pos = undefined;

    this.current_state = new CameraViewState();
    this.current_mode = new CameraPTZMode();
    this.tandem_mode = true;

    this.normalized_zoom = 0;

    this.vector_up_axis   = new THREE.Vector3(0, 1, 0);
    this.vector_right_axis = new THREE.Vector3(-1, 0, 0);
    this.vector_forward_axis = new THREE.Vector3(0, 0, 1);
    this.tmp_forward = this.vector_forward_axis.clone();
    this.tmp_right = this.vector_right_axis.clone();

    this.tmp_dir = new THREE.Vector3();

    this.zoom = Configuration.max_zoom_distance / 2;
    this.reference_zoom = Configuration.max_zoom_distance / 2;

    this.reference_rotation = new THREE.Quaternion();
    this.reference_position = new THREE.Vector3();
    this.__last_reference_position = new THREE.Vector3();

    this.tmp_size = new THREE.Vector3();
    this.tmp_quat = new THREE.Quaternion();

    this.min_zoom             = 1;
    this.max_zoom             = 400;

    this.current_tilt         = 0;
    this.current_orientation  = 0;

    // Target pan/tilt for PTZ camera to aim for
    this.new_tilt             = 0;
    this.new_pan              = 0;

    this.input_enabled = true;

    // this.active               = true; // Used to determine if tandem mode is active
  }

  set_camera(camera)
  {
    this.camera = camera;
    this.camera_initial_rot = camera.quaternion.clone();
    this.camera_initial_pos = camera.position.clone();
  }

  set_mode(mode)
  {
    this.current_mode.on_exit(this);
    this.current_mode = mode;
    this.current_mode.on_enter(this);
  }

  set_normalized_zoom(zoom)
  {
    this.normalized_zoom = THREE.Math.clamp(zoom, 0, 1);
  }

  update_normalized_zoom(min_zoom, max_zoom)
  {
    let zoom = this.camera.position.distanceTo(this.reference_position);
    this.normalized_zoom = this.linear_map(zoom, min_zoom, max_zoom, 1, 0);
    this.normalized_zoom = THREE.Math.clamp(this.normalized_zoom, 0, 1);
  }

  update()
  {
    if (this.debug_box)
    {
      this.debug_box.position.copy(this.reference_position);
    }

    if (!PhysicalCameraManager.tandem_mode)
    {
      return;
    }

    this.current_mode.update(this);
  }

  set_idle()
  {
    this.set_state(new CameraViewState());
  }

  set_debug_mode()
  {
    this.set_state(new CameraDebugState());
  }

  set_rotation_only_mode()
  {
    this.set_state(new RotationOnly());
  }

  camera_is_zoomed_out()
  {
    return this.normalized_zoom < 0.2;
  }

  set_rotation(tilt, orientation)
  {
    if (this.__is_valid_rotation(orientation, tilt))
    {
      if (PhysicalCameraManager.selected_camera_tandem.lens_type !== 'flat')
      {
        this.current_tilt = tilt;
        this.current_orientation = orientation;

        this.reference_rotation.copy(this.build_rotation(this.current_tilt, this.current_orientation));
      }
      else
      {
        this.new_tilt = tilt;
        this.new_pan = orientation;
      }
    }
  }

  set_zoom_delta(zoom_delta)
  {
    this.set_normalized_zoom(this.normalized_zoom + (zoom_delta / 1000));
  }

  __is_valid_rotation(pan, tilt)
  {
    return !!((Validation.is_int(pan) || Validation.is_float(pan)) &&
              (Validation.is_int(tilt) || Validation.is_float(tilt)));
  }

  linear_map(value,
    from_range_start_value,
    from_range_end_value,
    to_range_start_value,
    to_range_end_value)
  {
    return ((value - from_range_start_value) / (from_range_end_value - from_range_start_value)) * (to_range_end_value - to_range_start_value) + to_range_start_value;
  }

  get_current_tilt()
  {
    return this.current_tilt;
  }

  get_current_orientation()
  {
    return this.current_orientation;
  }

  get_zoom_to_show_rect(width, height, scale = 1)
  {
    let v_fov = THREE.Math.degToRad(this.camera.fov / 2);
    let h_fov = (2 * Math.atan(Math.tan(v_fov) * this.camera.aspect)) / 2;

    let distV = (height / 2) / Math.tan(v_fov * scale);
    let distH = (width / 2) / Math.tan(h_fov * scale);
    return Math.max(Math.abs(distH), Math.abs(distV));
  }
}
