import ApplicationView from './common/ApplicationView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import MapCalibrationMarker from '/js/components/MapCalibrationMarker';
import { ResourceContainer } from 'ohzi-core';
import RoleManager from '../RoleManager';
import CesiumHandler from '../CesiumHandler';

// This handles the Camera Calibration view
/**
 * Handles the functionalitty of camera calibration (camera calibration is located under options>camera calibration).
 * The pug template is camera_calibration.pug
 *
 * @class
 */
export default class CameraCalibrationView extends ApplicationView
{
  constructor(app)
  {
    super('camera-calibration', $('.camera-calibration'));
    this.app = app;

    this.camera_calibration_target_active = false;
    this.camera_calibration_camera_active = false;
    this.camera_calibration_pan_active = false;
  }

  start()
  {
    this.close_button = $('.menu__buttons-close');

    this.form = $('.camera-calibration__form');
    this.inputs = $('.camera-calibration__inputs-input-input');
    this.input_tgt_lat = $('.camera-calibration__inputs-input-tgt_lat')[0];
    this.input_tgt_lon = $('.camera-calibration__inputs-input-tgt_lon')[0];
    this.input_cam_lat = $('.camera-calibration__inputs-input-cam_lat')[0];
    this.input_cam_lon = $('.camera-calibration__inputs-input-cam_lon')[0];
    this.input_icon_pan = $('.camera-calibration__inputs-input-icon_pan')[0];
    this.input_cam_true_heading = $('.camera-calibration__inputs-input-cam_true_heading')[0];
    this.input_current_heading = $('.camera-calibration__inputs-input-current_heading')[0];
    
    //this.input_tgt_lat1 = this.inputs.find('.camera-calibration__inputs-input-tgt_lat');
    //this.input_tgt_lat2 = this.inputs.find('.camera-calibration__inputs-input-input').text('tgt_lat');
    this.button_set_target_location = $('.camera-calibration__button-title.set-target-location')[0];
    this.button_set_camera_location = $('.camera-calibration__button-title.set-camera-location')[0];
    this.button_set_video_target = $('.camera-calibration__button-title.set-video-target')[0];

    this.cameras_select = $('.camera-calibration__cameras_select');
    
    this.__fill_cameras_select();

    this.map_calibration_marker = new MapCalibrationMarker('Calibration', -76.6148160, 39.4180870, 50.0, 50);
    this.map_calibration_marker.hide();
  }

  get_headers()
  {
    const headers = {};

    headers['Content-Type'] = 'application/json';
    headers.Authorization = 'Bearer ' + RoleManager.bearer_token;

    return headers;
  }

  close()
  {
    this.hide();
    this.map_calibration_marker.hide();
    this.app.menu_view.show();
  }

  show()
  {
    super.show();

    this.__fill_cameras_select();
    this.__clear_form();

    // Hide sphere/plane
    if (PhysicalCameraManager.selected_camera)
    {
      PhysicalCameraManager.selected_camera.map_marker.hide(true);
    }

    CesiumHandler.select_bing_aerial_map();

    this.app.menu_options_view.hide();
  }

  // Called by UI (camera_calibration.pug) when the user clicks the button
  get_target_map_location()
  {
    if (this.__is_camera_selected())
    {
      this.camera_calibration_target_active = true;

      this.button_set_target_location.textContent = 'Right click on the map';
    }
  }

  // Called by UI (camera_calibration.pug) when the user clicks the button
  get_camera_map_location()
  {
    if (this.__is_camera_selected())
    {
      this.camera_calibration_camera_active = true;

      this.button_set_camera_location.textContent = 'Right click on the map';
    }
  }

  // Called by UI (camera_calibration.pug) when the user clicks the button
  get_video_pan()
  {
    if (this.__is_camera_selected())
    {
      this.camera_calibration_pan_active = true;

      // Open camera player to allow selecting target (close current camera if already open)
      let form_data = this.__form_data();
      let camera = PhysicalCameraManager.get_by_name(form_data.camera_name);
    
      if (camera && PhysicalCameraManager.selected_camera !== camera)
      {
        this.app.menu_view.select_physical_camera(camera);
      }

      this.button_set_video_target.textContent = 'Double click on the video';
    }
  }

  // Called by UI (camera_calibration.pug) when the user clicks the button
  calculate_heading()
  {
    if (this.__is_camera_selected())
    {
      let heading = this.__calculate_camera_heading();
      $(this.input_cam_true_heading).val(heading.toFixed(2));

      // Update marker arrow to calculated heading
      this.map_calibration_marker.set_heading(heading);
    }
  }

  // Called by UI (camera_calibration.pug) when the user clicks the button. Save the heading and cam lat/lon to camera settings and save all to server
  save_heading()
  {
    if(isNaN($(this.input_cam_true_heading).val()))
    {
      console.log(`Cannot save heading to camera settings as heading is NaN: ${$(this.input_cam_true_heading).val()}`);
      return;
    }

    if (this.__is_camera_selected())
    {
      let form_data = this.__form_data();
      let camera = PhysicalCameraManager.get_by_name(form_data.camera_name);
      
      console.dir(camera);
      
      if (camera)
      {
        if(!isNaN($(this.input_cam_true_heading).val()))
        {
          camera.yaw = parseFloat($(this.input_cam_true_heading).val());
        }
        if(!isNaN($(this.input_cam_lat).val()))
        {
          camera.cam_lat = parseFloat($(this.input_cam_lat).val());
        }
        if(!isNaN($(this.input_cam_lon).val()))
        {
          camera.cam_lon = parseFloat($(this.input_cam_lon).val());
        }
        this.app.camera_settings_view.update_camera_on_server(camera,false);
      }
    }
  }

  set_target_location(lat, lon)
  {
    $(this.input_tgt_lat).val(lat.toFixed(6));
    $(this.input_tgt_lon).val(lon.toFixed(6));

    this.camera_calibration_target_active = false;
    this.button_set_target_location.textContent = 'Set target location on the map';
  }

  set_camera_location(lat, lon)
  {
    $(this.input_cam_lat).val(lat.toFixed(6));
    $(this.input_cam_lon).val(lon.toFixed(6));

    this.map_calibration_marker.set_arrow_position(lon, lat);

    this.camera_calibration_camera_active = false;
    this.button_set_camera_location.textContent = 'Set camera location on the map';
  }

  set_camera_pan(pan)
  {
    console.log(`[CameraCalibrationView:set_camera_pan] pan: ${pan}`);
    console.dir(this);
    $(this.input_icon_pan).val(pan.toFixed(2));

    this.camera_calibration_camera_active = false;
    this.button_set_video_target.textContent = 'Set video target';
  }

  // Called by UI (camera_settings.pug) when the user changes the camera select
  reload_camera_settings()
  {
    this.__clear_form();

    let form_data = this.__form_data();

    let camera = PhysicalCameraManager.get_by_name(form_data.camera_name);
    let keys = Object.keys(form_data);

    console.dir(camera);

    if (camera)
    {
      $(this.input_current_heading).val(parseFloat(camera.yaw).toFixed(2));

      this.map_calibration_marker.set_position_to_camera(camera);
      this.map_calibration_marker.show();

      // Open camera player to allow selecting target
      if (PhysicalCameraManager.selected_camera !== camera)
      {
        this.app.menu_view.select_physical_camera(camera);
      }

      // Hide sphere/plane
      camera.map_marker.hide(true);
    }
  }

  __is_camera_selected()
  {
    return (this.cameras_select[0].selectedIndex > 0);
  }

  __calculate_camera_heading()
  {
    let cam_loc = new Location($(this.input_cam_lat).val(), 
        $(this.input_cam_lon).val(), 0.0);

    let tgt_loc = new Location($(this.input_tgt_lat).val(), 
        $(this.input_tgt_lon).val(), 0.0);

    let icon_pan = parseFloat($(this.input_icon_pan).val());

    console.log("Inputs: ");
    console.log("Target Location: ", tgt_loc.lat.toFixed(7), tgt_loc.lon.toFixed(7));
    console.log("Cam Location: ", cam_loc.lat.toFixed(7), cam_loc.lon.toFixed(7));
    console.log("Pan Angle to Target: ", icon_pan.toFixed(2));

    let cam_true_heading = calculate_true_heading(cam_loc, tgt_loc, icon_pan);
    console.log("Outputs: ");
    console.log("Calculated Camera True Heading: ", cam_true_heading.toFixed(2));
    //$('#hdg_cam_true_heading').val(cam_true_heading.toFixed(2)).trigger('change');

    return cam_true_heading;
  }

  __fill_cameras_select(selected_camera_name)
  {
    if (PhysicalCameraManager.selected_camera)
    {
      selected_camera_name = selected_camera_name || PhysicalCameraManager.selected_camera.name;
    }

    let select = this.cameras_select[0];

    this.__reset_select(select);
    let cameras = PhysicalCameraManager.get_editable_cameras();

    this.__fill_select(select, cameras, selected_camera_name);

    select.selectedIndex = '0';
  }

  __reset_select(select)
  {
    select.innerHTML = '';

    let option = document.createElement('option');
    option.setAttribute('disabled', 'disabled');

    let text_node = document.createTextNode(select.dataset.disabledText);
    option.appendChild(text_node);
    select.appendChild(option);
  }

  __fill_select(select, cameras, selected_camera_name)
  {
    for (let i = 0; i < cameras.length; i++)
    {
      let option = document.createElement('option');
      option.setAttribute('value', cameras[i].name);

      if (selected_camera_name === cameras[i].name)
      {
        option.setAttribute('selected', 'selected');
      }

      let text_node = document.createTextNode(cameras[i].name);
      option.appendChild(text_node);

      select.appendChild(option);
    }
  }

  __form_data()
  {
    return this.form.serializeArray().reduce(function(obj, item)
    {
      obj[item.name] = item.value;
      return obj;
    }, {});
  }

  __clear_form()
  {
    $(this.input_tgt_lat).val('');
    $(this.input_tgt_lon).val('');
    $(this.input_cam_lat).val('');
    $(this.input_cam_lon).val('');
    $(this.input_icon_pan).val('');
    $(this.input_cam_true_heading).val('');
    $(this.input_current_heading).val('');
  }

  __disable_cameras_select()
  {
    this.cameras_select.addClass('disabled');
  }

  __enable_cameras_select()
  {
    this.cameras_select.removeClass('disabled');
  }
}
