import { Configuration } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import PhysicalCameraManager from './PhysicalCameraManager';
import { pipelines} from 'media-stream-library';


/**
 * @class AxisCameraStream
 * @description Creates a stream that we are able to play a video, it acts like a media player.
 * @link https://github.com/AxisCommunications/media-stream-library-js
 */

// This handles the communication with a physical axis camera
export default class AxisCameraStream
{
  constructor(html_video)
  {
    this.html_video     = html_video; // HTMLVideo class

    this.user = undefined;
    this.password = undefined;
    this.host = undefined;
    this.recording_id = undefined;
    this.profile = undefined;
    this.play_offset = 0;

    this.is_streaming = false;

    this.last_video_current_time = -1;
    this.camera_name = '';
  }

  // Called when the camera settings are updated
  set_endpoint(camera)
  {
    // this.__stop_video_pipeline();
    this.user = camera.username;
    this.password = camera.password;
    this.host = camera.live_ip;
    this.resolution = camera.live_resolution;

    this.camera_name = camera.name;
    
  }

  // Called by MenuView and PlayerView.
  //
  // play_offset defines how many seconds of offset do you want when playing recording.
  // This is how we play a specific time of a specific recording.
  //
  // If play_offset is 0, the streaming of the recording starts
  // at the beginning of the recording
  set_recording_data(recording_id, play_offset)
  {
    this.recording_id = recording_id;
    this.play_offset  = play_offset;
  }

  set_profile(profile)
  {
    this.profile = profile;
  }

  set_resolution(resolution)
  {
    this.resolution = resolution;
  }

  // Called by MenuView and PlayerView
  start_streaming(on_video_ready)
  {
    // this.__stop_video_pipeline();
    this.__start_video_pipeline(this.__on_pipeline_ready.bind(this, on_video_ready));
  }

  stop_streaming()
  {
    this.__stop_video_pipeline();
  }

  /**
   * TODO I have added (Alexandros) the pipeline.play() and pipeline.pause() functions, on the play() and pause() fucntions
   * This need to be investigated more if it is correct way of pausing a stream and playing a stream. This code it seems to
   * pausing and playing the stream from the axis and not only the html video. With the latests tests this works perfectly and
   * there is no need to removing it. That code solved the problem on menu view cameras where we needed to pause the streams
   * when the camera got outside the view or entered the view.
   */

  play()
  {
    // this.is_streaming = true;
    this.html_video.play();
    this.pipeline.play();
  }

  pause()
  {
    this.html_video.pause();
    this.pipeline.pause();

  }

  pause_rtsp()
  {
    this.pipeline.rtsp.pause();
  }
    
  

  stop()
  {
    this.html_video.stop();
    this.__stop_video_pipeline();
  }

  update(throttle = 0, reconnection_callback=()=>
  {})
  {
    if (this.is_streaming)
    {
      if (throttle < 0.1)
      {
        if (this.pipeline && this.pipeline.rtsp)
        {


          if (this.last_video_current_time === this.html_video.container.currentTime)
          {
            this.restart_video_pipeline(reconnection_callback);
          }
        }
      }

      this.last_video_current_time = this.html_video.container.currentTime;
    }
  }

  // This function performs the RTSP connection to a axis camera
  // through the websocket server.
  // This connection is managed by mediaStreamLibrary
  //
  // If this.recording_id is not present,
  // the Axis Camera will give us the live streaming
  //
  // If this.recording_id is present,
  // the Axis Camera will give us a specific recording
  __start_video_pipeline(on_ready_callback)
  {
    let user         = this.user;
    let password     = this.password;
    let host         = this.host;
    let mediaElement = this.html_video.container;
    let recording_id = this.recording_id;
    let encoding     = Configuration.is_ios ? 'jpeg' : 'h264';
    let profile      = this.profile;
    let resolution   = this.resolution;
    // let resolution   = Configuration.is_ios ? '1440x1440' : this.resolution;

    if (Configuration.is_ios)
    {
      resolution = '';
      profile = profile ? `${profile}_ios` : '';
    }

    let url = `rtsp://${host}/axis-media/media.amp?` +
              `videocodec=${encoding}` +
              '&audio=1' +
              `${resolution ? `&resolution=${resolution}` : ''}` +
              `&streamprofile=${profile}` +
              `${recording_id ? `&recordingid=${recording_id}` : ''}`;

    // console.log(`Requesting RTSP uri: ${url}`);
    // console.dir(this.html_video);

    let websocket_ip = this.__get_websocket_ip();
    let context = this;

    let data = {
      ws: { uri: websocket_ip },
      rtsp: {
        uri: url
      },
      mediaElement,
      auth: {
        username: user,
        password: password
      }
    };

    console.log('DEBUG-AxisCameraStream.js: function is called to get video for:'+ this.camera_name+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
    console.log('content of the data sent to make the call to Axis:',data,'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));



    if (Configuration.is_ios)
    {
      this.pipeline = new pipelines.Html5CanvasPipeline(data);
    }
    else
    {
      this.pipeline = new pipelines.Html5VideoPipeline(data);
    }

    let pipeline        = this.pipeline;
    let play_offset     = this.play_offset;

    this.pipeline.onServerClose = ()=>
    {
      console.log('DEBUG-AxisCameraStream.js: Lost connection with the websocket ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
    };

    pipeline.rtsp.incoming.on('error', (err)=>
    {
      console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
      console.log(err);
    });

    pipeline.rtsp.outgoing.on('error', (err)=>
    {
      console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
      console.log(err);
    });

    this.pipeline.ready.then(() =>
    {
      console.log('DEBUG-AxisCameraStream.js: Connect to websocket through pipeline ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
      pipeline.rtsp.play(play_offset);
      // When the RTSP connection is ready to stream,
      // We call PlayerView.play()
      pipeline.rtsp.incoming.on('error', (err)=>
      {
        console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
        console.log(err);
      });

      pipeline.rtsp.outgoing.on('error', (err)=>
      {
        console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
        console.log(err);
      });

      pipeline.onPlay = () =>
      {
        console.log('DEBUG-AxisCameraStream.js: Video successfully loaded for camera ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));

        context.is_streaming = true;

        on_ready_callback();
      };

      pipeline.onError = (err) =>
      {
        console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
        console.log(err);

      };
      pipeline.rtsp.onError = (err) =>
      {
        console.log('DEBUG-AxisCameraStream.js: Video had an error ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a'));
        console.log(err);

      };
    });

    this.pipeline.ready.catch((e) =>
    {
      console.warn('[AxisCameraStream:sdsds] There was some error for the camera '+ this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a') );
      console.dir(e);
    });
  }

  __on_pipeline_ready(callback)
  {
    if (callback)
    {
      callback();
    }
  }

  restart_video_pipeline(recconnection_callback = ()=>
  {})
  {
    console.log('restart connection for camera ' + this.camera_name+' '+'Time is : '+moment().format('MMMM Do YYYY, h:mm:ss a') );
    this.__stop_video_pipeline();
    this.__start_video_pipeline(recconnection_callback);
  }

  __stop_video_pipeline()
  {
    this.is_streaming = false;

    if (this.pipeline)
    {
      this.html_video.stop();
      this.pipeline.rtsp.stop();
    }
  }

  __get_websocket_ip()
  {
    return ResourceContainer.get_resource('config').cameras_websocket_ip;
  }

  get_screenshot_url()
  {
    let scale = 1;
    let width = $(this.html_video.container).width() * scale;
    let height = $(this.html_video.container).height() * scale;

    let canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    canvas.getContext('2d').drawImage(this.html_video.container, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }
}
