import MenuViewMode from './MenuViewMode';
import PhysicalCameraManager from '../../PhysicalCameraManager';
import MenuCameraManager from '../../MenuCameraManager';

// This represents the Recordings mode of the MenuView
/**
 * @class RecordingsMode
 * @description User is able to see his Recordings files.Get activated when Recordings tab is clicked.
 * @function on_tab_selected() - Get called when user has clicked the tab. (called by MenuView).
 * @function on_player_hide() -  Get called when we want to hide the player. (called by PlayerContainer)
 * @function is_valid_camera() -  It works as filter for whcich camera to display in the menu view.Called by menuView.js
 * 
 */
export default class RecordingsMode extends MenuViewMode
{
  constructor()
  {
    super('recordings');
  }

  on_tab_selected(menu_view)
  {
    menu_view.fill_menu(0);
  }

  is_valid_camera(physical_camera)
  {
    return (physical_camera.bodyworn !== 'yes' && physical_camera.cam_type !== 'phone');
  }

  on_player_hide(player_container)
  {
    player_container.app.recording_telemetry_controller.stop_streaming(PhysicalCameraManager.selected_camera);
  }

  on_camera_selected(menu_view)
  {
    menu_view.show_calendar_view();

    menu_view.app.calendar_view.get_recordings_from_camera(PhysicalCameraManager.selected_camera);
  }

  on_day_selected(app)
  {
    let first_fragment = app.player_view.playback_bar.get_first_fragment();
    app.player_view.play_recording(first_fragment);

    app.player_view.extract_recording_view.show_open_button();
    app.player_container.show();
    app.menu_view.close();

    PhysicalCameraManager.selected_camera.fly_on_valid_telemetry = true;
  }

  on_recording_played(player_view, fragment)
  {
    console.log('[RecordingsMode:on_recording_played]');

    player_view.app.recording_telemetry_controller.stop_streaming(PhysicalCameraManager.selected_camera);

    // Set recording id and time to the selected setram
    PhysicalCameraManager.selected_camera.stream.set_recording_data(
      fragment.id,
      fragment.get_relative_offset_ms(player_view.playback_time) / 1000
    );

    // Set Recording profle to the selected stream (rec_profile is variable that is loaded dynamically from camera settings)
    PhysicalCameraManager.selected_camera.stream.set_profile(PhysicalCameraManager.selected_camera.rec_profile);

    // player_view.play is passed as a callback, so the AxisCameraStream can call it
    // when the RTSP connection is ready to stream
    PhysicalCameraManager.selected_camera.stream.start_streaming(player_view.play.bind(player_view));
  }
}
