import { ResourceContainer } from 'ohzi-core';

export default class PlayerMarkerMaterial extends THREE.PointsMaterial
{
  constructor()
  {
    super({ size: 4 * window.devicePixelRatio, color: 0xff0000 });
    this.map = ResourceContainer.get_resource('point');
    this.dephFunc = THREE.AlwaysDepth;
    this.depthTest = false;
    this.transparent = true;
  }
}
