
import { Input } from 'ohzi-core';

// This represents Audio Volume of the PlayerView
export default class AudioVolume
{
  constructor()
  {
    this.progress_ms = 0;
    this.time_label_ms = 0;

    this.player_video = undefined;
  }

  start(player_video)
  {
    this.container = document.querySelector('.player__audio');

    this.player_video = player_video;

    this.volume_static_bar = $('.player__audio-volume-static-bar');
    this.volume_bar = $('.player__audio-volume-dynamic-bar');
    this.volume_circle = $('.player__audio-volume-circle');

    this.audio_icon_on = $('.player__audio-icon--on');
    this.audio_icon_off = $('.player__audio-icon--off');

    this.recalculate_bounding_client();
  }

  update()
  {
    // this.recalculate_bounding_client();
    if (Input.left_mouse_button_down && this.is_mouse_over())
    {
      let progres_px = this.get_mouse_px() - this.volume_circle.width() / 2;
      let normalized_progres = ((progres_px + this.volume_circle.width() / 2) / this.playback_bar_rect.width);

      this.player_video.set_volume(normalized_progres);

      this.__set_translate(this.volume_circle[0], progres_px);
      this.__set_scale(this.volume_bar[0], normalized_progres);

      if (normalized_progres <= 0.0001)
      {
        this.audio_icon_on.addClass('hidden');
        this.audio_icon_off.removeClass('hidden');
      }
      else
      {
        this.audio_icon_on.removeClass('hidden');
        this.audio_icon_off.addClass('hidden');
      }
    }
  }

  show()
  {
    this.container.classList.remove('hidden');
  }

  hide()
  {
    this.container.classList.add('hidden');
  }

  recalculate_bounding_client()
  {
    this.playback_bar_rect = this.volume_static_bar[0].getBoundingClientRect();
  }

  is_mouse_over()
  {
    return Input.mouse_is_within_bounds(this.container.getBoundingClientRect());
  }

  // Get the position of the mouse in pixels
  get_mouse_px()
  {
    let position = Input.mouse_pos.x - this.playback_bar_rect.left;
    return THREE.Math.clamp(position, 0, this.playback_bar_rect.width);
  }

  __set_translate(element, px)
  {
    element.style.transform = `translate3d(${px}px, 0, 0)`;
  }

  __set_scale(element, scale)
  {
    element.style.transform = `scaleX(${scale})`;
  }
}
