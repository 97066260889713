
import { CameraUtilities } from 'ohzi-core';

import PhysicalCameraManager from './PhysicalCameraManager';
import PlayerMarker from './PlayerMarker';
import PlayerMarkerManager from './PlayerMarkerManager';

// This class represents the icon marker placed in the camera environment, displayed on the player
export default class PlayerCameraMarker extends PlayerMarker
{
  constructor()
  {
    super();

    this.container = undefined;
    this.label_container = undefined;

    this.player_hotspot_select = document.querySelector('.player__hotspot-select');

    this.__instanciate_html_container();

    this.container.addEventListener('click', this.on_click.bind(this));
    this.player_hotspot_select.addEventListener('change', this.on_hotspot_select_change.bind(this));
  }

  set_position(pos)
  {
    let screen_pos = CameraUtilities.world_pos_to_screen(pos);

    if (screen_pos.z < 1)
    {
      this.container.style.transform = `translate3d(${screen_pos.x}px,${screen_pos.y}px,0)`;
      this.show();
    }
    else
    {
      this.hide();
    }
  }

  update_label(label)
  {
    this.label_container.textContent = label;
  }

  on_click()
  {
    const hotspot = this.player_hotspot_select.options[this.player_hotspot_select.selectedIndex].value;

    if (hotspot !== 'none')
    {
      let hotspot_camera = PhysicalCameraManager.get_by_name(hotspot);

      if (hotspot_camera)
      {
        $(`.menu__cameras-camera[data-name=${hotspot_camera.name}]`).click();
      }
    }
  }

  on_hotspot_select_change()
  {
    PlayerMarkerManager.reset_temp_camera();
  }

  show()
  {
    this.container.classList.remove('hidden');
  }

  hide()
  {
    this.container.classList.add('hidden');
  }

  __instanciate_html_container()
  {
    this.container = document.createElement('div');
    this.container.classList.add('dot-marker');
    this.hide();

    for (let i = 0; i < 3; i++)
    {
      let ripple_el = document.createElement('div');
      ripple_el.classList.add('dot-marker__ripple');

      this.container.appendChild(ripple_el);
    }

    let background_el = document.createElement('div');
    background_el.classList.add('dot-marker__background');
    this.container.appendChild(background_el);

    let text_el = document.createElement('div');
    text_el.classList.add('dot-marker__text');
    text_el.textContent = '';
    this.container.appendChild(text_el);

    document.querySelector('.player').appendChild(this.container);
    this.label_container = text_el;
  }
}
