import UserRole from './UserRole';

export default class CustLiveRole extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    ViewApi.hide_player_info_button();
    ViewApi.hide_player_target_lock_button();
    ViewApi.hide_player_target_select();
    ViewApi.hide_recordings_download_buttons();
    ViewApi.hide_recordings_delete_buttons();
    ViewApi.hide_menu_options();
    ViewApi.hide_archive_tab();
    ViewApi.hide_recordings_tab();
    ViewApi.hide_extract_recording_button();
    ViewApi.set_menu_image_thumbnail();
  }
}
