import PhysicalCameraManager from './PhysicalCameraManager';
import TaglockMarkerManager from './TaglockMarkerManager';
import CesiumHandler from './CesiumHandler';
import { Time } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import RoleManager from './RoleManager';

// This class handles the function of the player header buttons
class PlayerSettings
{
  constructor()
  {
    this.player_tag_lock = false;
    this.map_lock = true;
    this.lock_view = false;
    this.taglock_lock_view = false;
    this.taglock_auto_track = false;
    this.camera_info_enabled = false;
    // this.mp_bridge = false;

    this.selected_target = undefined;

    // Target active period in seconds
    this.target_active_period = 2;

    this.target_active_times = {};
    this.target_active_times_keys = [];
  }

  start()
  {
    this.button_map = $('.player__button-map');
    this.button_taglock = $('.player__button-taglock');
    this.target_button = $('.player__button-target');
    this.info_button = $('.player__button-info');
    this.lock_button = $('.player__button-lock-view');
    this.taglock_lock_view_button = $('.player__button-taglock-lock-view');
    this.taglock_auto_track_button = $('.player__button-taglock-auto-track');
    // this.button_mp_bridge = $('.player__button-mp');

    this.select_target = $('.player__select-target');
    this.select_target_select = $('.player__select-target-select');
    this.select_dropdown_container = $('.player__select');
    // this.button_360_img = $('.player__button-360-img');
    // this.button_360_img_disabled = $('.player__button-360-img-disabled');
  }

  update()
  {
    for (let i = 0; i < this.target_active_times_keys.length; i++)
    {
      this.target_active_times[this.target_active_times_keys[i]] += Time.delta_time;

      if (this.target_active_times[this.target_active_times_keys[i]] >= this.target_active_period)
      {
        TaglockMarkerManager.remove_option_to_taglock_select(this.target_active_times_keys[i]);
        this.remove_option_to_target_select(this.target_active_times_keys[i]);
      }
    }
  }

  /*
  // Called by UI - Send target location to Mission Planner Serial Port Bridge
  toggle_mp_bridge()
  {
    console.log(`[toggle_mp_bridge]`);
    this.mp_bridge = !this.mp_bridge;

    if (this.mp_bridge)
    {
      this.button_mp_bridge.addClass('active');
      console.log(`[toggle_mp_bridge] Mission Planner Bridge Enabled`);

    }
    else
    {
      this.button_mp_bridge.removeClass('active');
      console.log(`[toggle_mp_bridge] Mission Planner Bridge Disabled`);
    }
  }
  */
  // Called by UI
  toggle_tag_lock()
  {
    this.button_taglock.toggleClass('active');
    this.player_tag_lock = !this.player_tag_lock;
    if (this.player_tag_lock)
    {
      // this.show_taglock_lock_view();
      TaglockMarkerManager.hide_taglock_marker();
    }
    else
    {
      this.hide_taglock_lock_view();
      TaglockMarkerManager.show_taglock_marker();
    }
  }

  // Called by UI
  // This "lock view" mode will lock the Three camera to facing the video plane so when it rotates we are always looking normal to plane
  toggle_taglock_lock_view()
  {
    this.taglock_lock_view = !this.taglock_lock_view;

    console.log(`[PlayerSettings:toggle_lock_view] lock_view: ${this.lock_view}`);
    // console.log(`[PlayerSettings:toggle_lock_view] lock_view_all_axis: ${this.lock_view_all_axis}`);

    if (this.taglock_lock_view)
    {
      this.taglock_lock_view_button.addClass('active');
    }
    else
    {
      this.taglock_lock_view_button.removeClass('active');
    }
  }

  // Called by UI
  // This "Tag Lock Auto Track" mode automatically swap physical cameras to the closest camera to the taglock target to track it
  toggle_taglock_auto_track()
  {
    this.taglock_auto_track = !this.taglock_auto_track;

    console.log(`[PlayerSettings:toggle_taglock_auto_track] taglock_auto_track: ${this.taglock_auto_track}`);

    if (this.taglock_auto_track)
    {
      this.taglock_auto_track_button.addClass('active');
      this.player_tag_lock = true;
      TaglockMarkerManager.hide_taglock_marker();
    }
    else
    {
      this.taglock_auto_track_button.removeClass('active');
      this.player_tag_lock = false;
      TaglockMarkerManager.show_taglock_marker();
    }
  }

  // Called by UI and PlayerSettings
  // This "Center Map to Camera" feature will lock camera sphere/plane as center of map and allow you to rotate/zoom around it
  toggle_map_lock(will_unlock)
  {
    if (will_unlock)
    {
      this.map_lock = false;
    }
    else
    {
      this.map_lock = !this.map_lock;
    }

    if (this.map_lock)
    {
      this.button_map.addClass('active');
      if (PhysicalCameraManager.tandem_mode && PhysicalCameraManager.selected_camera_tandem)
      {
        CesiumHandler.lock_to_marker(PhysicalCameraManager.selected_camera_tandem.map_marker);
        PhysicalCameraManager.selected_camera_tandem.fly_to_camera_marker();
      }
      else
      {
        if (PhysicalCameraManager.selected_camera)
        {
          PhysicalCameraManager.selected_camera.fly_to_camera_marker();
          CesiumHandler.lock_to_marker(PhysicalCameraManager.selected_camera.map_marker);
        }
      }
    }
    else
    {
      this.button_map.removeClass('active');
      CesiumHandler.unlock();
    }
  }

  // Called by UI
  // This "lock view" mode will lock the Three camera to facing the video plane so when it rotates we are always looking normal to plane
  toggle_lock_view()
  {
    this.lock_view = !this.lock_view;

    console.log(`[PlayerSettings:toggle_lock_view] lock_view: ${this.lock_view}`);
    // console.log(`[PlayerSettings:toggle_lock_view] lock_view_all_axis: ${this.lock_view_all_axis}`);

    if (this.lock_view)
    {
      this.lock_button.addClass('active');

      CesiumHandler.lock_to_plane(true);

      if (PhysicalCameraManager.tandem_mode && PhysicalCameraManager.selected_camera_tandem)
      {
        PhysicalCameraManager.selected_camera_tandem.set_view_to_plane(true);
      }
      else
      {
        PhysicalCameraManager.selected_camera.set_view_to_plane(true);
      }
    }
    else
    {
      this.lock_button.removeClass('active');
      CesiumHandler.lock_to_plane(false);
    }
  }

  // Called by UI
  toggle_info()
  {
    this.info_button.toggleClass('active');
    this.camera_info_enabled = !this.camera_info_enabled;
  }

  // Called by UI
  fly_to_selected_camera()
  {
    if (PhysicalCameraManager.tandem_mode && PhysicalCameraManager.selected_camera_tandem)
    {
      PhysicalCameraManager.selected_camera_tandem.fly_to_camera_marker();
      return;
    }
    if (PhysicalCameraManager.selected_camera)
    {
      PhysicalCameraManager.selected_camera.fly_to_camera_marker();
    }
  }

  on_select_target()
  {
    this.selected_target = this.select_target_select.val();

    TaglockMarkerManager.set_active_taglock_by_name(this.selected_target);
  }

  // Fill Select element when websocket message of type 'target' is received
  add_option_to_target_select(target_name)
  {
    if (target_name)
    {
      let existing_option = this.select_target_select.find(`option[value="${target_name}"]`);

      if (existing_option.length === 0)
      {
        let select = this.select_target_select[0];

        let option = document.createElement('option');
        option.setAttribute('value', target_name);

        let text_node = document.createTextNode(target_name);
        option.appendChild(text_node);

        select.appendChild(option);

        // Add target marker on map
        // TaglockMarkerManager.add_marker(data);
      }

      this.target_active_times[target_name] = 0;
      this.target_active_times_keys = Object.keys(this.target_active_times);
    }
  }

  remove_option_to_target_select(target_name)
  {
    if (target_name)
    {
      let existing_option = this.select_target_select.find(`option[value="${target_name}"]`);

      if (existing_option.length > 0)
      {
        if (existing_option.is(':selected'))
        {
          $(this.select_target_select.find('option')[0]).prop('selected', true);
          this.selected_target = undefined;
        }

        existing_option.remove();

        // Remove target marker on map
        // TaglockMarkerManager.remove_marker(target_name);

        delete this.target_active_times[target_name];
        this.target_active_times_keys = Object.keys(this.target_active_times);
      }
    }
  }

  take_screenshot()
  {
    if (PhysicalCameraManager.selected_camera)
    {
      let app_config = ResourceContainer.get_resource('config');

      // Format: "2021-02-17T00:50:35-03:00"
      let time = moment().format();
      
      let data = {
        recording_id: 'Image',
        start_time: time,
        stop_time: time,
        camera_id: PhysicalCameraManager.selected_camera._id
      };

      $.ajax({
        type: 'POST',
        url: `${app_config.recordings_api_host}/exportjpg`,
        headers: RoleManager.get_auth_header(),
        timeout: 60000,
        dataType: 'json',
        data: JSON.stringify(data),
        complete: this.__take_screenshot_response.bind(this)
      });
    }
  }

  __take_screenshot_response(response)
  {
    if (response.status !== 0)
    {
      // Notify the user ?
    }
    else
    {
      console.error('Something went wrong', response);
    }
  }

  hide_info_button()
  {
    this.info_button.addClass('hidden');
  }

  show_button_taglock()
  {
    this.button_taglock.removeClass('hidden');
  }

  hide_button_taglock()
  {
    this.button_taglock.addClass('hidden');
  }

  hide_select_target()
  {
    this.select_target.addClass('hidden');
  }

  show_select_dropdown()
  {
    this.select_dropdown_container.removeClass('hidden');
  }

  hide_select_dropdown()
  {
    this.select_dropdown_container.addClass('hidden');
  }

  hide_taglock_lock_view()
  {
    console.log('[PlayerSettings:hide_taglock_lock_view');
    this.taglock_lock_view_button.addClass('hidden');
  }

  show_taglock_lock_view()
  {
    console.log('[PlayerSettings:hide_taglock_lock_view');
    this.taglock_lock_view_button.removeClass('hidden');
  }
}

export default new PlayerSettings();
