import ApplicationView from './common/ApplicationView';

export default class ModalWindowView extends ApplicationView
{
  constructor(app)
  {
    super('modal-window', $('.modal-window'));

    this.app = app;
  }

  start()
  {
    this.header = $('.modal-window__header');
    this.close_button = $('.modal-window__close-button');
    this.body = $('.modal-window__body');
  }

  show(content, hide_header=false)
  {
    if (hide_header)
    {
      this.body.addClass('modal-window__body-only');
      this.header.addClass('hidden');
      this.body[0].setAttribute('onclick', 'app.modal_window_view.hide(this)');
    }
    else
    {
      this.body.removeClass('modal-window__body-only');
      this.header.removeClass('hidden');
      this.body[0].removeAttribute('onclick');
    }

    if (this.__is_json(content))
    {
      content = JSON.parse(content);
      let content_keys = Object.keys(content);
      let content_html = '';

      for (let i = 0; i < content_keys.length; i++)
      {
        content_html += `${content_keys[i]}: ${content[content_keys[i]]}`;
        content_html += '<br><br>';
      }

      this.body.html(content_html);
    }
    else
    {
      this.body.html(content);
    }

    super.show();

    this.containerEl.style['pointer-events'] = 'none';
  }

  hide()
  {
    super.hide();

    this.body.html('');

    this.app.notification_manager.stop_sound_connecting();
  }

  __is_json(str)
  {
    try
    {
      JSON.parse(str);
      return true;
    }
    catch (e)
    {
      return false;
    }
  }
}
