import ApplicationView from '../common/ApplicationView';

export default class WebSocketConnectionView extends ApplicationView 
{

  constructor(app)
  {
    super('webSocketConnection',$('.web-socket-connection'));
    this.app = app;
  }

  show()
  {
    super.show();
  }

  hide()
  {
    super.hide();
  }

}