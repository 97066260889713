class Legitity 
{

  
  constructor(value) 
  {
    this.value = value;
    this.error = undefined;
  }
  
  boolean(msg) 
  {
    if (!this.error && this.value !== undefined && this.value !== true && this.value !== false) 
    {
      this.error = msg || 'boolean';
    }
    return this;
  }
  
  float(msg) 
  {
    if (!this.error && this.value !== undefined && !Number.isFinite(this.value)) 
    {
      this.error = msg || 'float';
    }
    return this;
  }
  
  int(msg) 
  {
    if (!this.error && this.value !== parseInt(this.value, 10)) 
    {
      this.error = msg || 'int';
    }
    return this;
  }
  
  matches(regex, msg) 
  {
    if (!this.error && this.value && !regex.test(this.value)) 
    {
      this.error = msg || 'regex';
    }
    return this;
  }
  
  max(len, msg) 
  {
    if (!this.error && this.value && this.value.length > len) 
    {
      this.error = msg || 'max';
    }
    return this;
  }
  
  min(len, msg) 
  {
    if (!this.error && this.value && this.value.length < len) 
    {
      this.error = msg || 'min';
    }
    return this;
  }
  
  required(msg) 
  {
    if (!this.error && !this.value) 
    {
      this.error = msg || 'required';
    }
    return this;
  }
  
  trim() 
  {
    this.value = this.value && this.value.trim ? this.value.trim() : this.value;
    return this;
  }
  
  normalize(fn, msg) 
  {
    if (!this.error) 
    {
      this.value = this.value !== undefined && fn(this.value);
      if (this.value === null) 
      {
        this.error = msg;
      }
    }
    return this;
  }
  
  test(check) 
  {
    if (!this.error) 
    {
      this.error = check(this.value);
    }
    return this;
  }
  custom(fn,msg) 
  {
    if (!this.error && !fn(this.value)) 
    {
      this.error = msg;
    }
    return this;
  }
}
  
export default Legitity;
  