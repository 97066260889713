const pipe = (...fns) => x => fns.reduce((y, f) => f(y), x);

const getMapLabelColor = (url,map_colors={})=>
{   
  const to_lower = str => str.toLowerCase();
  const object_keys = obj => Object.keys(obj);
  const find = (f,arr) => arr.filter(f);
  const str_includes = str => query => str.includes(query);
  const desired_prop = (prop,obj) => obj[prop];
  
  const map_on_color_mapping = (query)=> pipe(to_lower,str_includes(to_lower(query),desired_prop));

  const map_name = find(map_on_color_mapping(url),object_keys(map_colors));
  if(!map_name) return false;
  const desired_color = desired_prop('color',desired_prop(map_name,map_colors));
  return desired_color;
};


export default getMapLabelColor;