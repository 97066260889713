import { Screen } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { Configuration } from 'ohzi-core';

import FisheyeDebugMaterial from '/js/materials/FisheyeDebugMaterial';
// This class represents the sphere geometry where the camera video texture is applied
export default class FisheyeSphere extends THREE.Mesh
{
  constructor(fisheye_fov, use_upper_hemisphere)
  {
    let geo = new THREE.SphereBufferGeometry(10, 90, 90).toNonIndexed();
    let material = new FisheyeDebugMaterial();

    super(geo, material);

    this.__apply_uv_fisheye_correction(fisheye_fov);
    this.__apply_geometry_orientation(use_upper_hemisphere);

    this.last_fov = undefined;
    this.last_use_upper_hemisphere = undefined;

    this.frames = 0;
    this.is_playing = false;
  }

  update()
  {
    if (Configuration.is_ios)
    {
      if (this.is_playing)
      {
        if (this.frames > 30)
        {
          // console.log(`[FisheyeSphere:update] updating material frame for ios`);
          this.frames = 0;
          this.material.uniforms._MainTex.value.needsUpdate = true;
        }

        this.frames++;
      }
    }
  }

  on_video_player_play()
  {

  }

  set_rotation(yaw, roll, pitch)
  {
    // Convert from earth bearing to threejs sphere yaw
    let fixed_yaw = 360 - yaw;

    // Convert roll, yaw and pitch to Quaternions
    let roll_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), THREE.Math.degToRad(parseFloat(roll)));
    let yaw_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), THREE.Math.degToRad(parseFloat(fixed_yaw)));
    let pitch_quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), THREE.Math.degToRad(parseFloat(pitch)));

    // Apply roll, yaw and pitch rotation to the sphere
    // this.quaternion.copy(new THREE.Quaternion());
    // this.quaternion.multiply(yaw_quaternion).multiply(roll_quaternion).multiply(pitch_quaternion);
  }

  set_fov_and_use_upper_hemisphere(fov, use_upper_hemisphere)
  {
    if (fov !== this.last_fov || use_upper_hemisphere !== this.last_use_upper_hemisphere)
    {
      this.geometry.dispose();
      this.geometry = new THREE.SphereBufferGeometry(10, 90, 90).toNonIndexed();

      this.__apply_uv_fisheye_correction(fov);
      this.__apply_geometry_orientation(use_upper_hemisphere);

      this.last_fov = fov;
      this.last_use_upper_hemisphere = use_upper_hemisphere;
    }
  }

  set_texture(map)
  {
    // if (this.material.map)
    // {
    //   this.material.map.dispose();
    // }
    this.material.set_main_tex(map);
    this.material.set_debug_tex(ResourceContainer.get_resource('fisheye_tex'));
    // this.material.map = map;//ResourceContainer.get_resource('fisheye_tex');
    // this.material.map = ResourceContainer.get_resource('fisheye_tex');
  }

  __apply_uv_fisheye_correction(fisheye_fov)
  {
    let tex_width   = Screen.height;
    let tex_height  = Screen.height;

    let geo = this.geometry;
    geo.scale(-1, 1, 1);

    geo.computeFaceNormals();

    let normals = geo.attributes.normal.array;
    let uvs = geo.attributes.uv.array;

    for (let i = 0, l = normals.length / 3; i < l; i++)
    {
      let x = normals[i * 3 + 0];
      let y = normals[i * 3 + 1];
      let z = normals[i * 3 + 2];

      // radius center
      let correction = (x === 0 && z === 0) ? 1 : (Math.acos(y) / Math.sqrt(x * x + z * z)) * (2 / ((fisheye_fov * Math.PI) / 180));
      uvs[i * 2 + 0] = x * ((tex_height / 2) / tex_width) * correction + ((tex_width / 2) / tex_width);
      uvs[i * 2 + 1] = z * ((tex_height / 2) / tex_height) * correction + ((tex_height / 2) / tex_height);
    }
  }

  __apply_geometry_orientation(use_upper_hemisphere)
  {
    let geo = this.geometry;
    if (use_upper_hemisphere === false) // down
    {
      geo.rotateZ(Math.PI - THREE.Math.degToRad(0)); // tilt
      geo.rotateX(THREE.Math.degToRad(0)); // roll
    }
    else
    {
      geo.rotateZ(THREE.Math.degToRad(0)); // tilt
      geo.rotateX(-THREE.Math.degToRad(0)); // roll
    }

    // geo.rotateY(Math.PI/2);
    // geo.rotateY(Math.PI);
  }

  dispose()
  {
    this.geometry.dispose();
    this.material.dispose();
    if (this.parent)
    {
      this.parent.remove(this);
    }
  }
}
