import { Validation } from 'ohzi-core';

import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import TelemetryController from './TelemetryController';

// This creates a websocket client to send target telemetry messages to websocket server for broadcast
export default class TargetTelemetryController extends TelemetryController
{
  constructor(app)
  {
    super(app, app.config.target_telemetry_websocket_host, undefined);

    this.enabled = false;         // Websocket connection is open
    this.stream_enabled = false;  // Actively sending messages to websocket

    this.tgt_lat = 0.0;
    this.tgt_lon = 0.0;
    this.tgt_alt = 0.0;
  }

  on_message(event)
  {
    if (event.data === 'ping')
    {
      this.is_connection_alive = true;
    }
  }

  connect()
  {
    console.log(`[TargetTelemetryController:connect] Enable websocket output to server: ${this.host}`);

    if (this.webSocket.readyState === WebSocket.OPEN)
    {
      this.webSocket.close();
    }

    this.establish_connection();

    this.enabled = true;
    return true;
  }

  disconnect()
  {
    // console.log(`[TargetTelemetryController:stop] Stop sending `);

    this.__stop_serial_stream();

    // Close the connection, if open.
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN)
    {
      console.log('Closing websocket');
      this.webSocket.close();
    }

    this.stream_enabled = false;
    this.enabled = false;
  }

  update()
  {
    super.update();

    let camera = PhysicalCameraManager.selected_camera;

    if (camera)
    {
      this.tgt_lat = camera.tgt_lat;
      this.tgt_lon = camera.tgt_lon;
      this.tgt_alt = camera.tgt_alt;

      if (this.webSocket && this.enabled && !this.stream_enabled)
      {
        this.stream_enabled = true;
        this.__start_serial_stream();
      }
    }
    else
    {
      this.stream_enabled = false;
      this.__stop_serial_stream();
    }
  }

  send_target_message()
  {
    if (!this.__is_valid_location(this.tgt_lat, this.tgt_lon, this.tgt_alt))
    {
      return;
    }

    let time_utc_ms = Date.now();

    // Example from TagLock App
    // {"tgt_alt":0.0,"tgt_lat":-36.85580687679621,"tgt_lon":174.8275045469403,"name":"glenn1","time":1596615469000,"type":"target","uuid":"b026728d-6a75-4239-bd20-9d5f6febcbe1"}
    let json_msg = `{"name":"360_player","time":${time_utc_ms},"type":"target","tgt_lat": ${this.tgt_lat.toFixed(7)}, "tgt_lon": ${this.tgt_lon.toFixed(7)}, "tgt_alt": ${this.tgt_alt.toFixed(1)}}`;

    this.write_to_stream(json_msg);
  }

  write_to_stream(json_msg)
  {
    if (this.webSocket.readyState !== WebSocket.OPEN)
    {
      console.log('Websocket not open, cannot send message', json_msg);
      return;
    }

    console.log(`[TargetTelemetryController:write_to_stream] Sending to websocket: ${json_msg}`);

    this.webSocket.send(json_msg);
  }

  __start_serial_stream()
  {
    this.sendIntervalId = setInterval(this.send_target_message.bind(this), 1000);
  }

  __stop_serial_stream()
  {
    clearInterval(this.sendIntervalId);
  }

  __is_valid_location(lon, lat, alt)
  {
    return !!((Validation.is_int(lon) || Validation.is_float(lon)) &&
      (Validation.is_int(lat) || Validation.is_float(lat)) &&
      (Validation.is_int(alt) || Validation.is_float(alt)));
  }
}
